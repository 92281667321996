
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_TOMAS from '../../assets/img/tomas.jpg';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'


export function Sessions() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your sessions" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                </div>
            </motion.div>

        </>

    )
}








