
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";

import SRC_CLOSE from '../../assets/img/close.svg';
import SRC_EDIT from '../../assets/img/edit.svg';

import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_STAR from '../../assets/img/star.svg';
import SRC_LOCATION from '../../assets/img/location.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import { HeaderCheckout } from '../Header';
import Favorite from "../ui/Favorite";
import { InputField, RangeSlider, TextField, DateField, Checkbox, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import { TripSlider } from './TripSlider';

import SearchPeople from '../SearchPeople';



export function YourTrip() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your trip" hasIcon="true" icon={SRC_EDIT} link="/trip/results" />

                <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 100, y: 0 }} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.2 }} className='w-full h-full overflow-auto no-scrollbar pb-24'>
                    <div className=''>
                        <div className='flex p-global pt-0 flex-col items-center text-center'>
                            <motion.div  layoutId="tripImg" className='w-full before:block before:pt-[80%] before:w-full relative rounded-2xl overflow-hidden shadow-2xl'>
                                <motion.img src='https://tinyurl.com/22d2st9l' className='absolute top-0 left-0 w-full h-full object-cover' />
                            </motion.div>
                            <h2 className='text-3xl font-bold text-center mb-1 mt-6'>Siargao Island</h2>
                            <div className='text-sm text-slate-500'>24-29 April, 2024</div>

                            <div className='w-full pt-6 mt-6 border-t border-slate-200 dark:border-zinc-600'>
                                <div className='flex items-center pb-1 mb-2'>
                                    <motion.h2 className='text-global'>Instructors</motion.h2>
                                    <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>

                                </div>
                                <div className='flex items-center gap-x-2  py-3'>
                                    <motion.img className='size-12 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                                    Tomas Zeman
                                    <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                                </div>

                                <div className='flex items-center pb-1 mb-2 mt-8'>
                                    <motion.h2 className='text-global'>Boards for rent</motion.h2>
                                    <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>

                                </div>
                                <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                                    <motion.img className='size-12 mr-2 border border-slate-200 rounded-lg  object-cover' src='https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367' />
                                    Firewire Seasife
                                    <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                                </div>
                                <div className='flex items-center gap-x-2 py-3'>
                                    <motion.img className='size-12 mr-2 border border-slate-200 rounded-lg object-cover' src='https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377' />
                                    JS Monsta, 6'2
                                    <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                                </div>

                                <div className='flex items-center pb-1 mb-2 mt-8'>
                                    <motion.h2 className='text-global'>Saved spots</motion.h2>
                                    <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>

                                </div>
                                <Spot title="Pesangan" />
                                <Spot title="Cloud 9" />
                                <Spot title="Tuason" />
                            </div>
                        </div>







                    </div>
                </motion.div>

            </motion.div >


        </>

    )
}

const Spot = (props) => {
    const { title } = props;
    return (
        <div className='flex w-full items-center py-3 border-b border-slate-200 dark:border-zinc-700 relative'>
            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
            <div className='mr-auto font-medium'>{title}</div>
            <Favorite />
            <Link to="/spot-detail" className='absolute w-full h-full' />
        </div>
    )
}

