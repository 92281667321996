
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_LEVEL from '../../assets/img/wave.svg';
import SRC_STYLE from '../../assets/img/board.svg';
import SRC_STANCE from '../../assets/img/stance.svg';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip  from '../ui/Chip';


export function Details() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your details" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Expandable
                            title="Your skill level"
                            closedtitle="Intermediate"
                            opentitle="Please select"
                            hasIcon="true"
                            icon={SRC_LEVEL}
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Beginner" />
                                    <Chip title="Intermediate" />
                                    <Chip title="Advanced" />
                                    <Chip title="Expert" />
                                    
                                </div>
                            }
                        />
                        <Expandable
                            title="Stance"
                            closedtitle="Goofy"
                            opentitle="Please select"
                            hasIcon="true"
                            icon={SRC_STANCE}
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Regular" />
                                    <Chip title="Goofy" />
                                    
                                </div>
                            }
                        />
                        <Expandable
                            title="Style"
                            closedtitle="Shortboard"
                            opentitle="Please select"
                            hasIcon="true"
                            icon={SRC_STYLE}
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Longboard" />
                                    <Chip title="Shortboard" />
                                    <Chip title="Foil" />
                                    <Chip title="Twin fin" />
                                    
                                </div>
                            }
                        />
                    </div>
                </div>
            </motion.div>

        </>

    )
}








