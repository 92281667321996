import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";

import SRC_BOARD from '../../assets/img/board-1.png';
import { motion, useAnimation, AnimatePresence } from "framer-motion"

export function CheckoutDrawer(props) {
    const { price, per } = props;
    return (
        <>
            <AnimatePresence>
                <motion.div layout layoutId='drawerWrapper' initial={{ y: "100%" }} animate={{ y: 0 }} className='w-full shadow-inverted gap-x-global flex flex-col  p-global py-2 border-t border-zinc-200 dark:border-zinc-600 bg-white dark:bg-zinc-800  '>
                    <div className='flex items-center w-full justify-between'>
                        <div className=' rounded-xl p-2 w-2/3 relative'>
                            <div className='text-lg font-semibold flex items-start'>{price}
                                <div className='text-xs translate-y-1 ml-1 font-normal text-slate-400 dark:text-zinc-500'>/ {per}</div>
                            </div>

                        </div>
                        <Link className=' w-1/3 block' to="/checkout/step-one">
                            <motion.button
                                whileTap={{ scale: 1.1 }} className='w-full px-4 ml-auto whitespace-nowrap flex-shrink-0 py-4  bg-sky-500 text-white text-sm rounded-lg'>
                                Request
                            </motion.button>
                        </Link>
                    </div>
                </motion.div>
            </AnimatePresence>
        </>
    )
}

export function CheckoutDrawerSummary(props) {
    const { price, per } = props;
    return (
        <>
            <AnimatePresence>
                <motion.div layout layoutId='drawerWrapper' className='w-full shadow-inverted gap-x-global flex flex-col p-global py-2 border-t border-zinc-200 dark:border-zinc-600 bg-white dark:bg-zinc-800  '>
                    <motion.div initial={{y:50, opacity:0}} animate={{y:0, opacity:1}} className='text-xl flex items-center font-semibold pb-global pt-3 border-b border-slate-200 mb-global'>
                    <img src={SRC_BOARD} className='w-9 rounded-md border-slate-200 border object-cover mr-2' />
                        Firewire Seaside 6'1
                    </motion.div>
                    <div className='flex items-center w-full justify-between'>
                        <div className=' rounded-xl p-2 w-2/3 relative'>
                            <div className='text-lg font-semibold flex items-start'>{price}
                                <div className='text-xs translate-y-1 ml-1 font-normal text-slate-400 dark:text-zinc-500'>/ {per}</div>
                            </div>

                        </div>
                        <Link className=' w-1/3 block' to="/checkout/step-one">
                            <motion.button
                                whileTap={{ scale: 1.1 }} className='w-full px-4 ml-auto whitespace-nowrap flex-shrink-0 py-4  bg-sky-500 text-white text-sm rounded-lg'>
                                Request
                            </motion.button>
                        </Link>
                    </div>
                </motion.div>
            </AnimatePresence>
        </>
    )
}







