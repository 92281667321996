
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../assets/img/calendar.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_MEDIA from '../assets/img/media.svg';
import SRC_SIZE from '../assets/img/size.svg';
import SRC_STAR from '../assets/img/star-empty.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_TAG from '../assets/img/tag.svg';
import SRC_ADD from '../assets/img/add.svg';
import { Range } from 'react-range';

import SRC_INFO from '../assets/img/info.svg';
import SRC_TIME from '../assets/img/time.svg';
import SRC_CROWD from '../assets/img/crowd.svg';

import SRC_CHEV from '../assets/img/chevron-right.svg';

import { HeaderEdit } from './Header';
import { InputField, RangeSlider, TextField, DateField } from './ui/Forms';
import Switch from './ui/Switch';
import { Expandable } from './ui/Expandable';
import Chip from './ui/Chip';

import SearchPeople from './SearchPeople';



export function AddPost() {
    const [isTag, setIsTag] = useState(false);
    const handleOpenTag = () => {
        setIsTag(true);
    };
    const handleCloseTag = () => {
        setIsTag(false);
    };

    const value = 3.5;
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderEdit title="Add Session" />

                <div className='w-full h-full overflow-auto no-scrollbar'>

                    <div className='w-full px-global pt-0'>
                        <div className='w-full mt-2 mb-4 text-sm px-global min-h-32 text-sky-500 flex items-center justify-center flex-col b border border-dashed border-sky-500 rounded-xl'>
                            <img src={SRC_MEDIA} className='invert-blue' />
                            Add media
                        </div>
                        <form className='w-full flex flex-col gap-4 '>
                            <InputField label="Title of your session" id="title" />

                            <DateField label="Date" id="date" hasIcon="true" type="date" value="Time" icon={SRC_CALENDAR} />
                            <div className='grid grid-cols-2 gap-x-3'>
                                <InputField label="Start" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                                <InputField label="End" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                            </div>
                            <InputField label="Where did you go?" id="location" hasIcon="true" icon={SRC_LOCATION} />

                        </form>


                    </div>
                    <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 mt-6' />
                    <div className='w-full px-global pt-0 pb-32'>
                        <Expandable
                            title="How did it go?"
                            closedtitle="Describe your session."
                            opentitle="Describe your session."
                            hasIcon="true"
                            icon={SRC_INFO}
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <TextField label="How did it go?" id="how" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save</button>
                                    </form>
                                </>
                            }
                        />
                        <Expandable
                            title="Rate the conditions"
                            closedtitle="How good was it?"
                            opentitle="How good was it?"
                            hasIcon="true"
                            icon={SRC_STAR}
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <div className='w-full flex items-center justify-center'>
                                            <Rating name="half-rating" defaultValue={5} max={10} size='large' />
                                        </div>


                                    </form>
                                </>
                            }
                        />
                        <Expandable
                            title={
                                <>
                                    Wave count
                                </>
                            }
                            closedtitle="How many waves did you catch?"
                            opentitle="0 min - 20 max"
                            hasIcon="true"
                            icon={SRC_WAVE}
                            content={
                                <>

                                    <div className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <ValuePicker />
                                        {/*<RangeSlider min="0" max="20" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save</button>*/}
                                    </div>
                                </>
                            }
                        />

                        <Expandable
                            title="Wave size"
                            closedtitle="How big were the waves?"
                            opentitle="Please select"
                            hasIcon="true"
                            icon={SRC_SIZE}
                            content={
                                <>
                                    <div className='w-full overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                        <Chip title="Flat" />
                                        <Chip title="Small" />
                                        <Chip title="Shoulder High" />
                                        <Chip title="Head high" />
                                        <Chip title="2x Overhead" />
                                        <Chip title="3x Overhead" />
                                        <Chip title="Massive" />
                                    </div>
                                </>
                            }
                        />
                        <Expandable
                            title="Crowd factor"
                            closedtitle="How many people were in the lineup?"
                            opentitle="Please select"
                            hasIcon="true"
                            icon={SRC_CROWD}
                            content={
                                <>
                                    <div className='w-full overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                        <Chip title="Empty" />
                                        <Chip title="Medium" />
                                        <Chip title="Full" />
                                        <Chip title="Too much" />
                                    </div>
                                </>
                            }
                        />
                        <div className='w-full' onClick={handleOpenTag}>
                            <Expandable
                                title="Tag people"
                                closedtitle="Who did you surf with?"
                                opentitle="Who did you surf with?"
                                hasIcon="true"
                                icon={SRC_TAG}
                                content={
                                    <>

                                    </>
                                }
                            />
                        </div>
                        <Expandable
                            title="Board"
                            closedtitle="Which board did you use?"
                            opentitle="Which board did you use?"
                            hasIcon="true"
                            icon={SRC_BOARD}
                            content={
                                <div className='w-full pl-10'>
                                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                                        Seaside
                                        <input className="size-5 ml-auto accent-sky-500 text-sky-500 bg-sky-500" type="checkbox" />
                                    </div>
                                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                                        JS Monsta
                                        <input className="size-5 ml-auto accent-sky-500" type="checkbox" />
                                    </div>
                                    <div className='flex items-center gap-x-0 py-3 opacity-40'>
                                        <div className="size-12 flex items-center justify-center">
                                            <img className='rounded-full object-contain dark:invert' src={SRC_ADD} />
                                        </div>
                                        Add Board
                                    </div>
                                </div>
                            }
                        />

                    </div>
                </div>
            </motion.div >

            <AnimatePresence>
                {isTag && <SearchPeople onClose={handleCloseTag} />}
            </AnimatePresence>

        </>

    )
}

export function ValuePicker(props) {
    return (
        <>
            <div className='w-full overflow-auto no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                <Chip title="1" />
                <Chip title="2" />
                <Chip title="3" />
                <Chip title="4" />
                <Chip title="5" />
                <Chip title="6" />
                <Chip title="7" />
                <Chip title="8" />
                <Chip title="9" />
                <Chip title="10" />
                <Chip title="11" />
                <Chip title="12" />
                <Chip title="13" />
                <Chip title="14" />
                <Chip title="15" />
                <Chip title="16" />
                <Chip title="17" />
                <Chip title="18" />
                <Chip title="19" />
                <Chip title="20 +" />
            </div>


        </>
    );
}