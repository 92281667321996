
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_STAR from '../assets/img/star.svg';
import SRC_MAP from '../assets/img/map-example.jpg';
import SRC_GREAT from '../assets/img/great.svg';

import StaticCard from "./ui/StaticCard";
import ProductCard from "./ui/ProductCard";

export function Spots() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 100, scale: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className='page-wrapper p-0 pt-1 no-scrollbar'>
                <img className='w-full sticky top-0 -mb-4' src={SRC_MAP} />

                <motion.div 
                initial={{y:100}}
                animate={{y:0}}
                className='w-full'>
                    <AnimatedTabs />
                </motion.div>



            </motion.div>
        </>

    )
}

const SpotItem = (props) => {
    const { size, name } = props;

    return (
        <Link to="/spot-detail" className='w-full py-4 flex justify-between items-center border-b border-slate-100 dark:border-zinc-700 last-of-type:border-none'>
            <div>
                <h3 className='font-semibold text-lg'>{name}</h3>
                <div className='w-full flex items-center mt-1'>
                    <img className='size-6 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                    <img className='size-6 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                    <img className='size-6 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-10' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    <div className='text-xs ml-4 text-slate-400 dark:text-zinc-500'>239 Sessions</div>
                </div>
            </div>
            <img className='ml-auto mr-2 dark:invert' src={SRC_GREAT} />
            <div className='bg-slate-200 dark:bg-zinc-700 w-[80px] rounded-xl dark:text-white text-center py-3 font-semibold'>{size}ft</div>
        </Link>
    )
}

export default SpotItem

const tabs = [
    {
        name: 'tab1',
        label: 'Your area',
        render: () => {
            return <>
                <SpotItem name="Cloud 9" size="6-7" />
                <SpotItem name="Pesangan" size="6-7" />
                <SpotItem name="Rock island" size="6-7" />
                <SpotItem name="Stimpy's" size="6-7" />
                <SpotItem name="Daku island" size="6-7" />
                <SpotItem name="Tuason" size="6-7" />
                <SpotItem name="Jacking Horse" size="6-7" /></>
        }
    },
    {
        name: 'tab2',
        label: 'Favorites',
        render: () => {
            return <>
                <SpotItem name="Pipeline" size="10-12" />
                <SpotItem name="Jaws" size="24-30" />
                <SpotItem name="Pesangan" size="6-7" />
            </>
        }
    },
   

];


function AnimatedTabs() {
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleClick = (e, tab) => {
        e.preventDefault()

        setActiveTab(tab)
    }

    const isSelected = tab => activeTab.name === tab.name

    return (
        <>
            <div className="w-full flex sticky top-0 bg-white dark:bg-zinc-800 z-50 gap-x-3 overflow-auto no-scrollbar border-b border-slate-200 dark:border-zinc-700">
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        className=" w-1/2 text-center"
                    >
                        <a className='px-4 py-3 pt-4 block ' href="#" onClick={e => handleClick(e, tab)}>
                            {tab.label}
                        </a>

                        {isSelected(tab) && (
                            <motion.div layoutId="indicator" className="h-1 bg-sky-500 rounded-full" />
                        )}
                    </div>
                ))}
            </div>

            <div className="w-full bg-white dark:bg-zinc-800 relative z-40 px-global">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab.name || "empty"}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        {activeTab && activeTab?.render()}
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    )
}

