import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EYE from '../assets/img/eye.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import { InputField, TextField } from './ui/Forms';

import Switch from "./ui/Switch";



export function Onboarding() {
    return (
        <>

            <div className="fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0">

                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className='w-full p-8 pt-12 h-full overflow-auto items-start justify-start flex flex-col'>
                    <div className='w-full my-auto'>
                        <h1 className='text-4xl font-semibold'>Hi Tomas,</h1>
                        <h1 className='text-xl font-light'>Do you do some of the following?</h1>
                        {/*<motion.div
                            initial={{ scale: 0.6, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 0.1 }}
                            className=' flex-shrink-0 flex items-center py-12'>
                            <img className='size-24 rounded-full object-cover mr-5' src={SRC_TOMAS} />
                            <button className='px-5 py-3 rounded-lg bg-sky-500 text-white text-center'>
                                Upload image
                            </button>
    </motion.div>*/}
                    </div>
                </motion.div>
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className='w-full h-[68px] border-t border-slate-100 flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0'>
                    <Link to="/signup" className='text-sky-500 font-semibold text-sm'>Back</Link>
                    <div className='flex items-center gap-2'>
                        <Link to="/signup" className='text-slate-400 font-semibold text-sm px-4'>Skip</Link>
                        <Link className='px-5 py-2 rounded-lg bg-sky-500 text-white text-center'>Next</Link>
                    </div>

</motion.div>
            </div>



        </>
    );
}
