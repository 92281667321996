import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

import SRC_FAVORITE from '../../assets/img/favorite.svg';
import SRC_FAVORITED from '../../assets/img/favorite-full.svg';

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export default function Favorite() {
    const [isLike, setIsLike] = useState(false);

    return (
        <>
            {isLike
                ?
                <motion.button
                    onClick={() => setIsLike(false)}
                    whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg relative z-20'>
                    <motion.img whileTap={{rotate: 45}}  className='dark:invert' src={SRC_FAVORITED} />
                </motion.button>
                :
                <motion.button
                    onClick={() => setIsLike(true)}
                    whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg relative z-20'>
                    <motion.img whileTap={{rotate: 45}} className='dark:invert' src={SRC_FAVORITE} />
                </motion.button>

            }
        </>
    );
}

