
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_ADD from '../../assets/img/add.svg';
import SRC_NATHAN from '../../assets/img/nathan.webp';
import SRC_TOMAS from '../../assets/img/tomas.jpg';
import SRC_FILTER from '../../assets/img/filter.svg';

import SRC_COCO from '../../assets/img/coco.jpeg';

import { Drawer, DrawerOption } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';


export function Events() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Events" hasIcon="true" icon={SRC_ADD} link="/profile/edit-event" hasCustom="true"
                    custom={
                        <>
                            <div className='size-[26px] rounded-lg relative ml-6 flex items-center justify-center'>
                                <img src={SRC_FILTER} />
                                <Drawer
                                    options={
                                        <>
                                            <DrawerOption title="Upcoming" hasCheckbox="true" />
                                            <DrawerOption title="Completed" hasCheckbox="true" />
                                        </>
                                    }

                                />
                            </div>
                        </>
                    }
                />

                <div className='w-full h-full overflow-auto no-scrollbar'>

                    <div className='w-full p-global pt-0'>

                        <Event title="Trip to north" img="https://tinyurl.com/2bmdckce" date="Mar 19, 2024" hasUpcoming="true" />
                        <Event title="One day trip with locals" img="https://tinyurl.com/2d28uk2t" date="Mar 19, 2024" hasUpcoming="true" />
                        <Event title="Yoga retreat" img="https://tinyurl.com/25wh97hh" date="Mar 18, 2024" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function Event(props) {
    const { img, title, date, hasUpcoming } = props;
    return (
        <Link to="/profile/event-detail" className="w-full py-global relative mb-global flex flex-col items-center last-of-type:border-none">

            <div className='w-full relative before:w-full before:block before:pt-[66%]'>
                <img src={img} className='size-full absolute top-0 left-0 rounded-2xl object-cover' />
                {hasUpcoming && (
                    <div className='text-xxs absolute top-2 right-2 bg-sky-500 text-white px-2 py-1 uppercase rounded-xl'>Upcoming</div>
                )}
            </div>
            <div className='w-full pt-global'>

                <div className='font-semibold text-lg'>{title}</div>
                <div className='text-slate-500 text-sm flex items-center gap-x-4'>
                        {date}
                    </div>
                <div className='text-sm my-2'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...
                </div>

                <div className='w-full flex items-center '>
                    <img className='size-7 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                    <img className='size-7 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                    <img className='size-7 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-10' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    <div className='text-slate-500 text-sm flex items-center gap-x-4 ml-4'>+ 3</div>
                </div>
            </div>

        </Link>
    )
}








