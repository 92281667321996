import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";


import SRC_APPLE from '../assets/img/apple.svg';
import SRC_CARD from '../assets/img/card.svg';
import SRC_GOOGLE from '../assets/img/google.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_ADD from '../assets/img/add.svg';

import SRC_CLOSE from '../assets/img/close.svg';

import Switch from "./ui/Switch";
import { Expandable } from './ui/Expandable';
import { InputField, TextField } from './ui/Forms';
import Chip from './ui/Chip';



function PaymentDrawer({ onClose }) {

    return (
        <>

            <motion.div className='flex dark:text-white flex-col justify-end h-full w-full fixed overflow-hidden top-0  z-[99999999]'>
                <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 100 }} onClick={onClose} className='w-full h-full absolute top-0 left-0 bg-black/40' />

                <motion.div initial={{ y: "100%" }} animate={{ y: 0 }} exit={{ y: "100%" }} transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }} className="w-full rounded-tr-3xl rounded-tl-3xl  z-50 relative p-global shadow-inverted overflow-auto origin-top bg-white flex-col flex dark:bg-zinc-800 ">

                    <div className='w-full flex text-lg items-center font-semibold justify-between mb-4'>
                        Add payment method
                        <img onClick={onClose} src={SRC_CLOSE} />
                    </div>
                    <Expandable
                        title="Credit/Debit Card"
                        hasIcon="true"
                        icon={SRC_CARD}
                        content={
                            <>
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Name on card" id="name" />
                                    <InputField label="Card number" id="card" />
                                    <div className='w-full grid grid-cols-2 items-center gap-x-3'>
                                        <InputField label="CCV" id="ccv" />
                                        <InputField label="Expiry Date" id="expiry" />
                                    </div>
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Add Card</button>
                                </form>
                            </>
                        }
                    />

                    <Expandable
                        title="Apple Pay"
                        hasIcon="true"
                        icon={SRC_APPLE}
                        content={
                            <>
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Apple ID" id="name" />
                                    
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Add Card</button>
                                </form>
                            </>
                        }
                    />
                    <Expandable
                        title="Google Pay"
                        hasIcon="true"
                        icon={SRC_GOOGLE}
                        content={
                            <>
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Google id" id="name" />
                                    
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Add Card</button>
                                </form>
                            </>
                        }
                    />

                </motion.div>
            </motion.div>

        </>
    );
}



export default PaymentDrawer