import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"

import SRC_FAVORITE from '../assets/img/favorite.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_STAR from '../assets/img/star.svg';

import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_JOHN from '../assets/img/john.jpeg';
import SRC_RAMZI from '../assets/img/ramzi.jpeg';
import SRC_COCO from '../assets/img/coco.jpeg';

import Favorite from "./ui/Favorite";
import { SocialPost } from './Social'

import { Header } from './Header'

export function Instructors() {


    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <Header hasBack="true" title="29 Surf Instructors" />
                <div className='w-full h-full overflow-auto no-scrollbar pb-10'>
                    <div className='px-global py-8'>
                        <div className='flex flex-col items-center'>
                            <motion.h3 className='text-3xl font-semibold'>San Diego</motion.h3>
                            <div className='text-sm text-slate-400 dark:text-zinc-500'>
                                California, United States
                            </div>
                        </div>
                    </div>

                    <InstructorItem name="Coco" image={SRC_COCO} />
                    <InstructorItem name="Tomas" image={SRC_TOMAS} />
                    <InstructorItem name="Nathan" image={SRC_NATHAN} />
                    <InstructorItem name="Ramzi" image={SRC_RAMZI} />
                    <InstructorItem name="John" image={SRC_JOHN} />


                </div>



            </motion.div>
        </>

    )
}


export function InstructorItem(props) {
    const { title, image, name } = props;
    return (
        <motion.div 
        initial={{opacity:0, y:100}}
        animate={{opacity:100, y:0}}
        transition={{delay:0.2}}
        className='w-full px-8 py-6'>
            <div className='w-full relative before:pt-[100%] before:block before:w-full rounded-2xl overflow-hidden'>
                <div className='absolute top-3 right-3 z-40'>
                    <motion.button
                        whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg relative z-20'>
                        <motion.img whileTap={{ rotate: 45 }} className='invert' src={SRC_FAVORITE} />
                    </motion.button>
                </div>
                <Link to="/surfer-profile" className='absolute w-full h-full top-0 left-0 z-30' />
                <img className='w-full h-full absolute object-cover top-0 left-0' src={image} />
                <div className='gradient-overlay top-0 left-0 z-20 size-full p-6 flex items-end text-white absolute bg-gradient-to-t from-black/40 to-transparent'>
                    <div className='w-full'>
                        <h3 className='card-title font-semibold text-2xl mb-px'>{name}</h3>
                        <div className='flex w-full justify-between text-sm opacity-90'>
                            <div>0.2km</div>
                            <div className='flex items-center gap-x-1'>
                                4.9 <img className='invert size-4 -translate-y-px' src={SRC_STAR} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-3 text-sm'>I have 16 years of surfing e xperience and 3 years of teaching experience for all levels...</div>
            <div className='flex items-center gap-x-3 mt-2'>
                <div className='font-semibold'>$60/h</div>
                <div className='size-1 rounded-full bg-slate-300 dark:bg-zinc-600' />
                <div className='text-sky-500'>Save 30%</div>
            </div>
        </motion.div>
    )
}



