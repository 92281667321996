
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_ADD from '../../assets/img/add.svg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_BOARDTWO from '../../assets/img/board-2.png';
import SRC_BOARDTHREE from '../../assets/img/board-3.png';

import { InputField } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';




export function Products() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your Products" hasIcon="true" icon={SRC_ADD} link="/profile/add-product" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Product title="Seaside 5'1" img={SRC_BOARD} price="$50/day" />
                        <Product title="JS Monsta 6'1" img={SRC_BOARDTWO} price="$80/day" />
                        <Product title="Slater Outerknown" img={SRC_BOARDTHREE} price="$800" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function Product(props) {
    const { img, title, price } = props;
    return (
        <Link to="/profile/add-product" className="w-full py-global border-b border-slate-200 dark:border-zinc-600 flex items-center last-of-type:border-none">
            <img src={img} className='size-20 mr-global border border-slate-200 dark:border-zinc-600 rounded-xl object-contain' />
            <div>
                <div className='font-semibold'>{title}</div>
                <div className='text-slate-500 text-sm'>{price}</div>
            </div>
        </Link>
    )
}








