import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom";

import SRC_EYE from '../assets/img/eye.svg';
import { InputField, TextField } from './ui/Forms';

import Switch from "./ui/Switch";
import swal from 'sweetalert';
import SocialLogin from './SocialLogin';
import { GetRouteSearchParams } from '../helpers/GeneralHelper';

import { API_ROOT } from '../api';
import axios from 'axios';

export const Signup = (props) => {

    const { TRANSLATE, history, onStartUser, type, location, LANGUAGE, OriginFrom } = props;

    const [EMAIL, setEmail] = useState('');
    const [PASSWORD, setPassword] = useState('');
    const [FULLNAME, setFullname] = useState('');

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeCode = routeParams.code || '';

        if (routeCode) {
            //alert('The Code is ===> '+routeCode);
            onLoginAppleFlow(routeCode);
        }

    }, [location.search])

    const onKeyUp = (event) => {

        event.preventDefault();


        if (event.keyCode === 13) {

            event.target.blur();
            onSignup();

        }
    }

    const onSignup = () => {

        const FORM = {
            fullname: FULLNAME.trim(),
            email: EMAIL.trim(),
            password: PASSWORD.trim(),
            lang: TRANSLATE.lang,
            type: OriginFrom
        }

        console.log("FORM ===> ", FORM)

        if (!isValidForm(FORM, TRANSLATE)) {
            return
        }


        setLoading(true);

        axios.post(API_ROOT + '/users/signup', FORM).then(response => {

            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            if (OriginFrom === 'provider') {
                window.location.href = '/' + LANGUAGE + '/onboarding/provider/step1';
            }
            else {
                onStartUser();
                history.push('/' + LANGUAGE + '/settings');
            }

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: type || 'SIGNUP',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

        })
            .catch(error => {

                setLoading(false);

                if (!error || !error.response) {
                    return swal('Signup', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                console.log("ERROR ===> ", error_message);

                if (error_message === 'missing_password') {
                    return swal(TRANSLATE.wd_signup, TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_email)
                }
                else if (error_message === 'user_already_exists') {
                    return swal(TRANSLATE.wd_signup, TRANSLATE.error_already_exists)
                }

                return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_credentials)

            });
    }

    const onLoginAppleFlow = async (authCode) => {

        try {

            setLoading(true);

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var FORM = {
                lang: LANGUAGE,
                code: authCode,
                timezone: userTimezone
            }

            //return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(API_ROOT + '/users/login/apple', FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                history.push(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    history.push(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return swal('Login', TRANSLATE.error_invalid_credentials)
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return swal('Login', TRANSLATE.error_missing_password)
                    }
                    else if (error_message === 'missing_email') {
                        return swal('Login', TRANSLATE.error_invalid_email)
                    }

                    return swal('Login', TRANSLATE.error_invalid_credentials)
                });

        }
        catch (e) {
            setLoading(false);
            history.push(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }

    const onLoginGoogle = async (params) => {

        if (!params) {
            return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
        }

        setLoading(true);

        if (params.access_token) {
            const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
            const ResponseData = Response ? Response.data : {};
            params = {
                ...params,
                ...ResponseData
            }
        }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var FORM = {
            lang: LANGUAGE,
            oauth: params,
            timezone: userTimezone

        }

        axios.post(API_ROOT + '/users/login/google', FORM).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();


            history.push('/' + LANGUAGE + '/settings');

        })
            .catch(error => {

                setLoading(false);

                if (!error || !error.response) {
                    return swal('Login', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return swal('Login', TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return swal('Login', TRANSLATE.error_invalid_email)
                }

                return swal('Login', TRANSLATE.error_invalid_credentials)
            });
    }

    const onLoginApple = async (params) => {
        const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
        if (isNativeApp) {
            const webData = {
                type: 'LOGIN_APPLE'
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(webData));
        }
        else {
            if (params) {
                const fun = params.fun;
                fun()
                // alert('Download the app to login with Apple')
            }

        }
    }

    return (
        <>

            <div className="fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0">
                <div className='w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0'>
                    <div className="font-semibold tracking-wider text-xl">SURF<span className='text-sky-500'>YX</span></div>
                    <Link to="/login" className='text-sky-500 font-semibold text-sm'>Login</Link>
                </div>
                <div className='w-full p-8 h-full overflow-auto items-center justify-center flex flex-col'>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className='w-full mb-12 flex-col gap-3 items-center justify-center text-center'>
                        <h1 className='text-4xl font-semibold'>Hello there!</h1>
                        <h3 className='text-lg text-slate-400'>Please create account to continue</h3>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className='w-full gap-3 flex flex-col'>

                        <InputField label="Name" inputProps={{
                            value: FULLNAME,
                            Placeholder: 'Type Here',
                            onChange: (e) => setFullname(e.target.value),
                            onKeyUp: (e) => onKeyUp(e)

                        }} />
                        <InputField label="Email" inputProps={{
                            value: EMAIL,
                            Placeholder: 'Type Here',
                            onChange: (e) => setEmail(e.target.value),
                            onKeyUp: (e) => onKeyUp(e)

                        }} />
                        <InputField label="Password" id="password" hasIcon="true" icon={SRC_EYE} inputProps={{
                            value: PASSWORD,
                            Placeholder: 'Type Here',
                            onChange: (e) => setPassword(e.target.value),
                            onKeyUp: (e) => onKeyUp(e)

                        }} />

                        {isLoading ? (
                            <div className='w-full rounded-xl bg-zinc-500 text-white shadow-2xl p-4 text-center mt-3'>
                                Loading...
                            </div>
                        ) : (
                            <div className='w-full rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3' onClick={() => onSignup()}>
                                Sign up
                            </div>
                        )}

                        {/* <Link to="/onboarding" className='w-full rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3'>Sign up</Link> */}
                        <div className='flex my-5 gap-5 items-center justify-center text-xs text-slate-400'>
                            <div className='w-[40px] h-px bg-slate-300' />
                            Or continue with
                            <div className='w-[40px] h-px bg-slate-300' />
                        </div>
                        <SocialLogin {...props}
                            onLoginApple={onLoginApple}
                            onLoginGoogle={onLoginGoogle}
                        />
                    </motion.div>
                </div>
            </div>



        </>
    );
}

const isValidEmail = (email) => {

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

}

const isValidForm = (FORM, LANGUAGE) => {

    if(!LANGUAGE){
        LANGUAGE = 'en';
    }
    // Define language-specific error messages and titles with emojis
    const errorMessages = {
        en: {
            title: 'Error! ⚠️',
            fullnameLengthError: 'Fullname must be at least 6 characters long. ❌',
            emailFormatError: 'Invalid email format. Please enter a valid email address. ❌',
            passwordLengthError: 'Password must be at least 6 characters long. ❌'
        },
        fr: {
            title: 'Erreur ! ⚠️',
            fullnameLengthError: 'Le nom complet doit comporter au moins 6 caractères. ❌',
            emailFormatError: 'Format d\'email invalide. Veuillez saisir une adresse email valide. ❌',
            passwordLengthError: 'Le mot de passe doit comporter au moins 6 caractères. ❌'
        },
        it: {
            title: 'Errore! ⚠️',
            fullnameLengthError: 'Il nome completo deve contenere almeno 6 caratteri. ❌',
            emailFormatError: 'Formato email non valido. Si prega di inserire un indirizzo email valido. ❌',
            passwordLengthError: 'La password deve contenere almeno 6 caratteri. ❌'
        },
        es: {
            title: '¡Error! ⚠️',
            fullnameLengthError: 'El nombre completo debe tener al menos 6 caracteres. ❌',
            emailFormatError: 'Formato de email no válido. Por favor, introduzca una dirección de email válida. ❌',
            passwordLengthError: 'La contraseña debe tener al menos 6 caracteres. ❌'
        }
    };
    

    const errorMessagesForLanguage = errorMessages[LANGUAGE] || errorMessages['en'];

    // Extract form data
    const fullname_string = FORM.fullname.trim();
    const email_string = FORM.email.trim();
    const password_string = FORM.password.trim();

    // Check validity of fullname
    if (fullname_string.length < 6) {
        swal(errorMessagesForLanguage.title, errorMessagesForLanguage.fullnameLengthError, 'warning');
        return false;
    }

    // Check validity of email
    if (!isValidEmail(email_string)) {
        swal(errorMessagesForLanguage.title, errorMessagesForLanguage.emailFormatError, 'warning');
        return false;
    }

    // Check validity of password
    if (password_string.length < 6) {
        swal(errorMessagesForLanguage.title, errorMessagesForLanguage.passwordLengthError, 'warning');
        return false;
    }

    return true;
}

export default Signup