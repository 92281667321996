import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";


import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EVERYWHERE from '../assets/img/everywhere.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_FOLLOWING from '../assets/img/following.svg';
import SRC_CROWD from '../assets/img/crowd.svg';

import SRC_BACK from '../assets/img/back.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_COCO from '../assets/img/coco.jpeg';
import SRC_RAMZI from '../assets/img/ramzi.jpeg';

import Switch from "./ui/Switch";
import Favorite from './ui/Favorite';
import Chip from './ui/Chip';



function SearchPeople({ onClose }) {

    return (
        <>

            <motion.div initial={{x:'100%'}} exit={{ x: '100%' }} animate={{x:0}} transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }} className='flex flex-col h-full w-full fixed overflow-hidden top-0 bg-white dark:bg-zinc-800  z-[9999999]'>
                <div className='p-global w-full'>
                    <div className='w-full relative'>
                        <motion.img className='absolute left-4 top-[15px] dark:invert z-20' src={SRC_BACK} onClick={onClose} />
                        <motion.input className='bg-slate-100 pl-12 rounded-full w-full focus:outline-sky-500 dark:bg-zinc-700 p-4' placeholder='Search name' />
                    </div>
                </div>
                <motion.div className='w-full px-global h-full overflow-auto'>

                    <Person img={SRC_TOMAS} name="Tomas Zeman" location="Siargao Island, Philippines" />
                    <Person img={SRC_COCO} name="Coco Ho" location="North Shore, Oahu, Hawaii" />
                    <Person img={SRC_RAMZI} name="Ramzi" location="Imsouane, Morrocco" />
                    <Person img={SRC_NATHAN} name="Nathan Florence" location="North Shore, Oahu, Hawaii" />




                </motion.div>
            </motion.div>

        </>
    );
}

const Person = (props) => {
    const { name, img, location } = props;

    return (
        <div className='flex items-center gap-x-4 border-b border-slate-200 dark:border-zinc-700 py-3'>
            <img className='size-12 rounded-full object-cover' src={img} />
            <div>
            {name}
            <div className='text-xs text-slate-400 dark:text-zinc-400'>{location}</div>
            </div>
            <AddButton />
        </div>

    )
}

function AddButton () {
    const [isAdded, setIsAdded] = useState(false);

    return (
        <>
            {isAdded
                ?
                <motion.button 
                    onClick={() => setIsAdded(false)}
                    whileTap={{ scale: 1.1 }} className='px-4 py-2 ml-auto  bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>
                    Remove
                </motion.button>
                :
                <motion.button
                    onClick={() => setIsAdded(true)}
                    whileTap={{ scale: 1.1 }} className='px-4 py-2 ml-auto bg-sky-500 text-white text-sm rounded-lg'>
                    Add
                </motion.button>

            }
        </>
    );
}

export default SearchPeople