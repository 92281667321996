
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../../assets/img/calendar.svg';
import SRC_EDIT from '../../assets/img/edit.svg';
import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_CHAT from '../../assets/img/messages.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_LOCATION from '../../assets/img/location.svg';

import SRC_ADD from '../../assets/img/add.svg';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function EventDetail() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Event" hasIcon="true" icon={SRC_EDIT} link="/profile/edit-event" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full relative before:w-full before:block before:pt-[100%]'>
                        <img src="https://tinyurl.com/2bmdckce" className='size-full absolute top-0 left-0 object-cover' />
                    </div>
                    <div className='w-full px-global pt-0'>
                        <div className="w-full flex flex-col py-8 pt-3 items-start last-of-type:border-none">

                            <div className='pt-global'>
                                <div className='text-slate-500 text-sm'>Saturday, April 20</div>
                                <div className='font-bold text-2xl'>Trip to north</div>
                                <div className='text-sm mt-4'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                                </div>

                                <div className='w-full mt-4 pb-6 border-b border-slate-200 dark:border-zinc-600'>
                                    <div className='flex items-start'>
                                        <div className='text-xl font-semibold'>$20.00 USD</div>
                                        <div className='text-xs text-slate-500 ml-1 mt-1'>/ per person</div>
                                    </div>
                                </div>

                                <div className='flex w-full items-center py-8 border-b border-slate-200 dark:border-zinc-600'>
                                    <img className='size-14 mr-3 rounded-full object-cover' src={SRC_COCO} />
                                    <div>
                                        <div className='text-sm text-slate-400'>Organizer</div>
                                        <div className='font-bold'>Coco Ho</div>
                                    </div>
                                    <Link className='bg-slate-100 text-sm px-4 py-4 text-slate-700 dark:bg-zinc-800 dark:text-white rounded-lg ml-auto flex items-center gap-2'>
                                        <img className='dark:invert size-4' src={SRC_CHAT} />
                                        Chat
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className='mb-8 border-b pb-8 border-slate-200 dark:border-zinc-900'>
                            <div className='text-xl mb-3 font-bold'>Date and time</div>
                            <div className='flex items-center gap-x-1 mb-2'><img className='size-4 dark:invert' src={SRC_CALENDAR} />April 20, 2024</div>
                            <div className='flex items-center gap-x-1'>
                                <img className='size-4 dark:invert' src={SRC_TIME} />
                                9:00am - 4:30pm</div>
                        </div>
                        <div className='mb-8 border-b pb-8 border-slate-200 dark:border-zinc-900'>
                            <div className='text-xl mb-3 font-bold'>Location</div>
                            <div className='flex items-center gap-x-1 mb-2'>
                                <img className='size-4 dark:invert' src={SRC_LOCATION} />
                                Pilar, Siargao, Surigao Del Norte
                            </div>
                        </div>
                        <div className='mb-8 pb-8 '  onClick={handleClick}>
                            <div className='text-xl mb-3 font-bold'>Participants</div>
                            <div className='w-full flex items-center '>
                                <img className='size-10 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                                <img className='size-10 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                                <img className='size-10 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-10' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                               <div className='ml-4'> + 10</div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <button className='bg-sky-500 text-sm px-6 py-3 rounded-md text-white w-full'>Join event</button>
                </div>
            </motion.div >

            <AnimatePresence>
                {isShown &&
                    <>
                        <div className=' fixed w-full h-full top-0 left-0 z-[999909]' onClick={handleClick} />
                        <Participants />
                    </>
                }
            </AnimatePresence>
        </>

    )
}


function Participants() {
    return (

        <div className='fixed bottom-0 left-0 w-full flex items-end justify-start h-full z-[99999]'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 100 }}
                transition={{ duration: 0.1 }}
                exit={{ opacity: 0 }}
                className='bg-black/30 dark:bg-black/70 fixed w-full h-full top-0 left-0 z-50' />
            <motion.div
                key="modal"
                initial={{ y: "90%" }}
                animate={{ y: 0 }}
                transition={{ type: "spring", damping: 40, stiffness: 700 }}
                exit={{ y: "90%" }}
                className='w-full px-global pb-6 dark:text-white rounded-tl-xl rounded-tr-xl bg-white dark:bg-zinc-900 relative z-[9999]'>

                <div className='w-full flex items-center justify-center py-4 before:w-14 before:h-2 before:rounded-full dark:before:bg-zinc-700 before:bg-slate-200 before:block' />
                <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                    Tomas Zeman
                </div>
                <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    John Doe
                </div>
                <div className='flex items-center gap-x-2 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                    Jack Johnson
                </div>
            </motion.div>
        </div>

    )
}