import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EYE from '../assets/img/eye.svg';
import SRC_GOOGLE from '../assets/img/google.svg';
import SRC_APPLE from '../assets/img/apple.svg';
import SRC_FACEBOOK from '../assets/img/facebook.svg';
import { InputField, TextField } from './ui/Forms';

import Switch from "./ui/Switch";



export function Recovery() {
    return (
        <>

            <div className="fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0">
                <div className='w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0'>
                    <div className="font-semibold tracking-wider text-xl">SURF<span className='text-sky-500'>YX</span></div>
                    <Link to="/signup" className='text-sky-500 font-semibold text-sm'>Create account</Link>
                </div>
                <div className='w-full p-8 pt-0 h-full overflow-auto items-center justify-center flex flex-col'>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className='w-full mb-12 flex-col gap-3 items-center justify-center text-center'>
                        <h1 className='text-4xl font-semibold'>Password Recovery</h1>
                        <h3 className='text-lg text-slate-400'>Enter your email below.</h3>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className='w-full gap-3 flex flex-col'>
                        <InputField label="Email" />
                        <Link className='w-full rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3'>Recover password</Link>

                    </motion.div>
                </div>
            </div>



        </>
    );
}
