import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";


import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EVERYWHERE from '../assets/img/everywhere.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_FOLLOWING from '../assets/img/following.svg';
import SRC_CROWD from '../assets/img/crowd.svg';

import SRC_BACK from '../assets/img/back.svg';

import SRC_SEARCH from '../assets/img/search.svg';

import Switch from "./ui/Switch";
import Favorite from './ui/Favorite';



function SearchSpots({ onClose }) {

    return (
        <>

        <motion.div exit={{ height: 0 }} transition={{duration:0.1}} className='flex flex-col h-full w-full absolute overflow-hidden top-0 bg-white dark:bg-zinc-800  z-[9999]'>
            <div className='p-global w-full'>
                <div className='w-full relative'>
                    <motion.img  className='absolute left-4 top-[15px] dark:invert z-20' src={SRC_BACK}  onClick={onClose}  />
                    <motion.input initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1 }} transition={{ delay: 0 }} className='bg-slate-100 pl-12 rounded-full w-full focus:outline-sky-500 dark:bg-zinc-700 p-4' placeholder='Search by name or location' />
                    <motion.img initial={{scale:0}} animate={{scale:1}}  className='absolute right-4 top-[15px] dark:invert z-20' src={SRC_CURRENT} />
                </div>
            </div>

            <motion.div
                initial={{ height: 0 }}
                animate={{ height: "100%" }}
                className="w-full h-full overflow-hidden origin-top bg-white text-black flex-col flex dark:bg-zinc-800 dark:text-white">
                <motion.div
                    exit={{ opacity: 0, y: 20 }}
                    className='h-full w-full overflow-auto'>
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0 }}
                        className='w-full py-4 px-global'>
                        <h3 className='text-xl font-semibold mb-3'>Popular spots</h3>
                       
                        <div className='flex w-full items-center py-3 border-b border-slate-200 relative dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
                            <div className='mr-auto'>
                                <div className='mr-auto font-medium'>Cloud 9</div>
                                <div className='text-xs text-slate-400 dark:text-zinc-500'>Siargao Island, Philippines</div>
                            </div>
                            <Favorite />
                            <Link to="/spot-detail" className='absolute w-full h-full left-0 right-0' />
                        </div>
                        <div className='flex w-full items-center py-3 border-b border-slate-200 relative dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
                            <div className='mr-auto'>
                                <div className='mr-auto font-medium'>Pipeline</div>
                                <div className='text-xs text-slate-400 dark:text-zinc-500'>Oahu, Hawaii, USA</div>
                            </div>
                            <Favorite />
                            <Link to="/spot-detail" className='absolute w-full h-full left-0 right-0' />
                        </div>
                        <div className='flex w-full items-center py-3 border-b border-slate-200 relative dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
                            <div className='mr-auto'>
                                <div className='mr-auto font-medium'>Teahupo'o</div>
                                <div className='text-xs text-slate-400 dark:text-zinc-500'>Tahiti, French Polynesia</div>
                            </div>
                            <Favorite />
                            <Link to="/spot-detail" className='absolute w-full h-full left-0 right-0' />
                        </div>
                        <div className='flex w-full items-center py-3 relative '>
                            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
                            <div className='mr-auto'>
                                <div className='mr-auto font-medium'>Tuason Point</div>
                                <div className='text-xs text-slate-400 dark:text-zinc-500'>Siargao Island, Philippines</div>
                            </div>
                            <Favorite />
                            <Link to="/spot-detail" className='absolute w-full h-full left-0 right-0' />
                        </div>



                    </motion.div>



                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className='px-global py-global mt-auto'
                >
                    <motion.button exit={{ y: 100, opacity: 0 }} className='w-full shadow-xl py-3 text-center bg-sky-500 text-white rounded-xl'>Show Results</motion.button>

                </motion.div>

            </motion.div>



        </motion.div>

        </>
    );
}

export default SearchSpots