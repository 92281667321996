const getDistanceBetweenCoordinates = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = getRadiant(lat2 - lat1);
    var dLon = getRadiant(lon2 - lon1);

    lat1 = getRadiant(lat1);
    lat2 = getRadiant(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

const getRadiant = Value => {
    return Value * Math.PI / 180;
};

const onFormatUrl = (url) => {

    if (!url) {
        return url;
    }
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

const onFormatNickname = (fullName) => {
    // Convert full name to lowercase
    const lowercaseName = fullName.toLowerCase();

    // Replace non-alphanumeric characters (except spaces, dots, and underscores) with dots
    const username = lowercaseName.replace(/[^a-z0-9\s._]/g, '.')
        .replace(/\s+/g, '.') // Replace spaces with dots
        .replace(/\.+/g, '.') // Remove consecutive dots
        .replace(/^\.+|\.+$/g, ''); // Trim leading and trailing dots

    // If username exceeds Instagram's maximum length, truncate it
    const maxLength = 30; // Instagram's maximum username length
    if (username.length > maxLength) {
        return username.substring(0, maxLength);
    }

    return username;
}

const onBrowswerDeviceData = (navigator, window) => {
    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    var e = module.init();

    return {
        os: e.os.name,
        browser: e.browser.name
    };
}

const onGetCurrencySymbol = (currency, type) => {

    if (type === 'full') {

        if (currency === 'usd') {
            return '$ - USD - 🇺🇸'
        }
        else if (currency === 'gbp') {
            return '£ - GBP - 🇬🇧'
        }
        else {
            return '€ - EUR - 🇪🇺'
        }
    }
    else {
        if (currency === 'usd') {
            return '$'
        }
        else if (currency === 'gbp') {
            return '£'
        }
        else {
            return '€'
        }
    }

}

const onFormatImgUrl = (url) => {

    if (!url) {
        return ''
    }

    if (url.includes('mydogu.s3.amazonaws.com/')) {
        let newUrl = url.replace('mydogu.s3.amazonaws.com/', 'mydogu.s3.eu-central-1.amazonaws.com/')
        return newUrl
    }

    return url

}


const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};


const onFormatAddress = (location) => {

    const dataAddress = location?.address || {}

    const administrative = dataAddress.administrative;
    const boundary = dataAddress.boundary;
    const village = dataAddress.village;
    const county = dataAddress.county;
    const city = dataAddress.city;
    const state = dataAddress.state;
    const country = dataAddress.country;
    const suburb = dataAddress.suburb;

    const dataType = location.addresstype || '';
    const dataDisplay = location.display_name || '';

    let final_location = (state && state !== city) ? `${city}, ${state}, ${country}` : `${city}, ${country}`;

    if (dataType === 'village') {
        final_location = `${village}, ${county}, ${country}`
    }
    else if (dataType === 'island') {
        final_location = `${dataDisplay}`
    }
    else if (dataType === 'suburb') {
        final_location = `${suburb}, ${state}, ${country}`
    }
    else {

        if (!city) {
            if (boundary) {
                final_location = (state && state !== boundary) ? `${boundary}, ${state}, ${country}` : `${boundary}, ${country}`;
            }
            if (administrative) {
                final_location = (state && state !== administrative) ? `${administrative}, ${state}, ${country}` : `${administrative}, ${country}`;
            }
        }
    }


    return final_location
}

const onFormatAddressFull = (location) => {

    const dataAddress = location?.address || {}

    const administrative = dataAddress.administrative;
    const boundary = dataAddress.boundary;
    const village = dataAddress.village;
    const county = dataAddress.county;
    const city = dataAddress.city;
    const state = dataAddress.state;
    const country = dataAddress.country;
    const suburb = dataAddress.suburb;

    const dataType = location.addresstype || '';
    const dataDisplay = location.display_name || '';
    const dataLatitude = location.latitude || null;
    const dataLongitude = location.longitude || null;

    let final_location = (state && state !== city) ? `${city}, ${state}, ${country}` : `${city}, ${country}`;

    if (dataType === 'village') {
        final_location = `${village}, ${county}, ${country}`
    }
    else if (dataType === 'island') {
        final_location = `${dataDisplay}`
    }
    else if (dataType === 'suburb') {
        final_location = `${suburb}, ${state}, ${country}`
    }
    else {

        if (!city) {
            if (boundary) {
                final_location = (state && state !== boundary) ? `${boundary}, ${state}, ${country}` : `${boundary}, ${country}`;
            }
            if (administrative) {
                final_location = (state && state !== administrative) ? `${administrative}, ${state}, ${country}` : `${administrative}, ${country}`;
            }
        }
    }


    return {
        display_name: dataDisplay,
        latitude: dataLatitude,
        longitude: dataLongitude,
        city,
        country,
        final_location
    }
}

const onFormatAddressFlow = (location) => {

    if (!location) {
        return ''
    }

    if (!location.name || !location.state_name || !location.country_name) {
        return ''
    }

    const city = location.name;
    const state = location.state_name;
    const country = location.country_name;

    return `${city}, ${state}, ${country}`;
}


const onFormatPrices = ({ price_day, price_day_final, duration, category, quantity }) => {

    let PriceTotalFee = parseFloat((price_day_final + (price_day_final * 15 / 100)).toFixed(2));
    let PriceDayFee = parseFloat((price_day + (price_day * 15 / 100)).toFixed(2));

    let PriceNoDiscount = parseFloat((PriceDayFee * duration).toFixed(2));
    let PriceDiscount = parseFloat((PriceNoDiscount - PriceTotalFee).toFixed(2));


    if (category === 'consumable') {
        PriceTotalFee = parseFloat(((price_day + (price_day * 15 / 100)) * (quantity || 1)).toFixed(2));
        PriceDayFee = parseFloat((price_day + (price_day * 15 / 100)).toFixed(2));
        PriceNoDiscount = parseFloat((PriceDayFee * (quantity || 1)).toFixed(2));
        PriceDiscount = 0;
    }

    return {
        Duration: duration,
        PriceTotalFee,
        PriceDayFee,
        PriceNoDiscount,
        PriceDiscount
    }
}

//Calculate Prices
const onCalculateDailyPrice = (PRODUCT, DURATION) => {

    var { price_day, price_month, price_week } = PRODUCT;

    if (DURATION < 7) {
        return price_day
    }
    else if (DURATION >= 7 && DURATION < 30) {
        var PRICE_WEEK_DAILY = price_week / 7;
        return PRICE_WEEK_DAILY
    }
    else if (DURATION >= 30) {
        var PRICE_MONTH_DAILY = price_month / 30;
        return PRICE_MONTH_DAILY
    }

}


const numberWithCommas = (x, CURRENCY) => {
    if (isNaN(x)) return x;

    if (CURRENCY === 'coin') {
        return x.toFixed(2)
    }

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

const onFetchLanguage = (lang) => {

    if (lang === 'en') {
        return 'ENG'
    }
    else if (lang === 'it') {
        return 'IT'
    }
    else if (lang === 'fr') {
        return 'FR'
    }
    else if (lang === 'es') {
        return 'ES'
    }
    else if (lang === 'de') {
        return 'DE'
    }
    else if (lang === 'ar') {
        return 'AR'
    }
    else if (lang === 'ja') {
        return 'JA'
    }
    else if (lang === 'zh') {
        return 'ZH'
    }
}

const onFetchCurrency = (curr) => {

    if (curr === 'eur') {
        return '€'
    }
    else if (curr === 'usd') {
        return '$'
    }
    else if (curr === 'gbp') {
        return '£'
    }
}

const timeAgo = (timestamp) => {
    const now = new Date();
    const createdDate = new Date(timestamp);
    const timeDifference = now - createdDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days}d`;
    } else if (hours > 0) {
        return `${hours}h`;
    } else if (minutes > 0) {
        return `${minutes}min`;
    } else {
        return `${seconds}sec`;
    }
}

const onPlayHaptics = (status) => {
    const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
    if (isNativeApp) {
        const webData = {
            type: 'HAPTICS',
            status
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
    }
}

const onSendReactNative = (webData) => {
    const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
    if (isNativeApp) {
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
    }
}

function getAbbreviation(name) {
    if (!name) {
        return name
    }
    // Split the name into words
    const words = name.split(" ");

    // Initialize an empty string for the abbreviation
    let abbreviation = "";

    // Iterate through the words and append the first character of each word to the abbreviation
    words.forEach(word => {
        abbreviation += word.charAt(0).toUpperCase();
    });

    return abbreviation;
}

function onFormatSocialCounter(count) {

    const formatter = new Intl.NumberFormat('en-GB', {
        notation: "standard",
        compactDisplay: "short"
    })

    let result = formatter.format(count);

    const KAPPA = count / 1000;
    const MILLION = count / 1000000;

    if (count >= 10000 && count < 100000) {
        result = parseInt(KAPPA) + 'K';

        if ((parseFloat(KAPPA) - parseInt(KAPPA)) > 0) {
            result = (KAPPA).toFixed(1) + 'K';
        }
    }
    else if (count >= 100000 && count < 1000000) {
        result = parseInt(KAPPA) + 'K';

        if ((parseFloat(KAPPA) - parseInt(KAPPA)) > 0) {
            result = (KAPPA).toFixed(1) + 'K';
        }
    }
    else if (count >= 1000000) {
        result = parseInt(MILLION) + 'M';

        if ((parseFloat(MILLION) - parseInt(MILLION)) > 0) {
            result = (MILLION).toFixed(1) + 'M';
        }
    }

    return result;
}

const onGeneratePlaceholderNotifications = ({ messageContent, isMe, other_name }) => {

    let generatedText = '';
    let messageOtherName = other_name;

    if (messageContent?.image) {
        generatedText = isMe ? `📷 You shared an image with ${messageOtherName}` : `📷 ${messageOtherName} shared an image with you`;
    } else if (messageContent?.video) {
        generatedText = isMe ? `🎥 You shared a video with ${messageOtherName}` : `🎥 ${messageOtherName} shared a video with you`;
    } else if (messageContent?.audio) {
        generatedText = isMe ? `🎵 You shared an audio with ${messageOtherName}` : `🎤 ${messageOtherName} shared an audio with you`;
    } else if (messageContent?.post) {
        generatedText = isMe ? `📝 You shared a post with ${messageOtherName}` : `📝 ${messageOtherName} shared a post with you`;
    } else if (messageContent?.location) {
        generatedText = isMe ? `📍 You shared a location with ${messageOtherName}` : `📍 ${messageOtherName} shared a location with you`;
    } else if (messageContent?.user) {
        generatedText = isMe ? `👤 You shared a profile with ${messageOtherName}` : `👤 ${messageOtherName} shared a profile with you`;
    } else if (messageContent?.store) {
        generatedText = isMe ? `🏬 You shared a Dogu provider profile with ${messageOtherName}` : `🏬 ${messageOtherName} shared a Dogu provider profile with you`;
    } else if (messageContent?.pet) {
        generatedText = isMe ? `🐶 You shared a dog with ${messageOtherName}` : `🐶 ${messageOtherName} shared a dog with you`;
    }  else if (messageContent?.challenge) {
        generatedText = isMe ? `🐶🥇 You shared a challenge with ${messageOtherName}` : `🐶🥇 ${messageOtherName} shared a challenge with you`;
    }

    return generatedText;
};

const ageToString = (ageInMonths) => {
    if (ageInMonths < 0) {
        return "Age cannot be negative.";
    }

    const years = Math.floor(ageInMonths / 12);
    const months = ageInMonths % 12;

    if (years === 0) {
        return `${months} month${months !== 1 ? 's' : ''}`;
    } else if (months === 0) {
        return `${years} year${years !== 1 ? 's' : ''}`;
    } else {
        return `${years} year${years !== 1 ? 's' : ''} and ${months} month${months !== 1 ? 's' : ''}`;
    }
}

export {
    timeAgo,
    ageToString,
    onGeneratePlaceholderNotifications,
    onFormatSocialCounter,
    GetRouteSearchParams,
    onGetCurrencySymbol,
    onBrowswerDeviceData,
    onFormatUrl,
    onFormatImgUrl,
    getDistanceBetweenCoordinates,
    getRadiant,
    onFormatPrices,
    onCalculateDailyPrice,
    numberWithCommas,
    onFetchCurrency,
    onFetchLanguage,
    onFormatAddress,
    onFormatAddressFlow,
    onFormatAddressFull,
    onPlayHaptics,
    onSendReactNative,
    onFormatNickname,
    getAbbreviation
};

