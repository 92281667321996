
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_BOARDTWO from '../../assets/img/board-2.png';
import SRC_BOARDTHREE from '../../assets/img/board-3.png';

import SRC_COCO from '../../assets/img/coco.jpeg';

import { InputField } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';


export function Orders() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Orders" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Order title="Seaside 5'1" img={SRC_BOARD} price="Mar 19, 2024" hasPending="true" />
                        <Order title="JS Monsta 6'1" img={SRC_BOARDTWO} price="Mar 19, 2024" hasPending="true" />
                        <Order title="Slater Outerknown" img={SRC_BOARDTHREE} price="Mar 18, 2024" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function Order(props) {
    const { img, title, price, hasPending } = props;
    return (
        <Link to="/profile/order-detail" className="w-full py-global relative border-b border-slate-200 dark:border-zinc-600 flex items-center last-of-type:border-none">
            {hasPending && (
                <div className='text-xxs bg-orange-400 text-white absolute right-0 top-global px-2 py-1 rounded-xl'>Pending</div>
            )}
            <div className='size-20 relative mr-global'>
                <img src={SRC_COCO} className='rounded-full object-cover size-7 shadow-sm absolute -bottom-1 -right-1 border-2 border-white dark:border-zinc-900' />
                <img src={img} className='size-full border border-slate-200 dark:border-zinc-600 rounded-xl object-contain' />
            </div>
            <div>
                <div className='font-semibold'>{title}</div>
                <div className='text-slate-500 text-sm flex items-center'>
                    {price}
                </div>
            </div>
            <img src={SRC_CHEV} className='ml-auto dark:invert opacity-45' />
        </Link>
    )
}








