import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"

import SRC_FAVORITE from '../assets/img/favorite.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_STAR from '../assets/img/star.svg';

import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_JOHN from '../assets/img/john.jpeg';
import SRC_FATLIPS from '../assets/img/fat-lips.jpeg';
import SRC_KUDO from '../assets/img/kudo.jpeg';
import ProductCard from "./ui/ProductCard";
import Favorite from "./ui/Favorite";

import { SocialPost } from './Social'

import { Header } from './Header'

export function SurfShops() {


    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <Header hasBack="true" title="5 Surf shops" />
                <div className='w-full h-full overflow-auto no-scrollbar pb-10'>
                    <div className='px-global py-12'>
                        <div className='flex flex-col items-center'>
                            <motion.h3 className='text-3xl font-semibold'>Siargao island</motion.h3>
                            <div className='text-sm text-slate-400 dark:text-zinc-500'>
                                Philippines
                            </div>
                        </div>
                    </div>

                    <ShopItem image={SRC_FATLIPS} name="Fat Lips" />
                    <ShopItem image={SRC_KUDO} name="Kudo Surf" />
                    <ShopItem image={SRC_FATLIPS} name="Fat Lips" />



                </div>



            </motion.div>
        </>

    )
}


export function ShopItem(props) {
    const { title, image, name } = props;
    return (
        <div className='w-full border-t-8 border-slate-200 dark:border-zinc-900 py-global pb-10'>
            <div className='w-full flex items-center mb-3 text-sm px-global pb-global'>
                <Link to="/surfer-profile">
                    <img className='size-10 rounded-full object-cover mr-3' src={image} />
                </Link>
                <div>
                    <Link to="/surfer-profile" className='font-bold'>{name}</Link>
                    <Link to="/spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                        Siargao Island, Philippines
                    </Link>
                </div>
                <Link to="/surfer-profile" className='px-3 py-2 bg-sky-500 text-xs text-white rounded-lg ml-auto'>Visit store</Link>
            </div>
            <motion.div
                className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                <ProductCard name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_deck_grande.png?v=1699917947" />
                <ProductCard name="Mashup" price="$500.00 USD" img="https://cdn.shopify.com/s/files/1/0378/5677/2234/products/slater-designs-ks-twin-2-fin-single-tab-blackblue-987753_720x.webp?v=1700646781" />
                <ProductCard name="Volcanic Too Fish" price="$500.00 USD" img="https://cdn.shopify.com/s/files/1/0378/5677/2234/products/vissla-sonoma-195-boardshort-surplus-331819_720x.jpg?v=1677165132" />
                <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
                
            </motion.div>
        </div>
    )
}



