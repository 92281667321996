
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_WETSUIT from '../../assets/img/wetsuit.svg';
import SRC_MORE from '../../assets/img/more.svg';
import SRC_MEDIA from '../../assets/img/media.svg';
import SRC_BOARD from '../../assets/img/board.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_SALE from '../../assets/img/sale.svg';

import SRC_ADD from '../../assets/img/add.svg';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderEdit } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function AddProduct() {
    const [isTag, setIsTag] = useState(false);
    const handleOpenTag = () => {
        setIsTag(true);
    };
    const handleCloseTag = () => {
        setIsTag(false);
    };

    const value = 3.5;
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderEdit title="Add product" hasIcon="true" icon={SRC_DELETE} />

                <div className='w-full h-full overflow-auto no-scrollbar'>

                    <div className='w-full px-global pt-0'>
                       
                        <div className='w-full mt-2 mb-4 text-sm px-global min-h-32 text-sky-500 flex items-center justify-center flex-col b border border-dashed border-sky-500 rounded-xl'>
                            <img src={SRC_MEDIA} className='invert-blue' />
                            Product photos
                        </div>
                        <form className='w-full flex flex-col gap-4 '>
                            <InputField label="Product name" id="title" />
                            <SelectField
                                title="For Sale or Rent?"
                                options={
                                    <>
                                        <SelectOption title="Sale" icon={SRC_SALE} />
                                        <SelectOption title="Rent" icon={SRC_TIME} />
                                    </>
                                }

                            />
                            <SelectField
                                title="Select category"
                                options={
                                    <>
                                        <SelectOption title="Surfboard" icon={SRC_BOARD} />
                                        <SelectOption title="Rashguards & Wetsuits" icon={SRC_WETSUIT} />
                                        <SelectOption title="Accessories" icon={SRC_LEASH} />
                                        <SelectOption title="Other" icon={SRC_ADD} />
                                       
                                    </>
                                }

                            />
                            <InputField label="Price" id="price" />
                            <TextField label="About this product" id="about" />
                        </form>



                    </div>
                    
                </div>
            </motion.div >

            <AnimatePresence>
                {isTag && <SearchPeople onClose={handleCloseTag} />}
            </AnimatePresence>

        </>

    )
}

