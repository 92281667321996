
import { Fragment, useState } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, stagger, AnimatePresence, isVisible } from "framer-motion"

import SRC_MORE from '../assets/img/more.svg';
import SRC_STAR from '../assets/img/star.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_DISTANCE from '../assets/img/distance.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_SHORTS from '../assets/img/shorts.svg';
import SRC_FAVORITE from '../assets/img/favorite.svg';
import SRC_SESSIONS from '../assets/img/sessions.svg';
import SRC_SEND from '../assets/img/send.svg';

import SRC_LINK from '../assets/img/link.svg';
import SRC_INSTA from '../assets/img/instagram.svg';

import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_BOARDONE from '../assets/img/board-1.png';
import SRC_BOARDTWO from '../assets/img/board-2.png';
import SRC_BOARDTHREE from '../assets/img/board-3.png';
import SRC_COCO from '../assets/img/coco.jpeg';




import SRC_CHEV from '../assets/img/chevron-right.svg';

import { HeaderProfile } from './Header'

import { SocialPost } from './Social'
import { ProductCard } from './ui/ProductCard'




export function SurferProfile() {

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <HeaderProfile />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full relative before:pt-[60%] before:block before:w-full overflow-hidden'>
                        <img className='w-full h-full absolute object-cover top-0 left-0' src="https://images.unsplash.com/photo-1482531007909-192ac913980a?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    </div>
                    <div className='w-full flex flex-col items-center mb-3 text-sm px-global pb-8 -mt-16 relative z-20'>
                        <img className='size-32 rounded-full object-cover border-4 border-white dark:border-zinc-800' src={SRC_COCO} />
                        <div className='flex flex-col items-center'>
                            <div className='text-xl font-bold'>Coco Ho</div>
                            <div className='text-base'>Siargao Island, Philippines</div>
                            <div className='text-sky-500 dark:text-sky-500 text-sm flex gap-x-2 mt-2 items-center'>
                                1,948 Followers
                                <div className='size-1 rounded-full bg-slate-200' />
                                934 Following
                            </div>
                        </div>
                    </div>
                    <AnimatedTabs />



                </div>


            </motion.div>

        </>
    );
}



const tabs = [
    {
        name: 'tab1',
        label: 'Overview',
        render: () => {
            return <Overview />
        }
    },
    {
        name: 'tab2',
        label: 'Sessions',
        render: () => {
            return <Sessions />
        }
    },
    {
        name: 'tab3',
        label: 'Services',
        render: () => {
            return <Services />
        }
    },
    {
        name: 'tab4',
        label: 'Shop',
        render: () => {
            return <div className='w-full p-global grid grid-cols-2 gap-global'>
                <ProductCardGrid imgId="imgOner" name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img={SRC_BOARDONE} />
                <ProductCardGrid imgId="imgTwo" name="Mashup" price="$500.00 USD" img={SRC_BOARDTWO} />
                <ProductCardGrid imgId="imgThree" name="Volcanic Too Fish" price="$500.00 USD" img={SRC_BOARDTHREE} />
                <ProductCardGrid imgId="imgFour" name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
                <ProductCardGrid imgId="imgFive" name="The Sweet Potato" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/seaside-324395_grande.jpg?v=1645727960" />
            </div>
        }
    },
    {
        name: 'tab5',
        label: 'Media',
        render: () => {
            return <ImageGrid />
        }
    },
    {
        name: 'tab6',
        label: 'Reviews',
        render: () => {
            return <div className='w-full p-global'>
                <div className='flex items-center justify-between mb-global'>
                    Surfed with Coco?
                    <Link className="bg-sky-500 text-white text-sm py-3 px-3 rounded-lg" to="/add-review">
                        Add your review
                    </Link>
                </div>
                <div className='flex mb-global w-full flex-shrink-0 items-start text-sm  relative bg-slate-100 dark:bg-zinc-700 p-6 rounded-xl'>
                    <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    <div>
                        <div className='flex items-center gap-x-2 mt-0'>
                            <div className='font-semibold'>Coco Ho</div>
                            <div className='flex gap-x-px dark:invert ml-auto'>
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                            </div>
                        </div>
                        <div className='text-sm mt-1 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</div>
                    </div>
                </div>
                <div className='flex w-full flex-shrink-0 items-start text-sm  relative bg-slate-100 dark:bg-zinc-700 p-6 rounded-xl'>
                    <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    <div>
                        <div className='flex items-center gap-x-2 mt-0'>
                            <div className='font-semibold'>Coco Ho</div>
                            <div className='flex gap-x-px dark:invert ml-auto'>
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                                <img className='size-4' src={SRC_STAR} />
                            </div>
                        </div>
                        <div className='text-sm mt-1 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</div>
                    </div>
                </div>
            </div>
        }
    }
];


function AnimatedTabs() {
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleClick = (e, tab) => {
        e.preventDefault()

        setActiveTab(tab)
    }

    const isSelected = tab => activeTab.name === tab.name

    return (
        <>
            <div className="w-full flex sticky top-0 bg-white dark:bg-zinc-800 z-50 gap-x-3 overflow-auto no-scrollbar border-b border-slate-200 dark:border-zinc-700">
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        className=""
                    >
                        <a className='px-3 py-3 block' href="#" onClick={e => handleClick(e, tab)}>
                            {tab.label}
                        </a>

                        {isSelected(tab) && (
                            <motion.div layoutId="indicator" className="h-1 bg-sky-500 rounded-full" />
                        )}
                    </div>
                ))}
            </div>

            <div className="w-full">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab.name || "empty"}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        {activeTab && activeTab?.render()}
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    )
}



function Overview() {
    return (
        <>
            <div className='w-full block px-global py-10 border-b-8 border-slate-200 dark:border-zinc-900'>
                <h2 className='text-global'>About me</h2>
                <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <div className='w-full mt-4 text-sky-500'>
                    <div className='flex items-center gap-x-2 my-2 text-sm'>
                        <img className='size-4 dark:invert' src={SRC_INSTA} />
                        thomino6711
                    </div>
                    <div className='flex items-center gap-x-2 my-2 text-sm'>
                        <img className='size-4 dark:invert' src={SRC_LINK} />
                        thomino.eu
                    </div>
                </div>
            </div>



            <div className='w-full block py-6  border-b-8 border-slate-200 dark:border-zinc-900'>
                <div className='w-full px-global flex items-center justify-between mb-2'>
                    <h2 className='text-global'>Hire me</h2>
                    <div className='text-sky-500 font-semibold'>View all</div>
                </div>
                <div className="w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                    <motion.div className='w-[93%] flex-shrink-0 p-6 my-3 rounded-xl flex items-center bg-slate-100 dark:bg-zinc-700'>
                        <div className='w-full'>
                            <h2 className='text-lg font-semibold'>Advanced surf guiding</h2>
                            <p className='text-sm text-slate-500 dark:text-zinc-400'>
                                Focused for itermediate surfers who want to take their surfing to next level
                            </p>
                            <div className='my-3 text-sm'>$50.00 USD</div>
                            <div className='w-full flex items-center gap-x-2'>
                                <Link className='px-4 py-2 bg-sky-500 text-white text-sm rounded-lg'>Book now</Link>
                                <Link to="/service-detail" className='px-4 py-2 bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>More info</Link>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div className='w-[93%] flex-shrink-0 p-6 my-3 rounded-xl flex items-center relative bg-slate-100 dark:bg-zinc-700'>
                        <div className='w-full'>
                            <h2 className='text-lg font-semibold'>Surf training for beginners</h2>
                            <p className='text-sm text-slate-500 dark:text-zinc-400'>
                                Focused for itermediate surfers who want to take their surfing to next level
                            </p>
                            <div className=' text-sm mr-auto my-3'>$50.00 USD</div>
                            <div className='w-full flex items-center gap-x-2'>
                                <Link className='px-4 flex py-2 bg-sky-500 text-white text-sm rounded-lg'>Book now</Link>
                                <Link to="/service-detail" className='px-4 block py-2 bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>More info</Link>
                            </div>
                        </div>

                    </motion.div>
                </div>

            </div>

            <div className='w-full block py-6  border-b-8 border-slate-200 dark:border-zinc-900'>
                <div className='w-full px-global flex items-center justify-between mb-2'>
                    <h2 className='text-global'>For Sale & Rent</h2>
                    <div className='text-sky-500 font-semibold'>View all</div>
                </div>
                <div className="w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                    <ProductCard name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img={SRC_BOARDONE} />
                    <ProductCard name="Mashup" price="$500.00 USD" img={SRC_BOARDTWO} />
                    <ProductCard name="Volcanic Too Fish" price="$500.00 USD" img={SRC_BOARDTHREE} />
                    <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
                </div>
            </div>


            <div className='w-full'>
                <div className='flex items-center justify-between mt-6 mb-2 p-global'>
                    <h2 className='text-global'>24 Reviews</h2>
                    <div className='flex items-center gap-x-1 justify-end ml-auto text-sky-500 font-semibold'>
                        4.9
                        <img className='size-4 -translate-y-px invert-blue' src={SRC_STAR} />
                    </div>
                </div>
                <div className='w-full pb-8 flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar'>
                    <div className='flex w-[93%] flex-shrink-0 items-start text-sm  relative bg-slate-100 dark:bg-zinc-700 p-6 rounded-xl'>
                        <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                        <div>
                            <div className='flex items-center gap-x-2 mt-0'>
                                <div className='font-semibold'>Coco Ho</div>
                                <div className='flex gap-x-px dark:invert ml-auto'>
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                </div>
                            </div>
                            <div className='text-sm mt-1 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</div>
                        </div>
                    </div>
                    <div className='flex w-[93%] flex-shrink-0 items-start text-sm  relative bg-slate-100 dark:bg-zinc-700 p-6 rounded-xl'>
                        <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                        <div>
                            <div className='flex items-center gap-x-2 mt-0'>
                                <div className='font-semibold'>Coco Ho</div>
                                <div className='flex gap-x-px dark:invert ml-auto'>
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                    <img className='size-4' src={SRC_STAR} />
                                </div>
                            </div>
                            <div className='text-sm mt-1 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='w-full flex items-center px-global pb-global'>
                <img className='size-12 rounded-full object-cover mr-2' src={SRC_TOMAS} />
                <input className='h-12 w-full bg-slate-100 dark:bg-zinc-700 rounded-xl border-none pl-4' placeholder='Leave review' />
                <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-12 flex-shrink-0 flex items-center justify-center rounded-lg'>
                    <img className='dark:invert' src={SRC_SEND} />
                </motion.button>
            </div>

        </>
    )
}

function Sessions() {
    return (
        <>
            <div className='w-full grid grid-cols-3 gap-x-3 no-scrollbar snap-mandatory p-global border-b-8 border-slate-200 dark:border-zinc-900'>

                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_WAVE} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        14k
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Waves surfed</div>

                </div>
                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_SHORTS} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        391h
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Water time</div>

                </div>
                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_SESSIONS} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        291
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Total sessions</div>

                </div>
            </div>
            <SocialPost name="Coco Ho" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_COCO} />
            <SocialPost name="Coco Ho" session="Messy Pesangan" photo="https://media.istockphoto.com/id/922584892/photo/skilled-surfers-finished-morning-surfing-session-in-sydney.jpg?s=612x612&w=0&k=20&c=3UomlVf2w4uA20HLwrpB7OX13I8NclV04dETQel1_Z0=" avatar={SRC_COCO} />
            <SocialPost name="Coco Ho" hasSlider="true" session="Barrels at Tuason" photo="https://cms.accuweather.com/wp-content/uploads/2018/06/surf-4.jpg" avatar={SRC_COCO} />
            <SocialPost name="Coco Ho" session="Time to shine" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_COCO} />
            <SocialPost name="Coco Ho" hasSlider="true" session="Trip to north!" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_COCO} />
            <SocialPost name="Coco Ho" session="Massive Stimpy's" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_COCO} />

        </>
    )
}

function Services() {
    return (
        <div className='p-global'>
            <Service />
            <Service />
            <Service />
            <Service />
            <Service />
        </div>
    )
}


function Service() {
    return (
        <motion.div className='w-full p-6 my-3 rounded-xl flex items-center relative bg-slate-100 dark:bg-zinc-700'>
            <div className='w-full'>
                <h2 className='text-lg font-semibold'>Surf training for beginners</h2>
                <p className='text-sm text-slate-500 dark:text-zinc-400'>
                    Focused for itermediate surfers who want to take their surfing to next level
                </p>
                <div className=' text-sm mr-auto my-3'>$50.00 USD</div>
                <div className='w-full flex items-center gap-x-2'>
                    <Link className='px-4 flex py-2 bg-sky-500 text-white text-sm rounded-lg'>Book now</Link>
                    <Link to="/service-detail" className='px-4 block py-2 bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>More info</Link>
                </div>
            </div>

        </motion.div>
    )
}

function ImageGrid() {
    return (
        <div className='grid grid-cols-3 gap-px'>
            <ExpandableImage img="https://images.unsplash.com/photo-1489633908075-1c914e8ee5ea?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3VyZmVyfGVufDB8fDB8fHww" />
            <ExpandableImage img="https://images.unsplash.com/photo-1502680390469-be75c86b636f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3VyZmVyfGVufDB8fDB8fHww" />
            <ExpandableImage img="https://plus.unsplash.com/premium_photo-1675721094347-08f1f4ccb2b2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8c3VyZmVyfGVufDB8fDB8fHww" />
            <ExpandableImage img="https://images.unsplash.com/photo-1559628234-d70bb7959a92?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3VyZmVyfGVufDB8fDB8fHww" />
            <ExpandableImage img="https://images.unsplash.com/photo-1551524358-f34c0214781d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1526485856375-9110812fbf35?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1505459668311-8dfac7952bf0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://plus.unsplash.com/premium_photo-1684470859652-05e43eb77763?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1610272117575-77f47761c414?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1513737567531-bc431c8e5e85?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1530870110042-98b2cb110834?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />
            <ExpandableImage img="https://images.unsplash.com/photo-1535182463927-440364075d9c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHN1cmZlcnxlbnwwfHwwfHx8MA%3D%3D" />

        </div>
    )
}


const ExpandableImage = (props) => {
    const { img } = props;
    const [isOn, setIsOn] = useState(false);
    const toggleSwitch = () => setIsOn(!isOn);

    return (
        <>
            <div className='w-full'>
                <motion.div layout className='expandable-image-wrapper w-full before:pt-[100%] before:block before:w-full' data-isOn={isOn} onClick={toggleSwitch}>
                    <div className='overlay w-[2000px] h-[2000px] fixed -top-[500px] -left-[500px] bg-black/30 z-40' />
                    <motion.img layout className='expandable-image absolute top-0 left-0 w-full h-full object-cover' src={img} />
                </motion.div>
            </div>
        </>
    )
}


const ProductCardGrid = (props) => {
    const { img, name, price, imgId } = props;

    return (

        <>
            <motion.div
                layout
                className='snap-center w-full col-span-1 rounded-xl flex-shrink-0 relative'>
                <Link to="/product-page" className='items-center flex flex-col justify-start w-full h-full flex-shrink-0 rounded-lg'>
                    <div className='w-full bg-slate-50 border border-slate-100 dark:bg-zinc-600 h-[240px] relative z-20 flex-shrink-0 before:w-full before:block rounded-xl overflow-hidden'>
                        <motion.img layoutId="image" className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                    </div>
                    <div className='w-full h-full flex flex-col justify-between mt-2'>
                        <motion.div layoutId="protuctname" className='card-title font-semibold text-sm mb-px'>{name}</motion.div>
                        <div className='flex w-full justify-between text-xxs mt-auto'>
                            {price}
                        </div>
                    </div>
                </Link>

            </motion.div>
        </>
    )
}

export default ProductCardGrid