
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../../assets/img/calendar.svg';
import SRC_CLOSE from '../../assets/img/close.svg';

import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_CHAT from '../../assets/img/messages.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_LOCATION from '../../assets/img/location.svg';

import SRC_ADD from '../../assets/img/add.svg';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function Location() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="" hasIcon="true" icon={SRC_CLOSE} link="/marketplace" />

                <motion.div exit={{x: -50, opacity:0}} transition={{duration:0.2}} className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='p-global'>
                        <h2 className='text-2xl font-bold text-center mb-8'>Where are you going?</h2>
                        <InputField label="Destination" id="time" hasIcon="true" icon={SRC_LOCATION} type="text" />
                        <div className='text-xs text-slate-600 uppercase mt-8'>Popular destinations</div>
                        <LocationItem title="Pipeline" location="Oahu, Hawaii" />
                        <LocationItem title="Siargao Island" location="Philippines" />
                        <LocationItem title="Tarifa" location="Spain" />
                        <LocationItem title="Canggu" location="Bali, Indonesia" />
                        <LocationItem title="Hossegor" location="France" />
                        <LocationItem title="Jefferys Bay" location="South Africa" />

                    </div>
                </motion.div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <Link to="/trip/duration" className='bg-sky-500 text-center text-sm px-6 py-3 rounded-md text-white w-full'>Next</Link>
                </div>
            </motion.div >


        </>

    )
}


export function LocationItem(props) {
    const { title, location } = props;
    return (
        <div className='flex w-full items-center py-3 border-b border-slate-200 relative dark:border-zinc-700'>
            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
            <div className='mr-auto'>
                <div className='mr-auto font-medium'>{title}</div>
                <div className='text-xs text-slate-400 dark:text-zinc-500'>{location}</div>
            </div>

            <Link to="/spot-detail" className='absolute w-full h-full left-0 right-0' />
        </div>
    )
}