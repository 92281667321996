import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';

const ThemeSwitcher = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <motion.button
      exit={{y:-20}}
      onClick={toggleDarkMode}
      className=""
    >
        <div className="font-semibold tracking-wider text-xl">{darkMode ? 'DARK' : 'SURF'}<span className='text-sky-500'>YX</span></div>
      
    </motion.button>
  );
};

export default ThemeSwitcher;