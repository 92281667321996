import React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Link } from "react-router-dom";



import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_LIKE from '../../assets/img/like.svg';
import SRC_LIKED from '../../assets/img/like-full.svg';

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export function Expandable(props) {
    const { title, closedtitle, opentitle, content, hasIcon, icon } = props;
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <>

            <div className="w-full border-b border-slate-200">
                <div className="w-full flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-start items-center py-5 flex-shrink-0">
                    {isOpen
                        ?
                        <div className="w-full flex items-center justify-between" onClick={() => setIsOpen(false)}>
                            {hasIcon && (
                                <img className="mr-3" src={icon} />
                            )}
                            <div>
                                <div className="font-medium">{title}</div>
                                <div className="text-sm text-slate-400">{opentitle}</div>
                            </div>
                            <motion.img animate={{ rotate: '90deg' }} className="dark:invert ml-auto" src={SRC_CHEV} />
                        </div>
                        :
                        <div className="w-full flex items-center justify-between" onClick={() => setIsOpen(true)}>
                            {hasIcon && (
                                <img className="mr-3" src={icon} />
                            )}
                            <div>
                                <div className="font-medium">{title}</div>
                                <div className="text-sm text-slate-400">{closedtitle}</div>
                            </div>
                            <motion.img className="dark:invert opacity-40 ml-auto" src={SRC_CHEV} />
                        </div>
                    }
                </div>
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ height: 0, overflow: 'hidden', opacity:0 }}
                            animate={{ height: 'auto', overflow: 'visible', opacity:1 }}
                            exit={{ height: 0 , overflow: 'hidden', opacity:0}}
                            className="w-full">{content}</motion.div>
                    )}
                </AnimatePresence>
            </div>





        </>
    );
}

