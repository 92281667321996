
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";

import SRC_CLOSE from '../../assets/img/close.svg';

import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_STAR from '../../assets/img/star.svg';
import SRC_LOCATION from '../../assets/img/location.svg';

import { HeaderCheckout } from '../Header';
import Favorite from "../ui/Favorite";
import { InputField, RangeSlider, TextField, DateField, Checkbox, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import { TripSlider } from './TripSlider';

import SearchPeople from '../SearchPeople';



export function Results() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your trip" hasIcon="true" icon={SRC_CLOSE} link="/marketplace" />

                <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 100, x: 0 }} exit={{ x: -50, opacity: 0 }} transition={{ duration: 0.2 }} className='w-full h-full overflow-auto no-scrollbar pb-24'>
                    <div className=''>
                        <div className='flex p-global flex-col items-center text-center'>
                            <h2 className='text-3xl font-bold text-center mb-1'>Siargao Island</h2>
                            <div className='text-sm text-slate-500'>24-29 April, 2024</div>
                        </div>

                        <div className='flex items-center py-6 pb-1 mb-2 px-global'>
                            <motion.h2 className='text-global'>Instructors</motion.h2>
                            <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>
                        </div>
                        <motion.div
                            className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                            <Instructor hasRating="true" name="Coco" img={SRC_COCO} />
                            <Instructor hasRating="true" name="Amanda" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
                            <Instructor hasRating="true" name="Jordy" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/143918363/photo/standing-tall.jpg?s=612x612&w=0&k=20&c=CmYEL5PCpEPRU8w1G3ppYPgBP9sUqApsC7Jtdenj3ek=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
                            <Instructor hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
                        </motion.div>
                        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />
                        <div className='flex items-center pb-1 mb-2 px-global'>
                            <motion.h2 className='text-global'>Local surfers</motion.h2>
                            <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>
                        </div>
                        <motion.div
                            className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                            <Instructor name="Tomas" img="https://media.istockphoto.com/id/143918363/photo/standing-tall.jpg?s=612x612&w=0&k=20&c=CmYEL5PCpEPRU8w1G3ppYPgBP9sUqApsC7Jtdenj3ek=" />
                            <Instructor name="Jessica" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
                            <Instructor name="Tracy" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
                            <Instructor name="Tomas" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
                            <Instructor name="Tomas" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />


                            <Instructor name="Coco" img={SRC_COCO} />
                            <Instructor name="Amanda" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
                            <Instructor name="Jordy" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
                            <Instructor name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
                            <Instructor name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />

                        </motion.div>
                        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />

                        <div className='flex items-center pb-1 mb-2 px-global'>
                            <motion.h2 className='text-global'>Boards for rent</motion.h2>
                            <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>
                        </div>
                        <motion.div
                            className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                            <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
                            <ProductCard name="The Sweet Potato" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/seaside-324395_grande.jpg?v=1645727960" />
                            <ProductCard name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_deck_grande.png?v=1699917947" />
                            <ProductCard name="Mashup" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367" />
                            <ProductCard name="The Sweet Potato" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/seaside-324395_grande.jpg?v=1645727960" />
                            <ProductCard name="Volcanic Too Fish" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/FRK_Plus_Deck_grande.png?v=1677109018" />
                            <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />

                        </motion.div>

                        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />

                        <div className='flex items-center pb-1 mb-2 px-global'>
                            <motion.h2 className='text-global'>Surf Spots</motion.h2>
                            <Link to="/surf-spots" className='text-sky-500 ml-auto'>View All</Link>
                        </div>
                        <div className='px-global'>
                            <Spot title="Pesangan" />
                            <Spot title="Cloud 9" />
                            <Spot title="Tuason" />
                            <Spot title="Salvation" />
                            <Spot title="Rock Island" />
                            <Spot title="Stimpy's" />
                        </div>


                    </div>
                </motion.div>
                <TripSlider />
            </motion.div >


        </>

    )
}

const Spot = (props) => {
    const { title } = props;
    return (
        <div className='flex w-full items-center py-3 border-b border-slate-200 dark:border-zinc-700 relative'>
            <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
            <div className='mr-auto font-medium'>{title}</div>
            <Favorite />
            <Link to="/spot-detail" className='absolute w-full h-full' />
        </div>
    )
}


const Instructor = (props) => {
    const { img, name, hasRating, price } = props;
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (

        <>
            <motion.div
                whileHover={{ scale: 0.9 }}
                layout
                className='w-2/5 bg-white overflow-hidden border border-slate-200 dark:bg-zinc-900 rounded-xl flex-shrink-0 relative'>

                <Link to="/surfer-profile" className='w-full relative z-50 before:w-full before:block before:pt-[130%] rounded-tl-xl rounded-tr-xl overflow-hidden'>
                    <img className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                    <div className='gradient-overlay size-full px-3 py-2 z-20 flex items-end text-white absolute top-0 left-0 bg-gradient-to-t from-black/40 to-transparent'>
                        <div className='w-full'>
                            <h3 className='card-title font-semibold text-sm mb-px'>{name}</h3>
                            <div className='flex w-full justify-between text-xxs opacity-90'>
                                <div>0.2km</div>
                                {hasRating && (
                                    <div className='flex items-center gap-x-1'>
                                        4.9
                                        <img className='invert -translate-y-px' src={SRC_STAR} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
                <div className='p-0'>

                    <motion.button onClick={handleClick}
                        whileTap={{ scale: 1.1 }} className={`px-4 py-4 w-full   text-sm relative z-20 ${clicked ? 'bg-sky-500 text-white' : 'bg-white text-zinc-800 dark:bg-zinc-800 dark:text-white'}`} >
                        {clicked ? 'Saved' : '+ Save'}
                    </motion.button>
                </div>
            </motion.div>
        </>
    )
}


export function ProductCard(props) {
    const { img, name, price } = props;
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (

        <>
            <motion.div
                layout
                className='snap-center w-2/5 col-span-1 overflow-hidden flex-shrink-0 relative border border-slate-200 dark:bg-zinc-900 rounded-xl '>
                <div className='items-center flex flex-col justify-start w-full h-full flex-shrink-0 rounded-lg'>
                    <Link to="/product-page" className='w-full h-[220px] relative z-20 flex-shrink-0 before:w-full before:block rounded-xl overflow-hidden'>
                        <img className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                    </Link>
                    <div className='w-full h-full flex flex-col justify-between mt-2 p-3'>
                        <h3 className='card-title font-semibold text-sm mb-px'>{name}</h3>
                        <div className='flex w-full justify-between text-xxs mt-auto'>
                            {price}
                        </div>
                    </div>
                    <motion.button onClick={handleClick}
                        whileTap={{ scale: 1.1 }} className={`px-4 py-4 w-full border-t border-slate-200 dark:bg-zinc-900  text-sm relative z-20 ${clicked ? 'bg-sky-500 text-white' : 'bg-white text-zinc-800 dark:bg-zinc-800 dark:text-white'}`} >
                        {clicked ? 'Saved' : '+ Save'}
                    </motion.button>
                </div>

            </motion.div>
        </>
    )
}
