
import { Fragment, useState } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, stagger, AnimatePresence, isVisible } from "framer-motion"

import SRC_MORE from '../assets/img/more.svg';
import SRC_STAR from '../assets/img/star.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_DISTANCE from '../assets/img/distance.svg';
import SRC_DURATION from '../assets/img/duration.svg';
import SRC_CONDITIONS from '../assets/img/conditions.svg';
import SRC_SIZE from '../assets/img/size.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_SEND from '../assets/img/send.svg';

import SRC_TOMAS from '../assets/img/tomas.jpg';

import { HeaderPost } from './Header'


export function PostDetail() {

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type:"spring", damping:60, stiffness:700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <HeaderPost />
                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full flex items-center mb-3 text-sm p-global pb-0 pt-0'>
                        <Link to="../surfer-profile">
                            <img className='size-10 rounded-full object-cover mr-3' src={SRC_TOMAS} />
                        </Link>
                        <div>
                            <strong>Tomas Zeman</strong>
                            <div className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                                Cloud 9
                                <div className='size-1 rounded-full bg-slate-200' />
                                4 Hours ago
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar'>

                        <div className='w-full flex-shrink-0 snap-center relative before:pt-[66.666%] before:block before:w-full rounded-xl overflow-hidden'>
                            <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfertoday.com/images/stories/teahupoo-meaning.jpg' />
                        </div>
                        <div className='w-full flex-shrink-0 snap-center relative before:pt-[66.666%] before:block before:w-full rounded-xl overflow-hidden'>
                            <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfholidays.com/assets/images/blog/2015-08-17-teahupoo-aerial.jpg' />
                        </div>
                    </div>
                    <div className='w-full block p-global pt-3 pb-0 border-b-8 border-slate-200 dark:border-zinc-900'>
                        <div className='w-full text-lg font-bold'>Glassy Pesangan</div>
                        <div className='text-sm text-slate-800 dark:text-zinc-400 mb-4'>Glassy epic morning. As good as it gets.</div>



                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_CONDITIONS} />
                            <div className=' '>Conditions</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                4.9
                                <img className='size-4 -translate-y-px dark:invert' src={SRC_STAR} />
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_WAVE} />
                            <div className=' '>Wave Count</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                14
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_DURATION} />
                            <div className=' '>Duration</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                2:31
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_DISTANCE} />
                            <div className=' '>Distance</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                6.4km
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_SIZE} />
                            <div className=' '>Wave size</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                4 - 6ft
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_BOARD} />
                            <div className=' '>Board</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                5'7 Seaside
                            </div>
                        </div>
                        <div className='w-full flex py-6 items-center' onClick={handleClick}>
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-10' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                            <span className='text-slate-500 dark:text-zinc-400 text-sm ml-4'>With 3 others</span>
                        </div>

                    </div>

                    <div className='w-full p-global'>
                        <div className="text-base font-semibold flex gap-x-2 items-center">
                            1 Comment
                            <div className='size-1 rounded-full bg-slate-200' />
                            2 Likes
                        </div>
                        <div className='flex items-start text-sm py-6 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='size-12 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                            <div>
                                <div className='flex items-center gap-x-2 mt-2'>
                                    <div className='font-semibold'>Coco Ho</div>
                                    <div className='size-1 rounded-full bg-slate-200 dark:bg-zinc-600' />
                                    <div className='text-slate-500 dark:text-zinc-400'>5 hours ago</div>
                                </div>
                                <div className='text-sm mt-1 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex items-center px-global pb-global'>
                        <img className='size-12 rounded-full object-cover mr-2' src={SRC_TOMAS} />
                        <input className='h-12 w-full bg-slate-100 dark:bg-zinc-700 rounded-xl border-none pl-4' placeholder='Leave comment' />
                        <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-12 flex-shrink-0 flex items-center justify-center rounded-lg'>
                            <img className='dark:invert' src={SRC_SEND} />
                        </motion.button>
                    </div>

                </div>


            </motion.div>
            <AnimatePresence>
                {isShown &&
                    <>
                        <div className=' fixed w-full h-full top-0 left-0 z-[99999]' onClick={handleClick} />
                        <PostDrawer />
                    </>
                }
            </AnimatePresence>
        </>
    );
}

function PostDrawer() {



    return (

        <div className='fixed bottom-0 left-0 w-full flex items-end justify-start h-full z-[9999]'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 100 }}
                transition={{ duration: 0.1 }}
                exit={{ opacity: 0 }}
                className='bg-black/30 dark:bg-black/70 fixed w-full h-full top-0 left-0 z-50' />
            <motion.div
                key="modal"
                initial={{ y: "90%" }}
                animate={{ y: 0 }}
                transition={{ type: "spring", damping: 40, stiffness: 700 }}
                exit={{ y: "90%" }}
                className='w-full px-global pb-6 dark:text-white rounded-tl-xl rounded-tr-xl bg-white dark:bg-zinc-900 relative z-[9999]'>

                <div className='w-full flex items-center justify-center py-4 before:w-14 before:h-2 before:rounded-full dark:before:bg-zinc-700 before:bg-slate-200 before:block' />
                <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                    Tomas Zeman
                </div>
                <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                    John Doe
                </div>
                <div className='flex items-center gap-x-2 py-3'>
                    <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                    Jack Johnson
                </div>
            </motion.div>
        </div>

    )
}
