import React, { useEffect, useState } from "react";
import { useLocation, Routes, Route, ScrollRestoration, useNavigate, Navigate, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { Home } from './components/Home'
import { Social } from './components/Social'
import { Spots } from './components/Spots'
import { Navbar } from './components/Navbar'
import { Header, HeaderPost, HeaderSocial, HeaderSearch, HeaderProfile, HeaderUserProfile } from './components/Header'
import { SurferProfile } from "./components/SurferProfile";
import { PostDetail } from "./components/PostDetail";
import { SpotDetail } from "./components/SpotDetail";
import { StepOne } from "./components/Checkout/StepOne";
import { Product } from "./components/Product";
import { Profile } from "./components/Profile";
import { Instructors } from "./components/Instructors";
import { Chat } from "./components/Chat";
import { SurfShops } from "./components/SurfShops";
import { Photographers } from "./components/Photographers";
import { EditProfile } from "./components/EditProfile";
import { Settings } from "./components/Settings";
import { AddPost } from "./components/AddPost";

import { Login } from "./components/Login";
import { Signup } from "./components/Signup";
import { Recovery } from "./components/Recovery";
import { Logout } from "./components/Logout";
import { Onboarding } from "./components/Onboarding";

import { Sessions } from "./components/Profile/Sessions";
import { Saved } from "./components/Profile/Saved";
import { Details } from "./components/Profile/Details";
import { Boards } from "./components/Profile/Boards";
import { Trips } from "./components/Profile/Trips";
import { AddProduct } from "./components/Profile/AddProduct";
import { Products } from "./components/Profile/Products";
import { Orders } from "./components/Profile/Orders";
import { OrderDetail } from "./components/Profile/OrderDetail";
import { Services } from "./components/Profile/Services";
import { AddService } from "./components/Profile/AddService";
import { Bookings } from "./components/Profile/Bookings";
import { BookingDetail } from "./components/Profile/BookingDetail";
import { EditBooking } from "./components/Profile/EditBooking";

import { Events } from "./components/Profile/Events";
import { EventDetail } from "./components/Profile/EventDetail";
import { EditEvent } from "./components/Profile/EditEvent";
import { History } from "./components/Profile/History";
import { Location } from "./components/TripPlanner/Location";
import { Duration } from "./components/TripPlanner/Duration";
import { LookingFor } from "./components/TripPlanner/LookingFor";
import { Results } from "./components/TripPlanner/Results";
import { YourTrip } from "./components/TripPlanner/YourTrip";
import { Service } from "./components/Service";
import { AddReview } from "./components/AddReview";
import { Notifications } from "./components/Profile/Notifications";
import { postApiPrivate } from "./api";

import Landing from "./components/Landing";

import swal from 'sweetalert';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { HelmetProvider } from 'react-helmet-async';

import LANG_IT from './translations/it';
import LANG_EN from './translations/en';
import LANG_FR from './translations/fr';
import LANG_ES from './translations/es';
import { ApertureOutline } from "react-ionicons";
import Terms from "./components/Terms";


const CLIENT_ID = '534041740722-el9c12qp89n0tihead1iiqcb7ptiraom.apps.googleusercontent.com';

const isProd = true;

if (isProd) {
  console.log = () => { };
}

const helmetContext = {};



const AnimatedMaster = (props) => {
  return (
    <HelmetProvider context={helmetContext}>

      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <AnimatedRoutes {...props} />
      </GoogleOAuthProvider>

    </HelmetProvider>
  )
}

const AnimatedRoutes = (props) => {

  const { history } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isNativeApp, setNativeApp] = useState(false);
  const [USER, setUser] = useState(null);
  const [LANGUAGE, setLanguage] = useState('en');
  const [CURRENCY, setCurrency] = useState('usd');

  const [MC, setMC] = useState(0);
  const [NC, setNC] = useState(0);

  const [isModalUser, setModalUser] = useState(false);

  const [isModalSearch, setModalSearch] = useState(false);
  const [isModalCart, setModalCart] = useState(false);
  const [isModalLocalisation, setModalLocalisation] = useState(false);
  const [isModalGuide, setModalGuide] = useState(false);
  const [isModalCity, setModalCity] = useState(false);
  const [isModalPosts, setModalPosts] = useState(false);
  const [isModalAvatar, setModalAvatar] = useState(false);
  const [isModalWatch, setModalWatch] = useState(false);

  useEffect(() => {
    onStartUser();
  }, []);

  useEffect(() => {
    localStorage.setItem('language', LANGUAGE);
  }, [LANGUAGE]);

  useEffect(() => {

    const isApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
    setNativeApp(isApp)

  }, [window.isNativeApp])


  const onStartUser = async (isReset) => {

    if (isReset) {
      onUpdateUser({});
      return
    }

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (JWT) {

      onFetchUser(JWT);
    }
    else {
      setLoading(false);
    }

  }

  const onFetchUser = async (jwt, payload) => {

    if (payload) {
      onUpdateUser(payload);
      return
    }

    if (!jwt) {
      console.log("ERROR NO JWT FOUND ---> On Fetch User");

      alert('Check your internet connection and try again. 3');

      return
    }

    setLoading(true);

    try {

      const DATA_RESPONSE = await postApiPrivate('/users/profile', {}, jwt);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API USER INFO", DATA_RESPONSE.error);

        swal('Warning', 'You have been disconnected', 'warning')

        if (isProd) {
          localStorage.removeItem('jwt_token');
        }
        setLoading(false);
        onStartUser();

        return

      }

      const DATA_USER = DATA_RESPONSE || {};
      const DATA_MESSAGES = DATA_USER.mc || 0;
      const DATA_NOTIFICATIONS = DATA_USER.nc || 0;

      setMC(DATA_MESSAGES);
      setNC(DATA_NOTIFICATIONS);

      console.log("CHECKING USER PROFILE === ", DATA_USER);

      onUpdateUser(DATA_USER);
      setLoading(false);
      //alert('ALERT ===> '+JSON.stringify(DATA_USER))

    }
    catch (e) {

      console.log("FETCH USER ERROR ===> ", e);
      setLoading(false);
      // if(match?.url){
      //   window.location.replace('/' + LANGUAGE + '/login?next='+match?.url)
      // }
      // else{
      //   window.location.replace('/' + LANGUAGE + '/login')
      // }



      //alert('Account Disconnected ===> '+JSON.stringify(e, null, 2));
      return //alert('Check your internet connection and try again. 5');
    }

  }

  const onUpdateUser = (user) => {
    setUser(user);
  }

  const onUpdateLanguage = (language) => {
    setLanguage(language)
  }

  const onFetchCounts = (type, value) => {
    if (type === 'mc') {
      setMC(value);
    }
    else if (type === 'nc') {
      setNC(value);
    }
  }

  //TRACKING
  const onFetchAnalytics = ({ location }) => {

    console.log("ANALYTICS ===> ");

    // ReactGA.addTrackers(
    //   [
    //     {
    //       trackingId: 'UA-67700835-1',
    //       gaOptions: {
    //         name: 'tracker2'
    //       }
    //     }
    //   ],
    //   { debug: true, alwaysSendToDefaultTracker: false }
    // );

    // ReactGA.set({ page: location.pathname }, ['tracker2']);
    // ReactGA.pageview(location.pathname, ['tracker2']);

  }

  const onFacebookTracking = () => {

  }

  const onGoogleTracking = (event) => {

    // ReactGA.event({
    //   category: event,
    //   action: event
    // });

  }

  const onKlaviyoTracking = (event, item) => {

    var _learnq = window._learnq || [];

    if (!USER.email) {
      return
      alert("EVENT ==> ITEM ===> " + JSON.stringify({ event, item }, null, 2));
    }

    // alert("EVENT ==> ITEM ===> EMAIL ===> "+USER.email+'/n'+JSON.stringify({event, item}, null, 2));

    _learnq.push(['identify', {
      '$email': USER.email
    }]);


    if (event) {

      _learnq.push(['track', event, item]);

    }

  }

  let TRANSLATE = {};

  if (LANGUAGE === 'it') {
    TRANSLATE = LANG_IT;
  }
  else if (LANGUAGE === 'en') {
    TRANSLATE = LANG_EN;
  }
  else if (LANGUAGE === 'fr') {
    TRANSLATE = LANG_FR;
  }
  else if (LANGUAGE === 'es') {
    TRANSLATE = LANG_ES;
  }
  else {
    TRANSLATE = LANG_EN
  }

  const sharedProps = {
    ...props,
    USER,
    LANGUAGE,
    CURRENCY,
    TRANSLATE,
    MC,
    NC,

    onFetchUser,
    onStartUser,
    onUpdateUser,
    onFetchCounts,

    onFetchAnalytics,
    onGoogleTracking,
    onFacebookTracking,
    onKlaviyoTracking,
    onUpdateLanguage,

    isModalCart,
    isModalGuide,
    isModalLocalisation,
    isModalSearch,
    isModalUser,
    isModalCity,
    isModalPosts,
    isModalAvatar,
    isModalWatch,

    setModalCart,
    setModalGuide,
    setModalLocalisation,
    setModalSearch,
    setModalUser,
    setModalCity,
    setModalPosts,
    setModalAvatar,
    setModalWatch,

    isNativeApp


  }


  //PRIVATE URLS
  if (USER && USER.id) {
    return (
      <>
        <div className="w-full mx-auto h-full flex flex-col bg-white justify-between ">

          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HeaderSocial />} />

            <Route path="/marketplace" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/trip/location" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/trip/duration" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/trip/your-trip" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/trip/looking-for" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/trip/results" element={<Header title="Discover" hasLocation="true" />} />
            <Route path="/post-detail" element={<HeaderSocial />} />
            <Route path="/notifications" element={<HeaderSocial />} />
            <Route path="/new-session" element={<HeaderSocial />} />
            <Route path="/surfer-profile" element={<Header title="Discover" />} />
            <Route path="/edit-profile" element={<Header title="Edit Profile" />} />
            <Route path="/spots" element={<HeaderSearch title="Spots" />} />
            <Route path="/chat" element={<HeaderSearch title="Chat" />} />
            <Route path="/spot-detail" element={<HeaderSearch />} />
            <Route path="/product-page" element={<HeaderProfile />} />


            <Route path="/profile" element={<HeaderUserProfile />} />
            <Route path="/profile/sessions" element={<HeaderUserProfile />} />
            <Route path="/profile/saved" element={<HeaderUserProfile />} />
            <Route path="/profile/details" element={<HeaderUserProfile />} />
            <Route path="/profile/boards" element={<HeaderUserProfile />} />
            <Route path="/profile/trips" element={<HeaderUserProfile />} />
            <Route path="/profile/add-product" element={<HeaderUserProfile />} />
            <Route path="/profile/products" element={<HeaderUserProfile />} />
            <Route path="/profile/orders" element={<HeaderUserProfile />} />
            <Route path="/profile/order-detail" element={<HeaderUserProfile />} />
            <Route path="/profile/services" element={<HeaderUserProfile />} />
            <Route path="/profile/add-service" element={<HeaderUserProfile />} />
            <Route path="/profile/bookings" element={<HeaderUserProfile />} />
            <Route path="/profile/booking-detail" element={<HeaderUserProfile />} />
            <Route path="/profile/edit-booking" element={<HeaderUserProfile />} />
            <Route path="/profile/events" element={<HeaderUserProfile />} />
            <Route path="/profile/event-detail" element={<HeaderUserProfile />} />
            <Route path="/profile/edit-event" element={<HeaderUserProfile />} />

            <Route path="/settings" element={<HeaderUserProfile />} />

          </Routes>

          <div className="w-full h-full flex relative dark:bg-zinc-800">
            <AnimatePresence mode="sync">
              <Routes location={location} key={location.pathname}>

                <Route path="/" element={<Social location={location} {...sharedProps} />} />
                <Route path="/landing" element={<Landing location={location} {...sharedProps}  />} />
                <Route path="/landing/terms" element={<Terms />} location={location} {...sharedProps} />

                <Route path="/logout" element={<Logout location={location} {...sharedProps} />} />
                <Route path="/surfer-profile" element={<SurferProfile location={location} {...sharedProps} />} />
                <Route path="/post-detail" element={<PostDetail location={location} {...sharedProps} />} />
                <Route path="/marketplace" element={<Home location={location} {...sharedProps} />} />
                <Route path="/spots" element={<Spots location={location} {...sharedProps} />} />
                <Route path="/chat" element={<Chat location={location} {...sharedProps} />} />
                <Route path="/spot-detail" element={<SpotDetail location={location} {...sharedProps} />} />
                <Route path="/product-page" element={<Product location={location} {...sharedProps} />} />
                <Route path="/profile" element={<Profile location={location} {...sharedProps} />} />
                <Route path="/checkout/step-one" element={<StepOne location={location} {...sharedProps} />} />
                <Route path="/instructors" element={<Instructors location={location} {...sharedProps} />} />
                <Route path="/surf-shops" element={<SurfShops location={location} {...sharedProps} />} />
                <Route path="/photographers" element={<Photographers location={location} {...sharedProps} />} />
                <Route path="/edit-profile" element={<EditProfile location={location} {...sharedProps} />} />
                <Route path="/settings" element={<Settings location={location} {...sharedProps} />} />
                <Route path="/new-session" element={<AddPost location={location} {...sharedProps} />} />

                {/* <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/recovery" element={<Recovery />} /> */}

                <Route path="/onboarding" element={<Onboarding location={location} {...sharedProps} />} />
                <Route path="/profile/sessions" element={<Sessions location={location} {...sharedProps} />} />
                <Route path="/profile/saved" element={<Saved location={location} {...sharedProps} />} />
                <Route path="/profile/details" element={<Details location={location} {...sharedProps} />} />
                <Route path="/profile/boards" element={<Boards location={location} {...sharedProps} />} />
                <Route path="/profile/trips" element={<Trips location={location} {...sharedProps} />} />
                <Route path="/profile/add-product" element={<AddProduct location={location} {...sharedProps} />} />
                <Route path="/profile/products" element={<Products location={location} {...sharedProps} />} />
                <Route path="/profile/orders" element={<Orders location={location} {...sharedProps} />} />
                <Route path="/profile/order-detail" element={<OrderDetail location={location} {...sharedProps} />} />
                <Route path="/profile/services" element={<Services location={location} {...sharedProps} />} />
                <Route path="/profile/add-service" element={<AddService location={location} {...sharedProps} />} />
                <Route path="/profile/events" element={<Events location={location} {...sharedProps} />} />
                <Route path="/profile/bookings" element={<Bookings location={location} {...sharedProps} />} />
                <Route path="/profile/booking-detail" element={<BookingDetail location={location} {...sharedProps} />} />
                <Route path="/profile/edit-booking" element={<EditBooking location={location} {...sharedProps} />} />
                <Route path="/profile/event-detail" element={<EventDetail location={location} {...sharedProps} />} />
                <Route path="/profile/edit-event" element={<EditEvent location={location} {...sharedProps} />} />
                <Route path="/profile/history" element={<History location={location} {...sharedProps} />} />
                <Route path="/trip/location" element={<Location location={location} {...sharedProps} />} />
                <Route path="/trip/duration" element={<Duration location={location} {...sharedProps} />} />
                <Route path="/trip/looking-for" element={<LookingFor location={location} {...sharedProps} />} />
                <Route path="/trip/results" element={<Results location={location} {...sharedProps} />} />
                <Route path="/trip/your-trip" element={<YourTrip location={location} {...sharedProps} />} />
                <Route path="/service-detail" element={<Service location={location} {...sharedProps} />} />

                <Route path="/add-review" element={<AddReview location={location} {...sharedProps} />} />
                <Route path="/notifications" element={<Notifications location={location} {...sharedProps} />} />
                <Route path="/*" element={<Navigate to="/" />} />
                
              </Routes>
            </AnimatePresence>
          </div>

          {!location.pathname.startsWith('/') && <Navbar location={location} {...sharedProps} />}

        </div>
      </>
    );
  }
  

  //PUBLIC URLS
  return (
    <>
      <div className="w-full mx-auto h-full flex flex-col bg-white justify-between ">

        <Routes location={location} key={location.pathname}>


          <Route path="/marketplace" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/trip/location" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/trip/duration" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/trip/your-trip" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/trip/looking-for" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/trip/results" element={<Header title="Discover" hasLocation="true" />} />
          <Route path="/post-detail" element={<HeaderSocial />} />
          <Route path="/notifications" element={<HeaderSocial />} />
          <Route path="/new-session" element={<HeaderSocial />} />
          <Route path="/surfer-profile" element={<Header title="Discover" />} />
          <Route path="/edit-profile" element={<Header title="Edit Profile" />} />
          <Route path="/spots" element={<HeaderSearch title="Spots" />} />
          <Route path="/chat" element={<HeaderSearch title="Chat" />} />
          <Route path="/spot-detail" element={<HeaderSearch />} />
          <Route path="/product-page" element={<HeaderProfile />} />

          <Route path="/profile" element={<HeaderUserProfile />} />
          <Route path="/profile/sessions" element={<HeaderUserProfile />} />
          <Route path="/profile/saved" element={<HeaderUserProfile />} />
          <Route path="/profile/details" element={<HeaderUserProfile />} />
          <Route path="/profile/boards" element={<HeaderUserProfile />} />
          <Route path="/profile/trips" element={<HeaderUserProfile />} />
          <Route path="/profile/add-product" element={<HeaderUserProfile />} />
          <Route path="/profile/products" element={<HeaderUserProfile />} />
          <Route path="/profile/orders" element={<HeaderUserProfile />} />
          <Route path="/profile/order-detail" element={<HeaderUserProfile />} />
          <Route path="/profile/services" element={<HeaderUserProfile />} />
          <Route path="/profile/add-service" element={<HeaderUserProfile />} />
          <Route path="/profile/bookings" element={<HeaderUserProfile />} />
          <Route path="/profile/booking-detail" element={<HeaderUserProfile />} />
          <Route path="/profile/edit-booking" element={<HeaderUserProfile />} />
          <Route path="/profile/events" element={<HeaderUserProfile />} />
          <Route path="/profile/event-detail" element={<HeaderUserProfile />} />
          <Route path="/profile/edit-event" element={<HeaderUserProfile />} />

          <Route path="/settings" element={<HeaderUserProfile />} />

        </Routes>

        <div className="w-full  flex  relative dark:bg-zinc-800">
          <AnimatePresence mode="sync">
            {isLoading ? (
              <Routes location={location} key={location.pathname}>
                <Route path="/*" element={
                  <div className="w-full h-full flex items-center justify-center">
                    <Link className='w-[50px] h-[50px] flex items-center justify-center rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3 animate-spin'>
                      <ApertureOutline color={'#ffffff'} />
                    </Link>
                  </div>
                } />
              </Routes>
            ) : (
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Landing location={location} {...sharedProps} />} />
                <Route path="/landing" element={<Landing location={location} {...sharedProps}  />} />
                <Route path="/landing/terms" element={<Terms />} location={location} {...sharedProps} />
                <Route path="/login" element={<Login location={location} {...sharedProps} />} />
                <Route path="/signup" element={<Signup location={location} {...sharedProps} />} />
                <Route path="/recovery" element={<Recovery location={location} {...sharedProps} />} />
                <Route path="/logout" element={<Logout location={location} {...sharedProps} />} />
                <Route path="/*" element={<Navigate to="/login" />} />

              </Routes>
            )}
          </AnimatePresence>

        </div>

        {location.pathname !== '/' && <Navbar location={location} {...sharedProps} />}
      </div>

      

    </>
  );
};

export default AnimatedMaster;
