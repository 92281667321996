
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_STAR from '../../assets/img/star.svg';
import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_TOMAS from '../../assets/img/tomas.jpg';
import SRC_NATHAN from '../../assets/img/nathan.webp';
import SRC_JOHN from '../../assets/img/john.jpeg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_DELETE from '../../assets/img/delete.svg';

import { HeaderCheckout } from '../Header';



export function Notifications() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Notifications" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='px-global font-semibold py-3'>Yesterday</div>
                    <Booking avatar={SRC_COCO} name="Coco Ho" hasThumb="true" postThumb="https://tinyurl.com/2d28uk2t" date="April 19, 2019" detail={<> liked <span className='font-bold'>your post</span></>} />
                    <Booking avatar={SRC_TOMAS} name="Tomas" hasButton="true" btn="Follow" date="April 19, 2019" detail={<>started following you</>} />
                    <div className='px-global font-semibold py-3'>Past 7 days</div>
                    <Booking avatar={SRC_NATHAN} name="Nathan" hasThumb="true" postThumb={SRC_BOARD} date="April 19, 2019" detail={<>requested to rent <span className='font-bold'>Firewire Seaside</span></>} />
                    <Booking avatar={SRC_JOHN} name="John" hasThumb="true" postThumb="https://tinyurl.com/2d28uk2t" date="April 19, 2019" detail={<> commented on your post:<br /> <span className='text-slate-400'>Wow nice session!</span></>} />
                    <Booking avatar={SRC_NATHAN} name="Nathan"  date="April 19, 2019" detail={<>requested your service <span className='font-bold'>Advanced surf training</span></>} />

                    <div className='px-global font-semibold py-3'>Past 30 Days</div>
                    <Booking avatar={SRC_COCO} name="Coco Ho" hasThumb="true" postThumb="https://tinyurl.com/2d28uk2t" date="April 19, 2019" detail={<> liked <span className='font-bold'>your post</span></>} />
                    <Booking avatar={SRC_TOMAS} name="Tomas" hasButton="true" btn="Follow" date="April 19, 2019" detail={<>started following you</>} />
                    <Booking avatar={SRC_NATHAN} name="Nathan" hasThumb="true" postThumb={SRC_BOARD} date="April 19, 2019" detail={<>requested to rent <span className='font-bold'>Firewire Seaside</span></>} />
                    <Booking avatar={SRC_JOHN} name="John" hasThumb="true" postThumb="https://tinyurl.com/2d28uk2t" date="April 19, 2019" detail={<> commented on your post:<br /> <span className='text-slate-400'>Wow nice session!</span></>} />
                    <Booking avatar={SRC_NATHAN} name="Nathan"  date="April 19, 2019" detail={<>requested your service <span className='font-bold'>Advanced surf training</span></>} />
                </div>
            </motion.div>

        </>

    )
}

export function Booking(props) {
    const { avatar, name, date, detail, hasThumb, postThumb, hasButton, btn } = props;
    return (
        <div className="flex gap-x-4 items-center p-global text-sm hover:bg-zinc-100 rounded-lg relative cursor-pointer">
            <img className="size-12 rounded-full object-cover" src={avatar} />
            <div>
                <span className="font-bold">{name}</span> {detail}
                <div className="my-1 md:relative  text-xs opacity-50">
                   {date}
                </div>
            </div>
            {hasThumb && 
                <img src={postThumb} className='size-10 object-cover ml-auto' />
            }
            {hasButton && 
                <button className='px-3 py-2 bg-sky-500 text-white rounded-lg ml-auto text-xs'>{btn}</button>
            }

        </div>
    )
}









