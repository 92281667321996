
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_ADD from '../../assets/img/add.svg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_BOARDTWO from '../../assets/img/board-2.png';
import SRC_BOARDTHREE from '../../assets/img/board-3.png';

import { InputField } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';




export function Trips() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your Trips" hasIcon="true" icon={SRC_ADD} link="/trip/location" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <TripItem title="Siargao Island" img="https://tinyurl.com/22d2st9l" date="24 - 29 April, 2024" hasUpcoming="true" />
                        <TripItem title="Oahu, Hawaii" img="https://tinyurl.com/2d28uk2t" date="01 - 10 May, 2024" hasUpcoming="true" />
                        <TripItem title="Hossegor, France" img="https://tinyurl.com/25wh97hh" date="11 - 24 June, 2024" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function TripItem(props) {
    const { img, title, date } = props;
    return (
        <Link to="/trip/your-trip" className="w-full py-global relative mb-global flex flex-col items-center last-of-type:border-none">
            <div layoutId="tripImg" className='w-full relative before:w-full before:block before:pt-[66%]'>
                <motion.img src={img} className='size-full absolute top-0 left-0 rounded-2xl object-cover' />

            </div>
            <div className='w-full pt-global'>
                <div className='font-semibold text-lg'>{title}</div>
                <div className='text-slate-500 text-sm flex items-center gap-x-4'>
                    {date}
                </div>
            </div>
        </Link>
    )
}







