
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_STAR from '../assets/img/star.svg';
import SRC_MAP from '../assets/img/map-example.jpg';
import SRC_GREAT from '../assets/img/great.svg';

import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_JOHN from '../assets/img/john.jpeg';
import SRC_RAMZI from '../assets/img/ramzi.jpeg';
import SRC_COCO from '../assets/img/coco.jpeg';
import SRC_TOMAS from '../assets/img/tomas.jpg';

import StaticCard from "./ui/StaticCard";
import ProductCard from "./ui/ProductCard";

export function Chat() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 100, scale: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className='page-wrapper p-0 pt-1 no-scrollbar'>


                <motion.div
                    initial={{ y: 100 }}
                    animate={{ y: 0 }}
                    className='w-full'>
                    <AnimatedTabs />
                </motion.div>



            </motion.div>
        </>

    )
}


const tabs = [
    {
        name: 'tab1',
        label: 'People',
        render: () => {
            return <>
                <ChatItem image={SRC_NATHAN} name="Nathan" desc="Hey let's surf later!" hasUnread="true" />
                <ChatItem image={SRC_COCO} name="Coco" desc="How was it today? Epic?" hasUnread="true" />
                <ChatItem image={SRC_JOHN} name="John" desc="We are planning trip up north. You want..." hasUnread="true"  />
                <ChatItem image={SRC_RAMZI} name="Ramzi" desc="So good today. Can't wait for the morning" />
                <ChatItem image={SRC_TOMAS} name="Tomas" desc="Beer after surf? It's a beautiful day" />

            </>
        }
    },
    {
        name: 'tab2',
        label: 'Groups',
        render: () => {
            return <>
                Groups
            </>
        }
    },
    {
        name: 'tab3',
        label: 'Spot chats',
        render: () => {
            return <>
                Spot chats
            </>
        }
    },


];


function AnimatedTabs() {
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleClick = (e, tab) => {
        e.preventDefault()

        setActiveTab(tab)
    }

    const isSelected = tab => activeTab.name === tab.name

    return (
        <>
            <div className="w-full flex sticky top-0 bg-white dark:bg-zinc-800 z-50 gap-x-3 overflow-auto no-scrollbar border-b border-slate-200 dark:border-zinc-700">
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        className=" w-1/2 text-center"
                    >
                        <a className='px-4 py-3 pt-0 block ' href="#" onClick={e => handleClick(e, tab)}>
                            {tab.label}
                        </a>

                        {isSelected(tab) && (
                            <motion.div layoutId="indicator" className="h-1 bg-sky-500 rounded-full" />
                        )}
                    </div>
                ))}
            </div>

            <div className="w-full bg-white dark:bg-zinc-800 relative z-40 px-global">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab.name || "empty"}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        {activeTab && activeTab?.render()}
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    )
}


export function ChatItem(props) {
    const { desc, image, name, hasUnread } = props;
    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 100, y: 0 }}
            transition={{ }}
            className='w-full relative flex justify-between items-center py-global overflow-hidden'>

            <img className='size-14 rounded-full flex-shrink-0 object-cover mr-3' src={image} />
            <div className='w-full flex flex-col flex-shrink'>
                <div className='flex items-center justify-between w-full'>
                    <h3 className='card-title font-semibold text-base '>{name}</h3>
                    <div className='text-sm text-slate-500 dark:text-zinc-500'>4:23pm</div>
                </div>
                <div className='flex items-center justify-between w-full'>
                    <div className='card-title text-sm text-slate-500 dark:text-zinc-500 w-full whitespace-nowrap overflow-hidden text-ellipsis'>{desc}</div>
                    {hasUnread && (
                    <div className='flex size-6 items-center justify-center rounded-full bg-sky-500 text-white text-xs flex-shrink-0'>6</div>
                    )}
                </div>
            </div>



        </motion.div>
    )
}

