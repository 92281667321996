
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_CARD from '../assets/img/card.svg';
import SRC_WATCH from '../assets/img/watch.svg';
import SRC_VISA from '../assets/img/visa.png';
import SRC_NOTI from '../assets/img/notifications.svg';
import SRC_EMAIL from '../assets/img/email.svg';
import SRC_MOON from '../assets/img/moon.svg';
import SRC_PASSWORD from '../assets/img/password.svg';
import SRC_PRIVACY from '../assets/img/eye.svg';
import SRC_SUB from '../assets/img/sunscription.svg';

import SRC_FLAG from '../assets/img/flag.png';

import SRC_APPLE from '../assets/img/apple-watch.svg';
import SRC_GARMIN from '../assets/img/garmin.svg';

import SRC_CURRENCY from '../assets/img/sale.svg';


import SRC_EVENTS from '../assets/img/calendar.svg';
import SRC_TIME from '../assets/img/time.svg';

import SRC_CHEV from '../assets/img/chevron-right.svg';

import { HeaderEdit } from './Header';
import { InputField, TextField } from './ui/Forms';
import Switch from './ui/Switch';
import { Expandable } from './ui/Expandable';

import PaymentDrawer from './PaymentDrawer';
import CurrencyDrawer from './ui/CurrencyDrawer';


export function Settings() {
    const [isPayment, setIsPayment] = useState(false);
    const handleOpenPayment = () => {
        setIsPayment(true);
    };
    const handleClosePayment = () => {
        setIsPayment(false);
    };

    const [isCurrency, setIsCurrency] = useState(false);
    const handleOpenCurrency = () => {
        setIsCurrency(true);
    };
    const handleCloseCurrency = () => {
        setIsCurrency(false);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderEdit title="Settings" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full px-global'>
                        <Expandable
                            title="Notifications"
                            closedtitle="Messages, Likes, Bookings.."
                            opentitle="Enable or disable push notifications"
                            hasIcon="true"
                            icon={SRC_NOTI}
                            content={
                                <div className='mb-8 pl-9 pr-2'>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Like <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Comment <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        New messages <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        New booking <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        New order <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        New gear for sale in your area <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        New gear for rent in your area <Switch />
                                    </div>


                                </div>
                            }
                        />

                        <Expandable
                            title="Currency"
                            closedtitle="Philippine peso"
                            opentitle="Change currency"
                            hasIcon="true"
                            icon={SRC_CURRENCY}
                            content={
                                <div className='mb-8 w-full'>
                                    <div className='w-full flex items-center text-sm gap-x-4 '>
                                        <img src={SRC_FLAG} className='size-8 object-cover rounded-full' />
                                        <div>
                                            <div className='font-semibold text-base'>PHP</div>
                                            <div className='text-slate-400 dark:text-zinc-400 text-xs'>Philippine peso</div>
                                        </div>
                                        <button onClick={handleOpenCurrency} className='px-3 py-2 ml-auto text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 text-sm'>
                                            Change
                                        </button>
                                    </div>
                                    
                                </div>
                            }
                        />

                        <Expandable
                            title="Payment methods"
                            closedtitle="Debit card, Paypal, Google pay"
                            opentitle="Manage payment methods"
                            hasIcon="true"
                            icon={SRC_CARD}
                            content={
                                <div className='mb-8 w-full'>
                                    <div className='w-full flex items-center text-sm gap-x-4 border-b border-slate-200 pb-4'>
                                        <img src={SRC_VISA} className='size-8 object-contain' />
                                        <div>
                                            <div className='font-semibold text-base'>Visa •••• 2911</div>
                                            <div className='text-slate-400 dark:text-zinc-400 text-xs'>Expiring 09/2029</div>
                                        </div>
                                        <button className='px-3 ml-auto flex-shrink-0 flex items-center py-2 mt-2 bg-slate-100 dark:bg-zinc-700  dark:text-white text-sm rounded-lg'>
                                            Remove
                                        </button>
                                    </div>
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm mt-5' onClick={handleOpenPayment}><span className="mr-2 ml-1">+</span>Add payment method</button>
                                </div>
                            }
                        />
                        <Expandable
                            title="Connected devices"
                            closedtitle="Garmin, Apple watch"
                            opentitle="Connect your device to record your sessions"
                            hasIcon="true"
                            icon={SRC_WATCH}
                            content={
                                <>
                                    <div className='mb-8 w-full'>
                                        <div className='w-full flex items-center text-sm gap-x-4 border-b border-slate-200 py-4'>
                                            <img src={SRC_GARMIN} className='w-14 object-cover rounded-lg border border-slate-200 dark:border-zinc-600' />
                                            <div className='font-semibold text-base'>Garmin Connect</div>
                                            <button className='px-3 ml-auto flex-shrink-0 flex items-center py-2 mt-2 bg-slate-100 dark:bg-zinc-700  dark:text-white text-sm rounded-lg'>
                                                Remove
                                            </button>
                                        </div>
                                        <div className='w-full flex items-center text-sm gap-x-4 pt-4'>
                                            <img src={SRC_APPLE} className='w-14 object-cover rounded-lg border border-slate-200 dark:border-zinc-600' />
                                            <div className='font-semibold text-base'>Apple watch</div>
                                            <button className='px-3 ml-auto flex-shrink-0 flex items-center py-2 mt-2 bg-black dark:bg-zinc-700  text-white text-sm rounded-lg'>
                                                Connect
                                            </button>
                                        </div>

                                    </div>
                                </>
                            }
                        />
                        <Expandable
                            title="Theme"
                            closedtitle="Dark, Light, System"
                            opentitle="Choose your preferred theme"
                            hasIcon="true"
                            icon={SRC_MOON}
                            content={
                                <div className='mb-8 pl-9 pr-2'>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Light <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Dark <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        System <Switch />
                                    </div>
                                </div>
                            }
                        />
                        <Expandable
                            title="Privacy"
                            closedtitle="Public or hidden profile"
                            opentitle="Set up privacy of your profile and posts"
                            hasIcon="true"
                            icon={SRC_PRIVACY}
                            content={
                                <div className='mb-8 pl-9 pr-2'>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Public profile <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        Hidden profile <Switch />
                                    </div>
                                    <div className='w-full py-3 flex items-center justify-between'>
                                        All posts are public <Switch />
                                    </div>
                                </div>
                            }
                        />
                        <Expandable
                            title="Email"
                            closedtitle="Change your account email"
                            opentitle="Change your account email"
                            hasIcon="true"
                            icon={SRC_EMAIL}
                            content={
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="New email" id="email" />
                                    <InputField label="Re-type new email" id="email" />
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                </form>
                            }
                        />
                        <Expandable
                            title="Password"
                            closedtitle="Change your password"
                            opentitle="Change your password"
                            hasIcon="true"
                            icon={SRC_PASSWORD}
                            content={
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Old password" id="old" />
                                    <InputField label="New Password" id="new" />
                                    <InputField label="Re-type new Password" id="new2" />
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                </form>
                            }
                        />
                        <Expandable
                            title="Subscriptions"
                            closedtitle="Active PRO subscription"
                            opentitle="Manage your subscription"
                            hasIcon="true"
                            icon={SRC_SUB}
                            content={
                                <div className='mb-8 w-full'>
                                    <div className='w-full flex items-center text-sm gap-x-4pb-4'>
                                        <div>
                                            <div className='font-semibold text-base'>PRO subscription</div>
                                            <div className='text-slate-400 dark:text-zinc-400 text-xs'>Renews 09, February 2029</div>
                                        </div>
                                        <button className='px-3 ml-auto flex-shrink-0 flex items-center py-2 mt-2 bg-slate-100 dark:bg-zinc-700  dark:text-white text-sm rounded-lg'>
                                            Cancel
                                        </button>
                                    </div>
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm mt-5'>View subscriptions</button>
                                </div>
                            }
                        />
                    </div>
                </div>
            </motion.div>
            <AnimatePresence>
                {isPayment && <PaymentDrawer onClose={handleClosePayment} />}
                {isCurrency && <CurrencyDrawer onClose={handleCloseCurrency} />}
            </AnimatePresence>
        </>

    )
}








