
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_ADD from '../../assets/img/add.svg';
import SRC_BOARD from '../../assets/img/board.svg';

import { InputField } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';


export function Boards() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your boards" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Expandable
                            title="Seaside"
                            closedtitle="Firewire Seaside, 5'8"
                            opentitle="Edit"
                            hasIcon="true"
                            icon={SRC_BOARD}
                            content={
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Board Name" value="Seaside" id="board" />
                                    <InputField label="Brand" value="Firewire" id="Brand" />
                                    <InputField label="Size" value="5'8" id="size" />
                                    <div className='w-full flex gap-x-2'>
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600  text-sm'>Save changes</button>
                                        <button className='px-3 py-3 text-slate-700 rounded-lg bg-slate-200 dark:bg-zinc-600 text-sm'>Delete</button>
                                    </div>
                                </form>
                            }
                        />
                        <Expandable
                            title="Monsta"
                            closedtitle="JS Monsta 6, 6'1"
                            opentitle="Edit"
                            hasIcon="true"
                            icon={SRC_BOARD}
                            content={
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Board Name" value="Monsta" id="board" />
                                    <InputField label="Brand" value="JS" id="Brand" />
                                    <InputField label="Size" value="6'1" id="size" />
                                    <div className='w-full flex gap-x-2'>
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600  text-sm'>Save changes</button>
                                        <button className='px-3 py-3 text-slate-700 rounded-lg bg-slate-200 dark:bg-zinc-600 text-sm'>Delete</button>
                                    </div>
                                </form>
                            }
                        />
                        <Expandable
                            title="Add"
                            closedtitle="New board"
                            opentitle="New board"
                            hasIcon="true"
                            icon={SRC_ADD}
                            content={
                                <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                    <InputField label="Board Name" id="board" />
                                    <InputField label="Brand" id="Brand" />
                                    <InputField label="Size" id="size" />
                                    <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                </form>
                            }
                        />

                    </div>
                </div>
            </motion.div>

        </>

    )
}








