
import { useState } from 'react'
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"
import { Link } from "react-router-dom";

import SRC_STAR from '../../assets/img/star.svg';




const StaticCard = (props) => {
    const { img, name, hasRating, price } = props;

    return (

        <>
            <motion.div
                whileHover={{ scale: 0.9 }}
                layout
                className='expandable-card-wrapper snap-center w-2/5 h-[220px] bg-slate-100 dark:bg-zinc-900 rounded-xl flex-shrink-0 relative'>
                <div className='overlay fixed bottom-0 h-[calc(100%-68px)] w-full left-0 bg-white'></div>
                <Link to="/surfer-profile" className='expandable-card flex items-center justify-center w-full h-full flex-shrink-0 rounded-lg'>

                    <div className='expandable-card-inner overflow-x-hidden size-full bg-white rounded-xl overflow-auto'>

                        <div className={'expandable-card-image w-full h-full relative z-20 before:w-full before:block'}>
                            <img className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                            <div className='gradient-overlay size-full px-3 py-2 flex items-end text-white absolute bg-gradient-to-t from-black/40 to-transparent'>
                                <div className='w-full'>
                                    <h3 className='card-title font-semibold text-sm mb-px'>{name}</h3>
                                    <div className='flex w-full justify-between text-xxs opacity-90'>
                                        <div>0.2km</div>
                                        {hasRating && (
                                            <div className='flex items-center gap-x-1'>
                                                4.9
                                                <img className='invert -translate-y-px' src={SRC_STAR} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Link>

            </motion.div>
        </>
    )
}

export default StaticCard