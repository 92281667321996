import { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import { Link, useNavigate } from "react-router-dom";
import SRC_EYE from '../assets/img/eye.svg';

import { InputField } from './ui/Forms';

import swal from 'sweetalert';
import axios from 'axios';

import { API_ROOT } from '../api';

import { GetRouteSearchParams } from '../helpers/GeneralHelper';

import SocialLogin from './SocialLogin';

export const Login = (props) => {

    const navigate = useNavigate(); // Initialize the navigate function

    const { TRANSLATE, LANGUAGE, location, history, onStartUser } = props;

    const [isLoading, setLoading] = useState(false);
    const [EMAIL, setEmail] = useState('');
    const [PASSWORD, setPassword] = useState('');

    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeCode = routeParams.code || '';

        if (routeCode) {
            //alert('The Code is ===> '+routeCode);
            onLoginGoogleFlow(routeCode);
        }

    }, [location.search])


    useEffect(() => {
        const handleMessageEvent = (event) => {
            const message = event.data;

            if (message) {
                handleMessage(message);
            }
        };

        // Add the event listener when the component mounts
        document.addEventListener("message", handleMessageEvent);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("message", handleMessageEvent);
        };
    }, []);


    const handleMessage = (object) => {

        try {
            const message = object;
            //  alert(JSON.stringify(message))

            if (message && message.type === 'LOGIN_NATIVE') {
                const data = message.data;
                onLoginGoogleNative(data);
                // alert('LOGIN_NATIVE ===> '+JSON.stringify(data, null , 2));
            }
            else if (message && message.type === 'LOGIN_APPLE_NATIVE') {
                const data = message.data;
                onLoginAppleNative(data);
            }

        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error, e.g., display a user-friendly message or log more details
        }
    };

    const onKeyUp = (event) => {

        event.preventDefault();


        if (event.keyCode === 13) {

            event.target.blur();
            onLogin();

        }
    }

    const onLogin = () => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeNext = routeParams.next || '';

        // if (!isValidEmail(EMAIL)) {
        //     return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
        // }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var form = {
            email: EMAIL.trim(),
            password: PASSWORD.trim(),
            lang: LANGUAGE,
            timezone: userTimezone

        }

        setLoading(true);

        axios.post(API_ROOT + '/users/login', form).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();

            if (routeNext) {
                navigate(routeNext);
                return
            }

            navigate(`/${LANGUAGE}/settings`);

        })
            .catch(error => {

                setLoading(false);

                console.log("ERRO )))>== ", error);

                if (!error || !error.response) {
                    return swal('Login', TRANSLATE.error_invalid_credentials, "warning");
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return swal('Login', TRANSLATE.error_missing_password, "warning");
                }
                else if (error_message === 'missing_email') {
                    return swal('Login', TRANSLATE.error_invalid_email, "warning");
                }

                return swal('Login', TRANSLATE.error_invalid_credentials, "warning");
            });
    }

    const onLoginGoogle = async (params) => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeNext = routeParams.next || '';

        if (!params) {
            return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email, "warning");
        }

        setLoading(true);

        if (params.access_token) {
            const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
            const ResponseData = Response ? Response.data : {};
            params = {
                ...params,
                ...ResponseData
            }
        }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var FORM = {
            lang: LANGUAGE,
            oauth: params,
            timezone: userTimezone

        }

        axios.post(API_ROOT + '/users/login/google', FORM).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();

            if (routeNext) {
                navigate(routeNext);
                return
            }

            navigate(`/${LANGUAGE}/settings`);

        })
            .catch(error => {

                setLoading(false);

                navigate(`/${LANGUAGE}/login`);

                if (!error || !error.response) {
                    return swal('Login', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return swal('Login', TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return swal('Login', TRANSLATE.error_invalid_email)
                }

                return swal('Login', TRANSLATE.error_invalid_credentials)
            });
    }

    const onLoginApple = async (params) => {
        const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
        if (isNativeApp) {
            const webData = {
                type: 'LOGIN_APPLE'
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(webData));
        }
        else {
            if (params) {
                const fun = params.fun;
                fun()
                // alert('Download the app to login with Apple')
            }

        }
    }

    const onLoginGoogleFlow = async (authCode) => {

        const isLocalhost = window.location.href.includes('localhost:3000');

        try {

            setLoading(true);

            const clientId = '534041740722-el9c12qp89n0tihead1iiqcb7ptiraom.apps.googleusercontent.com';
            const clientSecret = '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';
            const redirectUri = isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.dogu.app/en/login';

            const tokenEndpoint = 'https://oauth2.googleapis.com/token';
            const userDataEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';

            const data = new URLSearchParams();
            data.append('code', authCode);
            data.append('client_id', clientId);
            data.append('client_secret', clientSecret);
            data.append('redirect_uri', redirectUri);
            data.append('grant_type', 'authorization_code');

            // Step 1: Exchange auth code for access token and refresh token
            const tokenResponse = await axios.post(tokenEndpoint, data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            //return swal('success', JSON.stringify(tokenResponse, null, 2), 'success');


            const tokenData = tokenResponse.data;


            // Step 2: Fetch user data using access token
            const userDataResponse = await fetch(userDataEndpoint, {
                headers: {
                    Authorization: `Bearer ${tokenData.access_token}`
                }
            });

            const userData = await userDataResponse.json();

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


            var FORM = {
                lang: LANGUAGE,
                oauth: {
                    ...userData,
                    accessToken: tokenData.access_token,
                    refreshToken: tokenData.refresh_token,
                    picture: userData.picture ? userData.picture.replace('s96', 's512') : ''

                },
                timezone: userTimezone

            }

            //return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(API_ROOT + '/users/login/google', FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                navigate(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    navigate(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning')
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return swal('Login', TRANSLATE.error_missing_password, 'warning')
                    }
                    else if (error_message === 'missing_email') {
                        return swal('Login', TRANSLATE.error_invalid_email, 'warning')
                    }

                    return swal('Login', TRANSLATE.error_invalid_credentials, 'warning')
                });

        }
        catch (e) {
            setLoading(false);
            navigate(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }

    const onLoginGoogleNative = async (params) => {

        try {

            setLoading(true);

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var FORM = {
                lang: LANGUAGE,
                oauth: {
                    ...params
                },
                timezone: userTimezone
            }

            // return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(API_ROOT + '/users/login/google', FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                navigate(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    navigate(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return swal('Login', TRANSLATE.error_missing_password, 'warning');
                    }
                    else if (error_message === 'missing_email') {
                        return swal('Login', TRANSLATE.error_invalid_email, 'warning');
                    }

                    return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
                });

        }
        catch (e) {
            setLoading(false);
            navigate(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }

    const onLoginAppleNative = async (params) => {

        try {

            setLoading(true);

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var FORM = {
                lang: LANGUAGE,
                oauth: {
                    ...params
                },
                timezone: userTimezone
            }

            // return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(`${API_ROOT}/users/login/apple`, FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                navigate(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    navigate(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return swal('Login', TRANSLATE.error_invalid_credentials)
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return swal('Login', TRANSLATE.error_missing_password)
                    }
                    else if (error_message === 'missing_email') {
                        return swal('Login', TRANSLATE.error_invalid_email)
                    }

                    return swal('Login', TRANSLATE.error_invalid_credentials)
                });

        }
        catch (e) {
            setLoading(false);
            navigate(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }


    return (
        <>

            <div className="fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0">
                <div className='w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0'>
                    <div className="font-semibold tracking-wider text-xl">SURF<span className='text-sky-500'>YX</span></div>
                    <Link to="/signup" className='text-sky-500 font-semibold text-sm'>Create account</Link>
                </div>
                <div className='w-full p-8 h-full overflow-auto items-center justify-center flex flex-col'>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className='w-full mb-12 flex-col gap-3 items-center justify-center text-center'>
                        <h1 className='text-4xl font-semibold'>Welcome back!</h1>
                        <h3 className='text-lg text-slate-400'>Please login to continue</h3>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className='w-full gap-3 flex flex-col'>
                        <InputField label="Email" type="email" inputProps={{
                            value: EMAIL,
                            placeholder: 'Type Here',
                            onChange: (e) => setEmail(e.target.value),
                            onKeyUp: (e) => onKeyUp(e)

                        }} />
                        <InputField label="Password" id="password" hasIcon="true" type="password" icon={SRC_EYE} inputProps={{
                            value: PASSWORD,
                            placeholder: 'Type Here',
                            onChange: (e) => setPassword(e.target.value),
                            onKeyUp: (e) => onKeyUp(e)

                        }} />
                        <Link className='text-sm font-semibold text-slate-400 ml-auto' to="/recovery">
                            Password recovery
                        </Link>

                        {isLoading ? (
                            <div className='w-full rounded-xl bg-zinc-500 text-white shadow-2xl p-4 text-center mt-3'>
                                Loading...
                            </div>
                        ) : (
                            <div className='w-full rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3' onClick={() => onLogin()}>
                                Log in
                            </div>
                        )}

                        <div className='flex my-5 gap-5 items-center justify-center text-xs text-slate-400'>
                            <div className='w-[40px] h-px bg-slate-300' />
                            Or continue with
                            <div className='w-[40px] h-px bg-slate-300' />
                        </div>
                        <SocialLogin {...props}
                            onLoginApple={onLoginApple}
                            onLoginGoogle={onLoginGoogle}
                        />
                    </motion.div>
                </div>
            </div>



        </>
    );
}
