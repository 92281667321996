const TRANSLATER = {

  lang: 'it',

  //Seo
  seo_title: 'Noleggio e servizio di attrezzatura per bambini',
  seo_description: 'Noleggia seggiolini auto, passeggini, lettini e tanti altri prodotti. I nostri fornitori consegnano dove e quando vuoi. Viaggia leggero e sicuro con i tuoi figli.',
  seo_city_title: "Noleggia articoli per bambini a {{city}}, consegna e ritiro",
  seo_city_description: "Noleggia attrezzature per bambini per le tue vacanze a {{city}}. Prenota passeggini, seggiolini auto, culle e molti altri articoli per bambini per le tue vacanze a {{city}}. Servizio di consegna e ritiro.",
  seo_category_title: "Affitta bambino {{category}} a {{city}}, pulito e sicuro",
  seo_category_description: "Noleggia baby {{category}} per le tue vacanze a {{city}}. {{category}} pulito, sicuro e garantito a {{city}}. Consegna {{category}} all'aeroporto o al tuo alloggio a {{ città}}.",
  seo_store_title: "{{store_name}} - articoli per bambini in {{city}}",
  seo_store_description: "{{store_name}} - articoli per bambini in {{city}}, prodotti puliti, sicuri e garantiti",
  seo_product_description: "Affitta {{category}} {{marca}} {{modello}} a {{city}} - dogu",

  //Header
  wd_login: 'Accedi',
  wd_signup: 'Iscriviti',
  wd_logout: 'Esci',
  wd_howitworks: 'Come funziona',
  wd_provider_area: 'Area providers',
  wd_customer_area: 'Area clienti',
  wd_list_gear: 'Elenca la tua attrezzatura',
  wd_blog: 'Blog',
  wd_when: 'Quando',
  wd_where: 'Dove',
  wd_start: 'Inizio',
  wd_end: 'Fine',
  wd_checkin: 'Controllo',
  wd_checkout: 'Acquista',
  wd_search: 'Cerca',
  wd_arriving: 'In arrivo',
  wd_leaving: 'In partenza',
  wd_from: 'da',
  wd_to: 'a',
  wd_and: 'e',
  wd_of: 'di',

  //Footer
  wd_learnmore: 'Ulteriori informazioni',
  wd_aboutus: 'Chi siamo',
  wd_trust: "Fiducia e sicurezza",
  wd_download: 'Scarica',
  wd_where_we_are: 'Dove siamo',
  wd_other_cities: 'Altre città',
  wd_support: 'Supporto',
  wd_help_center: 'Centro assistenza',
  wd_provider_resources: 'Risorse del provider',
  wd_terms: 'Termini e condizioni',
  wd_privacy: 'Informativa sulla privacy',
  wd_cancellation: 'Termini di cancellazione',
  wd_socialmedia: "Social media",
  wd_faq: 'Domande frequenti',

  //Words
  wd_locations: 'Località',
  wd_provider: 'Provider',
  wd_providers: 'Providers',
  wd_trips_taken: 'Viaggi effettuati',
  wd_cities_covered: 'Città coperte',
  wd_countries: 'Paesi',
  wd_time: 'Tempo',
  wd_click_here: 'Clicca qui',
  wd_gear_type: 'Tipo di ingranaggio',
  wd_child_age: ' Età del bambino ',
  wd_searching_providers: 'Alla ricerca di providers',
  wd_searching_products: 'Cerco prodotti',
  wd_all_cities: 'Tutte le città',
  wd_points_of_interest: 'Punti di interesse',
  wd_rental_dates: 'date di noleggio',
  wd_rentals: 'noleggi',
  wd_total_earned: 'totale guadagnato',
  wd_total_paid: 'totale pagato',
  wd_additional_info: 'Ulteriori informazioni',
  wd_order_total: 'totale ordine',
  wd_provider_place: 'Luogo del provider',
  wd_airports: 'aeroporti',
  wd_train_stations: 'stazioni ferroviarie',
  wd_ports: 'porti',
  wd_available_providers: 'providers disponibili',
  wd_other_providers: 'altri providers',


  //Search
  wd_destination: 'Destinazione',
  wd_rental_period: 'Periodo di noleggio',
  wd_pickup_address: 'Indirizzo di ritiro',
  wd_dropoff_address: 'Indirizzo di consegna',
  wd_address: 'Indirizzo',
  wd_address_street_landmark: 'Strada o punto di riferimento',
  wd_address_landmark_airport: 'Strada, punto di riferimento o aeroporto',
  wd_address_city_postal: 'Via, Città, CAP',
  wd_address_airport_station: 'Aeroporti, punti di riferimento o stazioni',
  wd_flexible: ' Sono flessibile',
  wd_pickup: 'Ritiro',
  wd_dropoff: 'Rilascio',

  //Commun
  wd_email: 'E-mail',
  wd_password: 'Password',
  wd_confirm_password: 'Conferma password',
  wd_full_name: 'Nome completo',
  wd_first_name: 'Nome',
  wd_last_name: 'Cognome',
  wd_first_last: 'Nome e Cognome',
  wd_login_google: 'Accedi con Google',
  wd_signup_google: 'Registrati con Google',
  wd_case_sensitive: 'Maiuscole/minuscole',
  wd_recover_password: 'Recupero password',
  wd_contact_details: 'Dettagli di contatto',
  wd_create_account: 'Crea account',

  wd_brand: 'marchio',
  wd_day: 'giorno',
  wd_days: 'giorni',
  wd_unit: 'unità',
  wd_units: 'unità',
  wd_price: 'prezzo',
  wd_location: 'posizione',
  wd_category: 'categoria',
  wd_categories: 'categorie',
  wd_age: 'età',
  wd_quantity: 'quantità',
  wd_products: 'prodotti',
  wd_product: 'prodotto',
  wd_availability: 'disponibilità',
  wd_more_articles: 'altri articoli',

  wd_store_name: 'nome negozio',
  wd_store_number: 'numero negozio',
  wd_store_currency: 'valuta del negozio',
  wd_store_status: 'stato negozio',
  wd_store_about: 'Informazioni sul tuo negozio',
  wd_store_placeholder: 'Costruisci fiducia con il tuo cliente raccontando un po\' di te',
  wd_store_availability: 'disponibilità del negozio',
  wd_phone_number: 'Numero di telefono',
  wd_delivery_point: 'Punto di consegna',
  wd_product_name: 'Nome del prodotto',
  wd_product_about: 'Descrizione del prodotto',
  wd_daily_price: 'Prezzo giornaliero',
  wd_weekly_price: 'Prezzo settimanale',
  wd_monthly_price: 'Prezzo mensile',
  wd_add_product: 'Aggiungi prodotto',
  wd_all_categories: 'Tutte le categorie',
  wd_new_product: 'Nuovo prodotto',
  wd_edit_product: 'Modifica prodotto',
  wd_published: 'pubblicato',
  wd_unpublished: 'non pubblicato',

  wd_all_orders: 'tutti gli ordini',
  wd_pending_approval: 'in attesa di approvazione',
  wd_upcoming: 'imminente',
  wd_rental_ending: 'fine noleggio',
  wd_currently_renting: 'attualmente in affitto',
  wd_rental_completed: 'noleggio completato',
  wd_canceled_rejected: 'annullato e rifiutato',

  wd_pending: 'in attesa',
  wd_accepted: 'accettato',
  wd_rejected: 'respinto',
  wd_canceled: 'cancellato',
  wd_delivering: 'consegna',
  wd_delivered: 'consegnato',
  wd_completed: 'completato',
  wd_refunded: 'rimborsato',
  wd_received: 'ricevuto',

  wd_message_customer: 'Invia un messaggio al cliente',

  //Risultati
  wd_available_self: 'Self Pickup & Dropoff disponibile GRATUITAMENTE',
  wd_available_pick_for: 'Ritiro e riconsegna disponibili per',
  wd_available_pick_from: 'Ritiro e riconsegna disponibili da',

  //Città
  wd_your_trip_to: 'Il tuo viaggio a',
  wd_rental_period_in: 'Periodo di noleggio a',
  wd_available_providers_in: 'providers disponibili in',
  wd_available_baby_gear: 'attrezzature per bambini disponibili',
  wd_choose_gear_from: 'Scegli la tua attrezzatura per bambini da',
  wd_products_in: 'prodotti in',

  //Provider
  wd_response_rate: 'Tasso di risposta',
  wd_response_within: 'Risponde entro 1 ora',
  wd_orders_accepted: 'Ordini accettati',
  wd_delivery_airport: 'Consegna in aeroporti',
  wd_delivery_station: 'Consegna in stazioni',
  wd_delivery_port: 'Consegna nei porti',
  wd_delivery_zones: 'zone di consegna',
  wd_top_clean_reviews: 'Le migliori recensioni pulite',
  wd_weekly_discounts: 'Sconti settimanali',


  //Prodotto
  wd_description: 'descrizione',
  wd_read_less: 'leggere di meno',
  wd_read_more: 'leggi di più',
  wd_convenient_delivery: 'Consegna conveniente',
  wd_quality_product: 'Prodotto di qualità',
  wd_cleanliness_standards: 'Norme di pulizia',
  wd_premium_support: 'Assistenza Premium',

  //Menù
  wd_myorders: 'i miei ordini',
  wd_orders: 'ordini',
  wd_reviews: 'recensioni',
  wd_notifications: 'notifiche',
  wd_wallet: 'portafoglio',
  wd_favorites: 'preferiti',
  wd_chat: 'chat',
  wd_contact: 'contatta l\'assistenza',
  wd_settings: 'impostazioni',

  wd_dashboard: 'dashboard',
  wd_myproducts: 'i miei prodotti',
  wd_earnings: 'guadagni',

  //Carrello
  wd_your_cart: 'il tuo carrello',
  wd_cart_empty: 'Il tuo carrello è vuoto',
  wd_subtotal: 'subtotale',
  wd_total: 'totale',
  wd_discount: 'sconto',
  wd_coupon_discount: 'buono sconto',
  wd_weekly_discount: 'sconto settimanale',
  wd_monthly_discount: 'sconto mensile',
  wd_more_products_provider: 'Altri prodotti dal provider',
  wd_consumable: 'Consumabile',

  //Guardare
  wd_your_order: 'Il tuo ordine',
  wd_order_details: 'Dettagli dell\'ordine',
  wd_customer_details: 'Dettagli cliente',
  wd_delivery_return: 'Consegna e Reso',
  wd_payment: 'Pagamento',
  wd_delivery_summary: 'Riepilogo consegna',
  wd_delivery_pickup_provider: 'Consegna e ritiro da parte del provider',
  wd_self_pickup: 'Ritiro e restituzione gratuiti',
  wd_self_pickup_tool: 'Visualizza luogo di ritiro approssimativo',
  wd_card_number: 'Numero carta',
  wd_expiry_month: 'Mese di scadenza',
  wd_expiry_year: 'Anno di scadenza',
  wd_security_code: 'Codice di sicurezza',

  add_payment_method: 'Aggiungi un metodo di pagamento',
  add_new_card: 'Aggiungi nuova carta',
  add_another_card: 'Aggiungi un\'altra carta',

  order_success: {
    t: 'La tua richiesta d\'ordine è stata ricevuta con successo.',
    s: "Controlla la tua e-mail, ti contatteremo nelle prossime 48 ore sullo stato del tuo ordine.",
    c: 'accetterà o rifiuterà la tua richiesta d\'ordine.',
    a: 'Prenotazione riuscita',
    b: 'Hai prenotato con successo il tuo ordine ed ecco la tua conferma',
  },

  //Pannello di controllo
  wd_your_earnings: 'I tuoi guadagni',
  wd_orders_count: 'Hai {{TOTAL}} nuovi ordini',
  wd_product_rentals_count: 'Questo prodotto ha {{TOTAL}} noleggi',
  wd_all_time: 'Tutto il tempo',
  wd_today: 'Oggi',
  wd_yesterday: 'Ieri',
  wd_last_days: 'Ultimi {{TOTAL}} giorni',
  wd_last_week: 'La scorsa settimana',
  wd_last_month: 'Il mese scorso',
  wd_last_year: 'L\'anno scorso',
  wd_quick_actions: 'Azioni rapide',
  wd_new_messages: 'Nuovi messaggi',

  //Posta in arrivo
  wd_choose_conversation: 'Scegli una conversazione',
  wd_no_conversations: 'Nessuna conversazione',
  wd_type_here: 'Digita qui',

  //Impostazioni
  wd_store_settings: 'impostazioni del negozio',
  wd_profile: 'profilo',
  wd_delivery_pickup: 'consegna e ritiro',
  wd_schedules: 'programmi',
  wd_payout: 'pagamento',

  //Pulsanti
  btn_start_now: 'Inizia ora',
  btn_more_info: 'Ulteriori informazioni',
  btn_become_provider: 'Diventa un provider',
  btn_book_now: 'Prenota ora',
  btn_provider_area: 'Vai all\'area providers',
  btn_apply_now: 'Applica ora',
  btn_rent_gear: 'Noleggia attrezzatura per bambini',
  btn_submit: 'Invia',
  btn_next: 'Avanti',
  btn_back: 'Indietro',
  btn_skip: 'Salta',
  btn_cancel: 'Annulla',
  btn_delete: 'Elimina',
  btn_upload: 'Carica',
  btn_refresh: 'Aggiorna',
  btn_decline: 'Rifiuto',
  btn_remove: 'Rimuovi',
  btn_apply: 'Applica',
  btn_continue: 'Continua',
  btn_select: 'Seleziona',

  btn_save: 'Salva',
  btn_add_to_cart: 'Aggiungi al carrello',
  btn_edit_profile: 'Modifica profilo',
  btn_save_changes: 'Salva modifiche',
  btn_upload_picture: 'Carica immagine',
  btn_use_picture: 'Usa questa immagine',
  btn_add_delivery_area: 'Aggiungi area di consegna',
  btn_add_new_product: 'Aggiungi nuovo prodotto',
  btn_publish_store: 'Pubblica negozio',
  btn_save_product: 'Salva prodotto',
  btn_delete_product: 'Elimina prodotto',
  btn_clear_calendar: 'Cancella calendario',
  btn_contact_customer: 'Contatta il cliente',
  btn_contact_provider: 'Contatta di provider',
  btn_change_password: 'Cambiare la password',

  btn_accepted_order: 'Accetta ordine',
  btn_delivering_order: 'Ordine in consegna',
  btn_delivered_order: 'Ordine consegnato',
  btn_completed_order: 'Ordine completato',
  btn_leave_review: 'Lascia una recensione',
  btn_check_review: 'Controlla la recensione',

  btn_continue_shopping: 'Continua lo shopping',
  btn_finish_booking: 'Termina prenotazione',
  btn_view_products: 'Visualizza {{TOTAL}} prodotti',
  btn_view_all: 'Visualizza tutto',

  btn_show_map: 'Mostra mappa',
  btn_hide_map: 'Nascondi mappa',

  btn_place_order: 'Invia l\'ordine',
  btn_manage_order: 'Gestisci ordine',



  //Questions
  ask_which_city: "In quale città stai andando?",
  ask_ready_book: 'Pronto per prenotare?',

  ask_where_delivery: "Dove dobbiamo consegnare l'attrezzatura per bambini?",
  ask_where_return: "Dove dovremmo ritirare l'attrezzatura per bambini?",
  ask_coupon: "Hai un coupon?",

  ask_sure: 'Sei sicuro?',
  ask_sure_accept: 'Sei sicuro di voler accettare questo ordine?',
  ask_sure_reject: "Sei sicuro di voler rifiutare questo ordine?",
  ask_sure_cancel: "Sei sicuro di voler annullare questo ordine?",

  ask_about_order: "Hai domande su questo ordine?",
  ask_why_reject: "Perché rifiuti quest'ordine?",
  ask_why_cancel: "Perché annulli questo ordine?",
  ask_help: 'Ciao ! Come posso aiutarla?',


  //Answers
  yes_accept: 'Sì accetto',
  yes_reject: 'Sì rifiuta',
  yes_cancel: 'Sì, annulla',

  //Tooltips
  tool_login: 'Creeremo un account per te in modo che tu possa gestire i tuoi ordini e contattare il provider a tuo piacimento. Hai già un account?',
  tool_signup: 'Accedi al tuo account per gestire i tuoi ordini e contattare il provider a tuo piacimento. Non hai un account?',

  tool_search_city: 'Cerca un esempio di città: Londra',
  tool_enter_city: 'Inserisci il nome di una città',
  tool_your_email: 'Il tuo indirizzo email',
  tool_your_name: 'Il tuo nome',

  tool_select_rental_dates: "Seleziona le date di noleggio per ottenere l'attrezzatura per bambini",
  tool_select_delivery: 'Se non sei sicuro, usa solo la città per ora',
  tool_select_checkin: 'Seleziona il giorno in cui ricevere gli accessori per bambini',
  tool_select_checkout: 'Seleziona il giorno in cui restituire l\'attrezzatura per bambini',
  tool_select_age: 'Seleziona l\'età di tuo figlio',
  tool_select_category: 'Seleziona la categoria di attrezzatura che desideri noleggiare',
  tool_select_availability: 'Seleziona le date in cui non sarai disponibile per il servizio di noleggio',
  tool_select_pickup: 'Seleziona il punto di ritiro',
  tool_select_dropoff: 'Seleziona il luogo di riconsegna',
  tool_select_from_dropdown: 'Scegli la tua preferenza di consegna dalle opzioni di seguito',

  tool_enter_email: 'Inserisci un indirizzo email valido',
  tool_enter_checkin: 'Inserisci una data di check-in valida',
  tool_enter_checkout: 'Inserisci una data di pagamento valida',
  tool_enter_pickup: 'Inserisci un punto di ritiro valido',
  tool_enter_dropoff: 'Inserisci un luogo di riconsegna valido',

  tool_store_address: 'Inserisci l\'indirizzo del tuo negozio ',
  tool_store_closed_dates: 'Negozio chiuso nelle seguenti date',
  tool_delivery_locations: 'Luoghi di consegna e ritiro',

  tool_enter_store_address: 'I clienti possono ritirare l\'attrezzatura da soli dal tuo indirizzo senza servizio di consegna.',
  tool_enter_delivery_locations: 'Gli aeroporti ei punti di riferimento famosi sono i luoghi più richiesti dai clienti.',

  tool_choose_gear_from: 'Scegli la tua attrezzatura tra {{TOTAL}} providers disponibili',
  tool_choose_gear_other: "Questi {{TOTAL}} providers non offrono la consegna presso la tua posizione, ma consegnano nelle vicinanze o sono disponibili per l'auto-ritiro gratuito.",
  tool_add_delivery_area: 'Più località aggiungi, più ordini puoi ricevere.',
  tool_product_availability: 'Ecco le date in cui questo prodotto viene noleggiato',

  tool_dnd_images: "Carica le immagini del tuo prodotto",

  tool_once_deleted: 'Una volta cancellato non sarai più in grado di recuperarlo',
  tool_once_proceed: 'Una volta eliminato, il tuo {{TYPE}} verrà eliminato. Vuoi continuare?',

  tool_tell_reason_reject: 'Indica al cliente un motivo per cui questo ordine non è stato accettato',
  tool_tell_reason_cancel: 'Dicci un motivo per cui questo ordine verrà annullato',

  tool_where_delivery: 'Inserisci l\'indirizzo dove desideri ritirare l\'attrezzatura o seleziona una delle opzioni di consegna disponibili.',
  tool_where_return: 'Inserisci l\'indirizzo a cui desideri riconsegnare l\'attrezzatura o seleziona una delle opzioni di reso disponibili.',

  tool_insert_coupon: 'Inserisci il codice coupon e fai clic su applica',
  tool_free_cancellation: 'Cancellazione gratuita fino a 48 ore.',

  tool_view_delivery_map: 'Puoi vedere dove {{store_name}} offre la consegna diretta sulla mappa.',
  tool_free_self_pickup: 'Ritiralo gratuitamente presso la sede del provider . ',
  tool_interested_other: "Altri articoli per bambini che potrebbero interessarti.",
  tool_additional_info: 'Scrivi maggiori informazioni su orari e indirizzi di consegna e ritiro, numero del volo, ecc.',

  tool_payment_hold: 'Non ti verrà addebitato alcun costo finché l\'ordine non sarà accettato. Se l\'ordine non viene accettato, la trattenuta verrà rilasciata. Quando la tua richiesta d\'ordine viene accettata, le cancellazioni sono completamente rimborsabili ad eccezione della tassa dogu fino a 48 ore prima del primo giorno di noleggio.',
  tool_payment_finishing: 'Terminando l\'ordine accetti',
  tool_payment_cancel: 'Annulla la prenotazione prima',
  tool_payment_refund: 'e riceverai un rimborso completo (ad eccezione della tassa dogu).',
  tool_select_card_payment: 'Seleziona la carta per il noleggio',


  //Alerts
  alert_recovery_success: 'Ti abbiamo inviato un\'e-mail ✉️ con le istruzioni per il recupero',
  alert_store_updated: {
    t: 'Negozio aggiornato',
    s: 'Il tuo negozio è stato aggiornato con successo'
  },
  alert_availability: {
    reimposta_t: 'La disponibilità è stata ripristinata',
    reset_s: 'La disponibilità del tuo negozio è stata reimpostata correttamente',
    salva_t: 'Disponibilità salvata',
    save_s: 'La disponibilità del tuo negozio è stata salvata con successo'
  },
  alert_order: {
    accepted_t: 'Ordine accettato',
    accepted_s: 'Il tuo ordine è stato accettato con successo',
    rejected_t: 'Ordine rifiutato',
    rejected_s: 'L\'ordine è stato rifiutato con successo',
    canceled_t: 'Ordine annullato',
    canceled_s: 'Il tuo ordine è stato annullato con successo'
  },
  alert_cart: {
    added_t: 'Carrello aggiornato',
    added_t: 'Prodotto aggiunto al carrello con successo',
    consumable_t: 'Aggiungi prima l\'articolo a noleggio',
    consumable_s: 'Questo prodotto può essere ordinato solo con un articolo a noleggio. Si prega di aggiungere un equipaggiamento per bambini da questo provider al carrello prima di aggiungere articoli di consumo.',
    multiple_t: 'Più providers',
    multiple_s: 'Ci sono articoli di un altro provider nel tuo carrello. Vuoi svuotare il carrello per aggiungere questo articolo?',
    multiple_c: 'Aggiungi elemento',
  },
  alert_expired: {
    cassa_t: 'Pagamento scaduto',
    cassa_s: 'Questo checkout è scaduto. Aggiungi nuovi prodotti al tuo carrello.',
  },

  //Errors
  error_invalid_email: 'Hai inserito un indirizzo email non valido. Si prega di riprovare',
  error_invalid_credentials: 'Credenziali non valide. Email e password non sono corrette. Si prega di riprovare ',
  error_invalid_fullname: 'Devi inserire il tuo nome completo con almeno 6 caratteri.',
  error_invalid_password: 'Devi inserire una Password di almeno 6 caratteri.',
  error_invalid_confirmation: 'La conferma della password non corrisponde alla password inserita.',

  error_already_exists: 'L\'account esiste già per questa email.',

  error_missing_password: 'Non hai inserito la tua password',
  error_missing_fullname: 'Non hai inserito il tuo nome completo',
  error_missing_phone: "Non hai inserito il tuo numero di telefono",

  error_invalid_address: 'Devi inserire un indirizzo valido con numero civico, via e città.',
  error_invalid_store_settings: 'È necessario completare l\'elenco di controllo per accedere alle impostazioni del negozio',
  error_invalid_card: 'La carta inserita non è valida. Si prega di riprovare',

  error_not_authorized: {
    t: 'Non autorizzato',
    s: ' Non sei autorizzato a visitare questa pagina'
  },

  //Cliente di destinazione
  cust_intro: {
    a: 'Noleggio attrezzatura per bambini',
    b: 'è a portata di clic',
    p: 'Trova tutto ciò che renderà più facile viaggiare con i tuoi piccoli. Sempre e ovunque, con i nostri providers locali!'
  },
  cust_options: [
    {
      t: 'Ovunque e quando vuoi',
      i: '/assets/img/landing/time.svg'
    },
    {
      t: 'Molte opzioni di consegna, compresi gli aeroporti',
      i: '/assets/img/landing/delivery.svg'
    },
    {
      t: 'Prodotti sicuri e puliti',
      i: '/assets/img/landing/clean.svg'
    },
    {
      t: 'Assistenza clienti premium',
      i: '/assets/img/landing/support.svg'
    }
  ],

  cust_how: {
    t: 'Come funziona',
    a: 'Scegli l\'attrezzatura per bambini di cui hai bisogno',
    b: 'Ritira o ricevi l\'attrezzatura per bambini dal provider',
    c: "Restituisci l'attrezzatura alla fine del tuo viaggio"
  },
  cust_reviews: {
    t: 'Più di 3.000 clienti soddisfatti',
    o: [
      {
        i: 'https://user-images.trustpilot.com/6242c03147517c0013ce5370/73x73.png',
        n: 'Claudio Aruanno',
        a: 'Solo 1 parola PERFETTO!',
        b: 'Stavamo cercando una culla per nostro figlio e ci siamo imbattuti in questa azienda. Ci hanno aiutato molto e il contatto è molto buono così come il servizio! Non avevamo prenotato un materasso e ne avevano uno in più per noi. Super bello e dolce. Grazie per questo. Mio figlio ha dormito meravigliosamente, perché la qualità era super!',
        c: "Siamo dei Paesi Bassi e lo consigliamo vivamente per la prossima volta!"
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Cinzia González',
        a: 'Noleggio passeggino.',
        b: 'Ho avuto un\'esperienza eccellente dall\'inizio alla fine della mia prenotazione. Mi hanno contattato qualche giorno prima del viaggio per organizzare l\'incontro, il giorno in cui ci siamo incontrati era puntuale e la comunicazione è stata facile e veloce. Il passeggino era in ottime condizioni. Al momento della restituzione del passeggino, sono stati molto flessibili nell\'orario e nel luogo per ritirarlo. Sono molto contento di aver scelto questa azienda, la consiglierò sicuramente.',
        c: ''
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Satu Malmi',
        a: '',
        b: 'Ottima qualità con la merce noleggiata (passeggino e seggiolino per auto). Anche la consegna e il reso sono avvenuti in modo fluido e flessibile. Consigliato per altre famiglie che vogliono assicurarsi che i propri bambini viaggino in sicurezza e non vogliono far volare le proprie cose all\'estero 🙂',
        c: ''
      }
    ]
  },

  cust_cities: 'Principali città disponibili',
  cust_clean: {
    a: 'Pulito',
    b: '& Sicuro',
    p: 'La pulizia e la sicurezza sono state le nostre principali priorità sin dall\'inizio. Prestiamo attenzione ai minimi dettagli affinché tu abbia un\'esperienza di noleggio perfetta. I prodotti che risultano non conformi ai nostri standard di igiene e sicurezza vengono rimossi dal nostro sito e non possono essere nuovamente pubblicati.'
  },
  cust_travel: {
    a: 'Viaggiare senza',
    b: 'borse pesanti',
    p: 'Trascorri una vacanza senza stress, dove ti godrai il viaggio e creerai ricordi con la tua famiglia senza trascinarti dietro un sacco di cose. Puoi trovare l\'attrezzatura per bambini di cui hai bisogno dove e quando vuoi. Con dogu, il tuo assistente di viaggio preferito, non devi più preoccuparti dei tuoi bagagli pesanti.'
  },
  cust_parents: {
    a: 'Condivisione ',
    b: 'genitori a genitori',
    p: 'La maggior parte dei nostri providers sono genitori proprio come te, e anche i punti a cui tieni per il tuo piccolo sono importanti per i nostri providers. Ecco perché sappiamo che troverai tutto ciò di cui hai bisogno per vivere un\'esperienza di viaggio senza stress con il tuo piccolo. Siamo felici di trovare soluzioni per te con la nostra vasta gamma di prodotti, a partire da prezzi di noleggio convenienti!'
  },
  cust_app: {
    a: 'Tutto in un unico posto',
    b: 'con l\'app dogu.',
    o: [
      'Ottieni tutto ciò di cui hai bisogno in un unico posto.',
      'Resta in contatto con i nostri providers.',
      'Gestisci il tuo ordine di noleggio.',
      'Connettiti rapidamente al nostro team di supporto.',
      'Approfitta delle promozioni esclusive solo per le app.'
    ]
  },
  cust_faq: [
    {
      t: 'Come funziona il servizio di noleggio dogu?',
      p: 'Dopo aver selezionato l\'attrezzatura per bambini da noleggiare e aver inviato l\'ordine, il Provider riceve una notifica. Possono accettare o rifiutare il tuo ordine di noleggio. Se accettato, riceverai una notifica e ti verrà addebitato. Il Provider coordinerà la consegna o il ritiro con te. Una volta restituito l\'articolo, tramite ritiro o riconsegna, la prenotazione è completa.'
    },
    {
      t: "Quando mi viene addebitato?",
      p: 'La tua carta di credito verrà addebitata una volta che la prenotazione è stata approvata dal Provider. Quando invii il tuo ordine di noleggio, viene applicata una sospensione sul tuo account ma viene automaticamente rilasciata se rifiutata.'
    },
    {
      t: "Posso annullare e ottenere un rimborso?",
      p: 'Puoi annullare il tuo ordine in qualsiasi momento fino a 48 ore prima del periodo di prenotazione per ottenere un rimborso completo meno le spese di servizio. Successivamente, non hai più diritto a un rimborso.'
    },
    {
      t: 'Posso modificare il mio ordine di noleggio?',
      p: 'Sì, il tuo ordine può essere modificato in qualsiasi momento e avrai bisogno dell\'approvazione del tuo provider per accettare le modifiche.'
    },
    {
      t: "L'attrezzatura per bambini è igienizzata?",
      p: 'Si consiglia di pulire e igienizzare gli indumenti per bambini subito dopo la fine della prenotazione. L\'abbigliamento per bambini dovrebbe essere pulito con prodotti sicuri che uccidono batteri e virus.'
    },

  ],

  //Landing Provider
  pro_intro: {
    a: 'Diventa un provider dogu',
    b: "Lancia il tuo negozio di noleggio di articoli per bambini"
  },
  pro_start: {
    a: 'Inizia a noleggiare in pochi minuti:',
    b: 'Nome negozio',
    c: 'Posizione e consegna',
    d: 'Prodotti'
  },
  pro_how: {
    t: 'Come posso iniziare?',
    p: 'Diventare un provider dogu e lanciare il tuo negozio di noleggio di articoli per bambini è facile. Solo 3 passaggi per iniziare! Crea il tuo negozio e aggiungi i tuoi primi prodotti per iniziare a ricevere ordini! Ti aiuteremo lungo la strada.'
  },
  pro_earning: {
    t: 'Guadagna fino a € 1.500/mese',
    p: 'Stai cercando un\'opportunità per aumentare il tuo reddito mensile? Hai una scorta di attrezzatura per bambini che non stai usando? Ora puoi guadagnare fino a € 1.500 al mese affittando la tua attrezzatura per bambini a famiglie in viaggio! Questo è un ottimo modo per guadagnare qualche soldo in più aiutando le famiglie a viaggiare con bambini piccoli. Che si tratti di un passeggino, di un seggiolino per auto o di una culla, gli accessori per bambini sono sempre necessari quando le famiglie sono lontane da casa.',
  },
  pro_reviews: {
    i: '/assets/img/avatar.svg',
    n: 'Vincinzo',
    a: 'provider dogu',
    b: 'Sono Vincenzo, padre di due bellissime bambine, sono appassionato di viaggi e ricordo ancora i progetti e i programmi con mia moglie quando stava per arrivare il primo figlio, riguardo alla necessità di cambiare auto per avere più spazio per stivare i bagagli della famiglia che cresce! Quando siamo diventati 4 siamo quasi entrati in crisi! Ho sposato il progetto dogu, credendo che sia un ottimo modo per non privarsi del piacere di un lungo viaggio, senza porsi il problema di trasportare tutta quell\'attrezzatura.'
  },
  pro_options: [
    {
      t: 'Imposta il tuo programma',
      i: '/assets/img/landing/schedule.svg'
    },
    {
      t: 'Inizia a guadagnare ora',
      i: '/assets/img/landing/earn.svg'
    },
    {
      t: 'Incontra nuove persone',
      i: '/assets/img/landing/community.svg'
    },
    {
      t: 'Supporto da dogu',
      i: '/assets/img/premium-support.svg'
    }
  ],


  pro_faq: [
    {
      t: 'Cosa significa essere un provider di dogu?',
      p: 'Da passeggini e seggiolini per auto a box e seggioloni, puoi fornire alle famiglie tutto ciò di cui hanno bisogno per rendere il loro viaggio più confortevole e piacevole. Eliminando la necessità per le famiglie di portare la propria attrezzatura, puoi anche aiutarle a risparmiare sui costi dei bagagli e ridurre lo stress di viaggiare con un bambino. Con il tuo servizio affidabile e conveniente, le famiglie possono concentrarsi sulla creazione di ricordi durante i loro viaggi senza preoccuparsi della logistica dell\'attrezzatura per bambini.'
    },
    {
      t: "C'è una tassa per iniziare con dogu?",
      p: 'Iniziare la tua avventura come dogu Provider è completamente gratuito. Una volta ricevuto un ordine, dedurremo una piccola quota dal totale, che verrà utilizzata per gestire Babobo e trovare clienti per supportarti.'
    },
    {
      t: 'Devo comprare l\'attrezzatura per iniziare?',
      p: 'Per iniziare, usa la tua attrezzatura per bambini esistente per iniziare come provider su dogu! In effetti, ti consigliamo se vuoi noleggiare più attrezzatura per bambini, comprala di seconda mano. Il nostro unico requisito per la tua attrezzatura per bambini è che sia in buone condizioni e pulita.'
    },
    {
      t: 'Dove consegno l\'attrezzatura per bambini?',
      p: 'La maggior parte dei nostri Providers consegna articoli per bambini in tutte le rispettive città. Puoi scegliere famosi punti di riferimento nelle vicinanze e centri di trasporto come stazioni ferroviarie e aeroporti. Puoi anche offrire ai clienti la possibilità di ritirare l\'abbigliamento per bambini direttamente da te!'
    },
    {
      t: 'Quanta attrezzatura per bambini mi serve per iniziare?',
      p: 'Hai solo bisogno di un articolo per iniziare come provider di dogu. Ti consigliamo di iniziare con un passeggino, una culla o un seggiolino per auto, poiché sono i nostri articoli a noleggio più popolari. Una volta che la tua attività decolla, puoi anche prendere in considerazione l\'aggiunta di altri articoli, come seggioloni, box e marsupi, al tuo inventario. Offrendo una gamma più ampia di prodotti, puoi soddisfare le esigenze di una base di clienti più ampia e aumentare le tue possibilità di attrarre ordini più grandi.'
    },
    {
      t: 'Quanto posso guadagnare come provider di dogu?',
      p: 'Puoi guadagnare fino a 1.500€ al mese come Provider dogu. Quanto guadagni dipenderà da dove ti trovi e sei disposto a consegnare, nonché da quanti articoli hai in affitto. I nostri più ricchi hanno sempre passeggini, culle e seggiolini per auto a noleggio.'
    },
    {
      t: 'Come posso iniziare?',
      p: 'Per iniziare, è sufficiente compilare la nostra domanda in 3 passaggi. Ciò include la configurazione del tuo negozio online e l\'elenco dei tuoi primi prodotti. Una volta completato, puoi pubblicare il tuo negozio e iniziare ad accettare ordini. È facile come sembra!'
    }
  ],

  //Accedi / Registrati / Recupera
  auth_login: {
    a: 'Accedi',
    b: 'Non hai un account? ',
    c: 'Hai dimenticato la password?'
  },
  auth_signup: {
    a: 'Iscriviti',
    b: 'Hai già un account? ',
    c: {
      a: 'Cliccando su iscriviti, accetti i nostri',
      b: 'Termini e condizioni',
      c: 'e nostro',
      d: 'Informativa sulla privacy',
    }
  },
  auth_recovery: {
    a: 'Hai dimenticato la password?',
    b: 'Inserisci la tua email qui sotto e ti invieremo le istruzioni per reimpostare la tua password.',
  },

  //Di
  about_page: {
    a: "dogu è un servizio di noleggio di articoli per bambini che offre una varietà di articoli per bambini attraverso gente del posto per famiglie in viaggio in oltre 250 città.",
    b: 'dogu è stato avviato nel 2018 da Duygu Sefa, fondatore e provider di dogu. In quanto madre di due figli, Duygu ha pensato che dovesse esserci un modo più semplice che portare tutta l\'attrezzatura per neonati necessaria quando si viaggia con i bambini.',
    c: 'Dopo numerosi viaggi con la mia bambina, mi sono resa conto che viaggiare con i più piccoli può diventare molto stressante, quindi mi sono chiesta perché non aiutare le famiglie a viaggiare libere da ogni attrezzatura?',
    d: 'Con una piena comprensione di ciò che le famiglie in viaggio potrebbero dover affrontare, nel 2019 è stato lanciato il mercato del noleggio di attrezzature per bambini dogu. Fin dal primo giorno le famiglie hanno continuato a fidarsi ea rendere dogu indispensabile durante i loro viaggi.',
    e: 'Oggi, dogu è una grande famiglia non solo di famiglie in viaggio alla ricerca di articoli per bambini, ma anche di providers di tutto il mondo che noleggiano articoli per bambini. I providers di dogu sono gente del posto che vuole rendere il viaggio delle famiglie un\'avventura ancora più straordinaria e guadagnare un reddito extra mensile mentre lo fa.'
  },

  //Come funziona
  how_page: {
    t: 'Viaggiare con i più piccoli è più facile che mai.',
    a: {
      t: 'Scegli l\'attrezzatura per bambini di cui hai bisogno',
      a: {
        t: 'Scegli la tua posizione',
        s: 'Scegli la destinazione in cui ritirare il tuo ordine selezionando la città che visiterai.'
      },
      b: {
        t: 'Seleziona le tue date',
        s: 'Aggiungi le date del tuo viaggio in base al tuo viaggio. Sentiti libero di contattare il Provider se hai domande sulla disponibilità.'
      },
      c: {
        t: 'Scegli l\'attrezzatura per bambini',
        s: "Puoi trovare seggiolini per auto di marca premium, qualsiasi tipo di passeggino, culle portatili."
      },
    },
    b: {
      t: 'Ricevi l\'attrezzatura per bambini dal Provider dove ne hai bisogno.',
      s: 'Abbiamo un sacco di opzioni di consegna!',
      a: {
        t: "Direttamente agli aeroporti."
      },
      b: {
        t: 'Direttamente agli hotel e agli affitti nei centri urbani.'
      },
      c: {
        t: "Incontri nei punti di interesse.",
        s: 'come le stazioni centrali dei treni e degli autobus o punti di riferimento della città. Oppure, ritira l\'attrezzatura gratuitamente presso il Provider \' s.'
      },
      d: {
        a: 'Il ritiro è gratuito presso la sede del Provider . ',
        b: 'Per altri luoghi di consegna, i providers in genere addebitano le spese di consegna. Vedrai le spese di spedizione al momento del check-out.',
        c: 'Una volta prenotato il noleggio, il Provider accetterà o rifiuterà l\'ordine entro 24 ore. Riceverai una mail con la conferma. Verrà applicata una sospensione sul tuo account fino all\'accettazione dell\'ordine, dopodiché ti verrà addebitato.',
        d: 'dogu addebita solo quando il tuo ordine viene accettato dal Provider.'
      }

    },
    c: {
      t: 'Restituisci l\'attrezzatura per bambini alla fine del viaggio',
      s: 'A seconda dell\'opzione scelta, incontra in tempo il provider dogu nel punto di ritiro o attendi che ritirino l\'attrezzatura per bambini. Assicurati di restituire i prodotti nelle stesse condizioni. E questo è tutto!'
    },
    d: {
      a: 'Ovunque tu vada',
      b: 'l\'attrezzatura per bambini ti incontrerà lì!',
      c: "La salute e la sicurezza sono la nostra priorità",
      d: 'Opzioni flessibili di ritiro e restituzione',
      e: 'Assistenza ogni volta che ne hai bisogno'
    }

  },

  //Fiducia e sicurezza
  trust_page: {
    a: {
      t: 'Fiducia e sicurezza dogu',
      s: 'La fiducia e la sicurezza sono state una priorità dall\'inizio fino ad ora. Prestiamo attenzione ai minimi dettagli affinché tu possa vivere un\'esperienza di noleggio perfetta.'
    },
    b: {
      t: 'Fiducia:',
      a: 'Abbiamo più di 2.000 clienti soddisfatti.',
      b: 'Non temere un cambio di programma di viaggio, puoi cancellare il tuo ordine con un rimborso completo fino a 48 ore prima del giorno del noleggio!',
      c: 'Se non sei soddisfatto dell\'attrezzatura per bambini che noleggi, la sostituiremo con un\'alternativa entro 24 ore. Se non riusciamo a trovare un\'opzione alternativa, ti rimborseremo i tuoi soldi!'
    },
    c: {
      t: 'Sicurezza e igiene:',
      s: 'I nostri providers devono soddisfare i nostri standard di pulizia di sicurezza e igiene. Gli articoli per bambini a noleggio vengono accuratamente puliti dai Providers dopo ogni noleggio. Tutti i providers dogu e i loro prodotti passano attraverso un processo di approvazione. E le tue recensioni sono molto preziose per noi. Prendiamo in considerazione le tue recensioni e intraprendiamo le azioni necessarie, quando necessario.'
    },
    d: {
      t: 'Protezione della prenotazione:',
      a: 'Le tue informazioni personali non saranno vendute o distribuite in alcun modo. La tua privacy è importante per noi. Ti chiediamo di fornire il tuo nome completo, indirizzo e-mail, numero di telefono e informazioni di pagamento attraverso il nostro sito.',
      b: "Facciamo del nostro meglio per assicurarti un'esperienza di prenotazione impeccabile, ma se un provider dogu deve annullare all'ultimo minuto, faremo del nostro meglio per aiutarti a trovare un sostituto."
    },
    e: {
      t: 'Non vedo l\'ora di incontrarti!',
      s: 'Se hai bisogno di qualcosa durante la tua esperienza di noleggio, non esitare a contattare il nostro team di esperti. Puoi raggiungerci in qualsiasi momento tramite live chat sul nostro sito web o app, oppure inviando un\'e-mail all\'indirizzo info@dogu.com e saremo felici di aiutarti.'
    }

  },

  //Scaricamento
  download_page: {
    t: 'Scarica l\'app',
    s: 'Gestisci facilmente il tuo noleggio con l\'app dogu. Scaricalo e inizia a usarlo ora!',
    a: "Tutto in un unico posto con l'app dogu.",
    b: 'Sviluppato pensando alle tue esigenze.'
  },

  //Posizioni
  locations_page: {
    t: 'Noleggia attrezzatura per bambini',
    s: 'Noleggia seggiolini auto, passeggini, culle e tanti altri prodotti. I nostri providers consegnano dove e quando vuoi. Viaggia leggero e sicuro con i tuoi bambini.',
  },

  //Flusso clienti
  cust_flow: {
    a: {
      t: "Quando vorresti affittare?",
      s: 'Si prega di selezionare le date di noleggio (ritiro e riconsegna) in'
    },
    b: {
      t: 'Ritiro e riconsegna',
      s: "Dove vuoi essere consegnato - ritiro e riconsegna"
    },
    c: {
      t: 'Di che attrezzatura hai bisogno?',
      s: 'Seleziona le categorie di attrezzatura e l\'età del tuo bambino'
    }
  },

  //Flusso provider
  pro_flow: {
    a: {
      t: 'Informazioni di base sul tuo negozio.',
      s: 'Il tuo numero di contatto sarà condiviso con il cliente solo quando accetti la sua richiesta d\'ordine.'
    },
    b: {
      t: 'Posizione e consegna.',
      s: "Questo aiuterà i clienti a selezionare il tuo negozio migliore per la loro posizione"
    },
    c: {
      t: 'Eccezionale! Il tuo primo prodotto è finito.',
      s: 'Aggiungi altri prodotti qui per configurare rapidamente il negozio.'
    },
  },

  pro_check: {
    t: 'Grazie per aver creato il tuo profilo provider. Si prega di seguire questi passaggi per iniziare a noleggiare.',
    a: 'Carica la tua immagine del profilo',
    b: 'Imposta le tariffe di consegna e ritiro',
    c: 'Imposta l\'indirizzo del tuo negozio ',
    d: 'Carica prodotti',
    e: 'Aggiungi informazioni di contatto',
    z: 'Il tuo profilo è in fase di verifica. Ti invieremo un\'e-mail entro 24 ore'
  },

  wd_list_months: [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'Agosto',
    'settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],

  wd_how_guide: {
    a: 'Prenota il noleggio dell\'attrezzatura per bambini',
    b: "Ricevi gli articoli per bambini dal tuo fornitore dove vuoi",
    c: 'Goditi il viaggio con il tuo piccolo',
    d: "Restituisci l'attrezzatura per bambini alla fine del viaggio",
    cta: 'Ho capito'
  },

  wd_list_schedules: {
    t: 'Tempi di ritiro e consegna',
    s: 'Controlla la tua disponibilità settimanale per il servizio di consegna e ritiro da casa.',
    home: 'Ritiro da casa',
    service: 'Servizio di consegna',
    a: 'lunedì',
    b: 'martedì',
    c: 'mercoledì',
    d: 'giovedì',
    e: 'venerdì',
    f: 'sabato',
    g: 'domenica'
  },

  wd_list_payout: {
    t: 'Opzioni di pagamento',
    s: 'Aggiungi le tue informazioni di pagamento per ricevere i guadagni dei tuoi ordini.',
    a: "Nome della banca",
    b: "Numero di previdenza sociale",
    c: 'Paese',
    d: 'Tipo',
    e: 'Bonifico bancario',
    f: 'Numero di conto',
    g: 'Numero di instradamento',
    connected: 'Connesso',
    disconnect: 'Disconnetti'

  },

  wd_list_earnings: {
    earn_baby: 'Guadagna e spendi BabyCoin?',
    ref_code: 'Il tuo codice di riferimento',
    recent: 'Transazioni recenti',
    ask_this: 'Cos\'è questo?',
    learn_how: 'Scopri come'
  },

  wd_analytics: 'Analitica',
  wd_pending_rate: "Tasso in sospeso",
  wd_acceptance_rate: 'Tasso di accettazione',
  wd_rejection_rate: "Tasso di rifiuto",
  wd_score: 'Punteggio',
  wd_performance: 'Prestazioni',
  wd_total_customers: 'Totale clienti',
  wd_total_products: 'Prodotti totali',
  wd_total_days: 'Giorni totali',
  wd_aov: 'Valore medio dell\'ordine',
  wd_total_earnings: 'Guadagni totali',
  wd_total_to_earn: 'Totale da guadagnare',
  wd_total_lost: 'Totale perso',

  wd_list_orders: [
    'Per questo ordine ti verranno addebitati {{TOTAL}} in caso di cancellazione.',
    'Per questo ordine ti verranno addebitati {{TOTAL}} in caso di cancellazione.',
    'Per questo ordine non ti verrà addebitato alcun importo in caso di cancellazione.',
    'Se il tuo ordine è stato accettato dal fornitore:',
    'Gli ordini cancellati prima delle ultime 48 ore del primo giorno di noleggio vengono rimborsati ad eccezione della commissione dogu',
    'Gli ordini cancellati nelle ultime 48 ore prima del primo giorno di noleggio non sono rimborsabili',
    'Le cancellazioni non possono essere rimborsate se il primo giorno di noleggio è entro 48 ore.'
  ]



};

export default TRANSLATER
