import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

import SRC_LIKE from '../../assets/img/like.svg';
import SRC_LIKED from '../../assets/img/like-full.svg';

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export default function Follow() {
    const [isFollowing, setIsFollowing] = useState(false);

    return (
        <>
            {isFollowing
                ?
                <motion.button 
                    onClick={() => setIsFollowing(false)}
                    whileTap={{ scale: 1.1 }} className='px-4 py-2 w-[94px] bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>
                    Following
                </motion.button>
                :
                <motion.button
                    onClick={() => setIsFollowing(true)}
                    whileTap={{ scale: 1.1 }} className='px-4 py-2 w-[94px] bg-sky-500 text-white text-sm rounded-lg'>
                    Follow
                </motion.button>

            }
        </>
    );
}

