
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CLOSE from '../assets/img/close.svg';
import SRC_COCO from '../assets/img/coco.jpeg';


import { HeaderCheckout } from './Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from './ui/Forms';
import Switch from './ui/Switch';
import { Expandable } from './ui/Expandable';
import Chip from './ui/Chip';

import SearchPeople from './SearchPeople';



export function AddReview() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#0EA5E9',
        },
        '& .MuiRating-iconHover': {
            color: '#0EA5E9',
        },
    });
    return (
        <>
            <motion.div layout
                initial={{ opacity: 0, y: "20%" }}
                animate={{ opacity: 100, y: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[999999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Add review" link="/marketplace" />

                <div className='w-full h-full overflow-auto no-scrollbar'>

                    <div className='w-full flex flex-col items-center mb-3 text-sm px-global py-8 relative z-20'>
                        <img className='size-32 rounded-full object-cover border-4 border-white dark:border-zinc-800' src={SRC_COCO} />
                        <div className='flex flex-col items-center'>
                            <div className='text-xl font-bold'>Coco Ho</div>
                            <div className='text-base'>Siargao Island, Philippines</div>

                        </div>
                    </div>

                    <div className='w-full flex items-center justify-center'>
                        <StyledRating name="half-rating" defaultValue={0} max={5} min={1} size='large' />
                    </div>
                    <div className='p-global mt-8'>
                        <TextField label="How was your experience?" id="how" />
                    </div>
                </div>

                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <Link to="/trip/duration" className='bg-sky-500 text-center text-sm px-6 py-3 rounded-md text-white w-full'>Submit</Link>
                </div>


            </motion.div >


        </>

    )
}

