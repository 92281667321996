
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_ADD from '../../assets/img/add.svg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_BOARDTWO from '../../assets/img/board-2.png';
import SRC_BOARDTHREE from '../../assets/img/board-3.png';

import SRC_COCO from '../../assets/img/coco.jpeg';

import { InputField } from '../ui/Forms';

import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';


export function Services() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your services" hasIcon="true" icon={SRC_ADD} link="/profile/add-service" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Service title="Surf training for beginners" price="$50/hour" />
                        <Service title="Advanced surf trainig" price="$50/hour" />
                        <Service title="Video consultation" price="$50/hour" />
                        <Service title="Surf workout" price="$50/hour" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function Service(props) {
    const { title, price } = props;
    return (
        <motion.div className='w-full p-6 my-3 rounded-xl flex items-center relative bg-slate-100 dark:bg-zinc-700'>
            <div className='w-full'>
                <h2 className='text-lg font-semibold'>{title}</h2>
                <p className='text-sm text-slate-500 dark:text-zinc-400'>
                    Focused for itermediate surfers who want to take their surfing to next level
                </p>
                <div className=' text-sm mr-auto my-3'>{price}</div>
                <div className='w-full flex items-center gap-x-2'>
                    <Link to="/profile/add-service" className='px-4 flex py-2 bg-sky-500 text-white text-sm rounded-lg'>Edit</Link>
                    <Link to="/service-detail" className='px-4 block py-2 bg-slate-200 dark:bg-zinc-700 dark:text-white text-sm rounded-lg'>View</Link>
                </div>
            </div>

        </motion.div>
    )
}








