
import { useState } from 'react'
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"
import { Link } from "react-router-dom";

import SRC_STAR from '../../assets/img/star.svg';




const FollowCard = (props) => {
    const { img, name } = props;

    return (

        <>
            <motion.div
                whileHover={{ scale: 0.9 }}
                layout
                className='snap-center w-[170px]  shadow-sm  bg-white dark:bg-zinc-800 p-2 rounded-xl flex-shrink-0 relative'>
                <div className='flex items-center snap-start flex-col relative justify-center w-full h-full  flex-shrink-0 rounded-lg'>
                    <div className='h-full flex flex-col items-center justify-center'>
                        <img className='size-20 rounded-full object-cover mb-2 mt-6' src={img} />
                        <h3 className='text-sm font-semibold text-center h-[40px] mb-2'>{name}</h3>
                    </div>
                    <motion.button
                        whileTap={{ scale: 0.95 }} className='px-4 py-2 mt-auto w-full bg-sky-500 text-white  text-sm rounded-lg relative z-20'>
                        Follow
                    </motion.button>
                    <Link className='absolute w-full h-full left-0 top-0' to="/surfer-profile"></Link>
                </div>

            </motion.div>
        </>
    )
}

export default FollowCard