
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll } from "framer-motion"
import { Link } from "react-router-dom";
import SRC_STAR from '../assets/img/star.svg';
import SRC_BAG from '../assets/img/bag.svg';
import SRC_COCO from '../assets/img/coco.jpeg';
import SRC_CHEV from '../assets/img/chevron-right.svg';

import SRC_BOARDONE from '../assets/img/board-1.png';
import SRC_BOARDTHREE from '../assets/img/board-3.png';

import StaticCard from "./ui/StaticCard";
import ProductCard from "./ui/ProductCard";
import SpotItem from "./Spots";

export function Home() {

  return (
    <>
      <motion.div layout
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 100, scale: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className='page-wrapper p-0 no-scrollbar'>

        {/*} <div className='flex flex-col w-full pt-global px-global'>
          <div className='flex items-center pb-1 mb-2'>
            <motion.h2 className='text-global'>Spots in your area</motion.h2>
            <Link to="/spots" className='text-sky-500 ml-auto'>View All</Link>
          </div>
          <SpotItem name="Cloud 9" size="6-7" />
          <SpotItem name="Pesangan" size="6-7" />
          <SpotItem name="Rock island" size="6-7" />
        </div>
  <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />*/}
        <div className='p-global w-full'>
          <motion.div 
          initial={{rotate:'15deg'}}
          animate={{rotate:0}}
          className='p-6 pr-4 relative rounded-2xl flex bg-gradient-to-t from-sky-400 to-sky-500 text-white shadow-xl'>
            <div>
              <div className='text-xl mb-1 font-semibold'>Plan your surf trip!</div>
              <div className='text-sm'>Find local instructor, gear for rent and best spots for your next destination.</div>
              <div className='flex gap-x-2 mt-3'>
                <Link to="/trip/location" className='px-3 py-2 text-sm bg-white rounded-lg text-sky-500 block'>Let's go!</Link>
                <button className='px-3 py-2 text-sm bg-sky-500 rounded-lg text-white block'>No thanks</button>
              </div>
            </div>
            <img src={SRC_CHEV} className='invert' />
          </motion.div>
        </div>
        <div className='flex items-center py-6 pb-1 mb-2 px-global'>
          <motion.h2 className='text-global'>Popular surf instructors</motion.h2>
          <Link to="/instructors" className='text-sky-500 ml-auto'>View All</Link>
        </div>
        <motion.div
          className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
          <StaticCard hasRating="true" name="Coco" img={SRC_COCO} />
          <StaticCard hasRating="true" name="Amanda" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
          <StaticCard hasRating="true" name="Jordy" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/143918363/photo/standing-tall.jpg?s=612x612&w=0&k=20&c=CmYEL5PCpEPRU8w1G3ppYPgBP9sUqApsC7Jtdenj3ek=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
          <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
        </motion.div>
        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />
        <div className='flex items-center pb-1 mb-2 px-global'>
          <motion.h2 className='text-global'>Looking for surf buddies</motion.h2>
          <Link className='text-sky-500 ml-auto'>View All</Link>
        </div>
        <motion.div
          className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
          <StaticCard name="Ashley" img="https://images.pexels.com/photos/2205647/pexels-photo-2205647.jpeg?auto=compress&cs=tinysrgb&w=800" />
          <StaticCard name="Jennifer" img="https://images.pexels.com/photos/3722176/pexels-photo-3722176.jpeg?auto=compress&cs=tinysrgb&w=800" />
          <StaticCard name="Rachel" img="https://images.pexels.com/photos/10043120/pexels-photo-10043120.jpeg?auto=compress&cs=tinysrgb&w=800" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/143918363/photo/standing-tall.jpg?s=612x612&w=0&k=20&c=CmYEL5PCpEPRU8w1G3ppYPgBP9sUqApsC7Jtdenj3ek=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
          <StaticCard name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
        </motion.div>
        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />
        <div className='flex items-center pb-1 mb-2 px-global'>
          <motion.h2 className='text-global'>Buy or rent</motion.h2>
          <Link to="/surf-shops" className='text-sky-500 ml-auto'>View All</Link>
        </div>
        <motion.div
          className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
          <ProductCard name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img={SRC_BOARDONE} />
          <ProductCard name="Mashup" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367" />
          <ProductCard name="Volcanic Too Fish" price="$500.00 USD" img={SRC_BOARDTHREE} />
          <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
          <ProductCard name="The Sweet Potato" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/seaside-324395_grande.jpg?v=1645727960" />
          <ProductCard name="Sci-Fi 2.0 Ibolic" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_deck_grande.png?v=1699917947" />
          <ProductCard name="Mashup" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367" />
          <ProductCard name="The Sweet Potato" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/seaside-324395_grande.jpg?v=1645727960" />
          <ProductCard name="Volcanic Too Fish" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/FRK_Plus_Deck_grande.png?v=1677109018" />
          <ProductCard name="Houdini" price="$500.00 USD" img="https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377" />
        </motion.div>
        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />

        <div className='flex items-center pb-1 mb-2 px-global'>
          <motion.h2 className='text-global'>Best snaps</motion.h2>
          <Link to="/photographers" className='text-sky-500 ml-auto'>View All</Link>
        </div>

        <motion.div
          className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">

          <div className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img src={SRC_BAG} className='absolute size-7 top-3 right-3 z-20' />
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://cloudfront-us-east-2.images.arcpublishing.com/reuters/WNXQ2BTOGNLZ3M2OOMQZAJLLIY.jpg' />
            </div>
            <div className='w-full flex items-center mt-3 text-sm'>
              <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/75.jpg' />
              <div>
                <strong>John Doe</strong>
                <div className='text-slate-500 text-xs'>Yesterday</div>
              </div>
              <button className='py-2 px-3 rounded-lg bg-sky-500 text-white text-xs ml-auto'>For Hire</button>
            </div>
          </div>

          <div className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfertoday.com/images/stories/teahupoo-facts.jpg' />
            </div>
            <div className='w-full flex items-center mt-3 text-sm'>
              <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
              <div>
                <strong>Bryan Whatever</strong>
                <div className='text-slate-500 text-xs'>Yesterday</div>
              </div>
              <button className='py-2 px-3 rounded-lg bg-sky-500 text-white text-xs ml-auto'>For Hire</button>
            </div>
          </div>

          <div className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img src={SRC_BAG} className='absolute size-7 top-3 right-3 z-20' />
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfertoday.com/images/stories/cloudbreak-tavarua.jpg' />
            </div>
            <div className='w-full flex items-center mt-3 text-sm'>
              <img className='size-10 rounded-full object-cover mr-2' src='https://mighty.tools/mockmind-api/content/human/68.jpg' />
              <div>
                <strong>Jessica Surfer</strong>
                <div className='text-slate-500 text-xs'>Yesterday</div>
              </div>
              <button className='py-2 px-3 rounded-lg bg-sky-500 text-white text-xs ml-auto'>For Hire</button>
            </div>
          </div>

        </motion.div>
        <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-8' />

        <div className='flex items-center pb-1 mb-2 px-global'>
          <motion.h2 className='text-global'>Upcoming events</motion.h2>
          <Link className='text-sky-500 ml-auto'>View All</Link>
        </div>

        <motion.div
          className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">

          <Link to="/profile/event-detail"  className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://travelingdutchies.com/wp-content/uploads/2017/03/philippines-surfclasses-siargao--790x593.jpg' />
            </div>
            <div className='w-full flex items-center mt-1 text-base'>
              <div>
                <strong>2 day surf trip to the north</strong>
                <div className='text-slate-500 text-xs'>General Luna, Philippines</div>
              </div>
            </div>
          </Link>

          <Link to="/profile/event-detail"  className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfertoday.com/images/stories/cloud-nine.jpg' />
            </div>
            <div className='w-full flex items-center mt-1 text-base'>
              <div>
                <strong>Surfing with legends</strong>
                <div className='text-slate-500 text-xs'>General Luna, Philippines</div>
              </div>
            </div>
          </Link>

          <Link to="/profile/event-detail"  className='w-3/4 flex-shrink-0 snap-center'>
            <div className='w-full relative before:pt-[80%] before:block before:w-full rounded-xl overflow-hidden'>
              <img className='w-full h-full absolute object-cover top-0 left-0' src='https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1d/3c/e0/a3/caption.jpg?w=600&h=600&s=1' />
            </div>
            <div className='w-full flex items-center mt-1 text-base'>
              <div>
                <strong>Quality Surfing Lessons in Siargao</strong>
                <div className='text-slate-500 text-xs'>General Luna, Philippines</div>
              </div>
            </div>
          </Link>

        </motion.div>

        <div className='h-2 w-full  my-8' />

      </motion.div>
    </>

  )
}








