import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"


import SRC_ADD from '../../assets/img/add.svg';
import SRC_VISA from '../../assets/img/visa.png';
import SRC_CASH from '../../assets/img/sale.svg';
import SRC_APPLE from '../../assets/img/apple.svg';

import SRC_BOARD from '../../assets/img/board-1.png';

import { SocialPost } from '../Social'

import { HeaderCheckout } from '../Header'
import { InputField } from '../ui/Forms';
import PaymentDrawer from '../PaymentDrawer';

import { CheckoutDrawerSummary } from './CheckoutDrawer';
import { InputGroup } from 'rsuite';

export function StepOne() {
    const [isPayment, setIsPayment] = useState(false);
    const handleOpenPayment = () => {
        setIsPayment(true);
    };
    const handleClosePayment = () => {
        setIsPayment(false);
    };

    return (
        <>
            <motion.div layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 100 }}
                transition={{ type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col  dark:bg-zinc-800 dark:text-white h-full w-full z-[999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Your Order" />
                <motion.div
                    initial={{ x: "100%", opacity: 100 }}
                    animate={{ x: 0, opacity: 100 }}
                    transition={{ type: "spring", damping: 60, stiffness: 700 }}
                    exit={{ opacity: 100 }}
                    className='w-full bg-white h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global'>
                        
                        <div className='text-center mb-8'>
                            <div className='text-2xl font-semibold'>Select payment method</div>
                            <div className=''>You will be charged after your session is complete</div>

                        </div>
                        <div className='mb-5'>
                            <InputField label="Discount code" id="discount" />
                        </div>
                        <Method title="Visa ending **** 2030" description="Expires 07/29" icon={SRC_VISA} />
                        <Method title="Apple pay" description="ID: 9284728" icon={SRC_APPLE} />
                        <Method title="Cash" description="Pay in person" icon={SRC_CASH} />
                        <div onClick={handleOpenPayment} className='p-3 flex items-center'>
                            <img src={SRC_ADD} className='size-7 mr-2' />
                            Add payment method
                        </div>
                    </div>


                </motion.div>

                <CheckoutDrawerSummary price="$50.00 USD" per="person" />



            </motion.div>
            <AnimatePresence>
                {isPayment && <PaymentDrawer onClose={handleClosePayment} />}
            </AnimatePresence>
        </>

    )
}

export function Method(props) {
    const { icon, title, description } = props;
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
        <motion.div whileTap={{ scale: 1.05 }} onClick={handleClick} className={`w-full p-3 text-left flex items-center rounded-2xl mb-2 border  dark:border-zinc-600 ${clicked ? 'border-transparent outline outline-1 bg-slate-100 outline-zinc-800 dark:outline-zinc-500' : 'border-slate-200'}`}>
            <div className="size-14 mr-4 flex items-center justify-center" >
                <img className='size-8 dark:invert object-contain' src={icon} />
            </div>
            <div>
                <div className='text-base font-semibold'>{title}</div>
                <div className='text-xs text-slate-400 dark:text-zinc-400'>{description}</div>
            </div>
        </motion.div>

    )
}







