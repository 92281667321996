
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_STAR from '../../assets/img/star.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import { HeaderCheckout } from '../Header';



export function Saved() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Favorites" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='p-global grid grid-cols-2 gap-3'>
                        <StaticCard hasRating="true" name="Amanda" img="https://media.istockphoto.com/id/1191593340/photo/young-woman-resting-on-her-surfboard-waiting-for-a-wave.jpg?s=612x612&w=0&k=20&c=JIVOdTqWgOqPSIj5w3R0ptzjG_kupuScJHGPhidnGQg=" />
                        <StaticCard hasRating="true" name="Jordy" img="https://media.istockphoto.com/id/1003589032/photo/portrait-of-confident-man-with-surfboard-at-beach.jpg?s=612x612&w=0&k=20&c=q_IApzbM5E9-lstBsi0vlZH407Qzzc0XbijBcaZg86s=" />
                        <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1480336073/photo/beautiful-woman-surfer-by-the-sea.jpg?s=612x612&w=0&k=20&c=ndadlW8k3bKivoqF3iO0d6pxEpvMO7xQEnRSC26JL-4=" />
                        <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/1201714837/photo/woman-carrying-over-her-head-a-surfboard.jpg?s=612x612&w=0&k=20&c=iYBT9vsMrnAde8jcA9fhsZh54unuqyBFq03uYzkD1II=" />
                        <StaticCard hasRating="true" name="Tomas" img="https://media.istockphoto.com/id/143918363/photo/standing-tall.jpg?s=612x612&w=0&k=20&c=CmYEL5PCpEPRU8w1G3ppYPgBP9sUqApsC7Jtdenj3ek=" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

const StaticCard = (props) => {
    const { img, name, hasRating, price } = props;

    return (

        <>
            <motion.div
                whileHover={{ scale: 0.9 }}
                layout
                className='expandable-card-wrapper snap-center w-full h-[260px] bg-slate-100 dark:bg-zinc-900 rounded-xl flex-shrink-0 relative'>
                <div className='overlay fixed bottom-0 h-[calc(100%-68px)] w-full left-0 bg-white'></div>
                <div to="/surfer-profile" className='expandable-card flex items-center justify-center w-full h-full flex-shrink-0 rounded-lg'>

                    <div className='expandable-card-inner overflow-x-hidden size-full bg-white rounded-xl overflow-auto'>

                        <div className={'expandable-card-image w-full h-full relative z-20 before:w-full before:block'}>
                            <img className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                            <div className='gradient-overlay size-full p-global flex items-end text-white absolute bg-gradient-to-t from-black/40 via-transparent to-black/20'>
                                <Link to="/surfer-profile" className='absolute w-full h-full left-0 top-0' />
                                <motion.button
                                    whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] absolute top-1 right-1 z-20 flex items-center justify-center rounded-lg z-20'>
                                    <motion.img whileTap={{ rotate: 45 }} className='invert' src={SRC_DELETE} />
                                </motion.button>
                                <div className='w-full'>
                                    <h3 className='card-title font-semibold text-base mb-px'>{name}</h3>
                                    <div className='flex w-full justify-between text-xs opacity-90'>
                                        <div>0.2km</div>
                                        {hasRating && (
                                            <div className='flex items-center gap-x-1'>
                                                4.9
                                                <img className='invert -translate-y-px' src={SRC_STAR} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </motion.div>
        </>
    )
}








