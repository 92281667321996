
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, Reorder, useDragControls } from "framer-motion"

import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_EDIT from '../assets/img/edit.svg';

import { HeaderEdit } from './Header';
import { InputField, TextField } from './ui/Forms';
import Switch from './ui/Switch';
import Chip from './ui/Chip';
import { Expandable } from './ui/Expandable';

export function EditProfile() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderEdit title="Edit Profile" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full flex items-center text-sm px-global py-4 pb-4 gap-x-4'>
                        <div className='size-32 relative flex-shrink-0'>
                            <img className='size-full rounded-full object-cover mr-5' src={SRC_TOMAS} />
                            <button className='size-10 absolute bottom-0 right-0 rounded-lg bg-slate-100 dark:bg-zinc-700 flex items-center justify-center'>
                                <img src={SRC_EDIT} className='dark:invert' />
                            </button>
                        </div>
                        <div className='h-32 w-full relative'>
                            <img className='w-full h-32 rounded-2xl object-cover' src="https://images.unsplash.com/photo-1482531007909-192ac913980a?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                            <button className='size-10 absolute bottom-0 right-0 rounded-lg bg-slate-100 dark:bg-zinc-700 flex items-center justify-center'>
                                <img src={SRC_EDIT} className='dark:invert' />
                            </button>
                        </div>

                    </div>
                    <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 my-4 mb-0' />
                    <div className='w-full px-global pb-24'>
                        <Expandable
                            title="Name & username"
                            closedtitle="Tomas Zeman"
                            opentitle="Your name will be used through the app"
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <InputField label="First Name" id="first" />
                                        <InputField label="Last Name" id="last" />
                                        <InputField label="Username" id="username" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                    </form>
                                </>
                            }
                        />
                        <Expandable
                            title="Location"
                            closedtitle="Siargao Island, Philippines"
                            opentitle="Where are you based?"
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <InputField label="Location" id="location" value="Siargao Island, Philippines" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                    </form>
                                </>
                            }
                        />
                        <Expandable
                            title="About you"
                            closedtitle="Hi, I'm professinal surfer from Bali..."
                            opentitle="Will appear in overview tab of your profile"
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <TextField label="About you" id="about" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                    </form>
                                </>
                            }
                        />
                        <Expandable
                            title="Links"
                            closedtitle="Website, Instagram, Facebook, YouTube"
                            opentitle="Links to other apps and website"
                            content={
                                <>
                                    <form className='w-full flex flex-col gap-4 mb-8 p-1'>
                                        <InputField label="Instagram" id="instagram" />
                                        <InputField label="YouTube" id="youtube" />
                                        <InputField label="TikTok" id="tiktok" />
                                        <InputField label="Website" id="website" />
                                        <button className='px-3 py-3 text-white rounded-lg bg-zinc-800 dark:bg-zinc-600 mr-auto text-sm'>Save changes</button>
                                    </form>
                                </>
                            }
                        />

                        <Expandable
                            title="Tabs"
                            closedtitle="Reorder and organize your tabs"
                            opentitle="Drag and drop the list to re-order your tabs"
                            content={
                                <>

                                    <Item />

                                </>
                            }
                        />

<Expandable
                            title="Your skill level"
                            closedtitle="Intermediate"
                            opentitle="Please select"
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Beginner" />
                                    <Chip title="Intermediate" />
                                    <Chip title="Advanced" />
                                    <Chip title="Expert" />
                                    
                                </div>
                            }
                        />
                        <Expandable
                            title="Stance"
                            closedtitle="Goofy"
                            opentitle="Please select"
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Regular" />
                                    <Chip title="Goofy" />
                                    
                                </div>
                            }
                        />
                        <Expandable
                            title="Style"
                            closedtitle="Shortboard"
                            opentitle="Please select"
                            content={
                                <div className='w-[calc(100%+15px)] overflow-auto mb-8 no-scrollbar flex gap-x-2 pl-6 *:flex-shrink-0'>
                                    <Chip title="Longboard" />
                                    <Chip title="Shortboard" />
                                    <Chip title="Foil" />
                                    <Chip title="Twin fin" />
                                    
                                </div>
                            }
                        />
                    </div>





                </div>


            </motion.div>
        </>

    )
}

const listItems = [
    { name: "Overview", id: 1 },
    { name: "Sessions", id: 2 },
    { name: "Services", id: 3 },
    { name: "Shop", id: 4 },
    { name: "Media", id: 5 },
    { name: "Reviews", id: 6 },
];


function Item({ value }) {

    const [items, setItems] = useState(listItems);
    return (
        <Reorder.Group values={items} onReorder={setItems} className="mb-8">
            {items.map((item) => (
                // Change the li to Reorder.Item and add value prop
                <Reorder.Item  key={item.id} value={item} className='w-full justify-between p-global py-5 bg-slate-100 rounded-xl flex items-center mb-2' >
                    {item.name}
                    <Switch />
                </Reorder.Item>
            ))}
        </Reorder.Group>
    );
}






