import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { ApertureOutline } from 'react-ionicons';

export const Logout = (props) => {
    const navigate = useNavigate(); // Initialize the navigate function

    const { onStartUser, USER } = props;

    useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('cart');
            localStorage.removeItem('ano');

            onStartUser('reset');

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGOUT',
                    data: null
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            // Redirect to Login
            navigate('/login'); // Redirect to the login page

        }, 1000)
    }, [onStartUser, navigate]);

    return (
        <>
            <div className="fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0">
                <div className='w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0'>
                    <div className="font-semibold tracking-wider text-xl">SURF<span className='text-sky-500'>YX</span></div>
                </div>
                <div className='w-full p-8 pt-0 h-full overflow-auto items-center justify-center flex flex-col'>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className='w-full mb-12 flex-col gap-3 items-center justify-center text-center'>
                        <h1 className='text-4xl font-semibold'>Bye {getFirstName(USER?.name)} ☀️</h1>
                        <h3 className='text-lg text-slate-400'>Enjoy and hope to see you soon 🌊🏄‍♂️</h3>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className='w-full gap-3 flex flex-col items-center'>
                        <Link className='w-[50px] h-[50px] flex items-center justify-center rounded-xl bg-sky-500 text-white shadow-2xl p-4 text-center mt-3 animate-spin'>
                            <ApertureOutline color={'#ffffff'} />
                        </Link>
                    </motion.div>
                </div>
            </div>
        </>
    );
}

function getFirstName(fullName) {
    if (!fullName) {
        return ''
    }
    // Split the full name into an array of its parts
    const nameParts = fullName.split(' ');

    // The first part of the name array is the first name
    const firstName = nameParts[0];

    return firstName;
}
