import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"

import SRC_GREAT from '../assets/img/great.svg';
import SRC_CHEV from '../assets/img/chevron-right.svg';
import SRC_SWELL from '../assets/img/swell.svg';
import SRC_WIND from '../assets/img/arrow.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_FORECAST from '../assets/img/forecast.png';
import SRC_CHAT from '../assets/img/messages.svg';

import { SocialPost } from './Social'

import { HeaderSpot } from './Header'

export function SpotDetail() {
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <HeaderSpot />
                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='px-global py-8'>
                        <motion.h3 className='text-3xl font-semibold'>Pesangan</motion.h3>
                        <div className='w-full flex items-center mt-2'>
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                            <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-10' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                            <span className='text-slate-500 dark:text-zinc-400 text-sm ml-4'>10 Sessions</span>

                            <motion.button
                                whileTap={{ scale: 0.95 }} className='px-3 flex-shrink-0 flex items-center py-2 ml-auto w-[94px] bg-sky-500 text-white text-sm rounded-lg'>
                                <img className='size-5 mr-2 invert' src={SRC_CHAT} />
                                Chat
                            </motion.button>

                        </div>
                    </div>
                    <AnimatedTabs />


                </div>



            </motion.div>
        </>

    )
}


function ForecastDay(props) {
    const { day } = props;
    return (
        <motion.div
            initial={{ x: 0 }}
            animate={{ x: [0, -50, 0] }}
            transition={{ delay: 0 }}
            className='w-full rounded-xl bg-slate-100 dark:bg-zinc-700 flex-shrink-0 snap-center'>
            <div className='flex justify-between mb-0 p-global pb-0'>
                <div className='font-bold'>{day}</div>
                <div className='flex items-center opacity-50 text-sm ml-auto mr-2'>
                    <img className='rotate-90' src={SRC_CHEV} />
                    10:12am
                </div>
                <div className='flex items-center opacity-50 text-sm'>
                    <img className='-rotate-90' src={SRC_CHEV} />
                    10:12am
                </div>
            </div>
            <ForecastRow time="6am" />
            <ForecastRow time="12pm" />
            <ForecastRow time="6pm" />
        </motion.div>
    )
}

const ForecastRow = (props) => {
    const { time } = props;
    return (
        <div className='flex items-center p-4 border-b border-zinc-200 last-of-type:border-none gap-x-6 relative'>
            <div className='text-xxs hidden absolute -left-1 top-8 origin-center -rotate-90'>{time}</div>
            <div className='px-4 py-2 rounded-xl bg-slate-200 font-semibold text-lg'>6-7</div>
            <div>
                <div className=' font-semibold'>7<span className='text-sm'>ft</span></div>
                <div className='text-xxs text-slate-400 dark:text-zinc-500'>Primary</div>
            </div>
            <div className='flex items-start'>
                <img className='mt-1 mr-1 size-4 -rotate-90' src={SRC_SWELL} />
                <div>
                    <div className='font-semibold'>11<span className='text-sm'>s</span></div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>ENE 61°</div>
                </div>
            </div>
            <div className='flex items-start'>
                <img className='mt-1 mr-1 size-4 -rotate-90' src={SRC_WIND} />
                <div>
                    <div className='font-semibold'>24<span className='text-sm'>kph</span></div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>ENE Wind</div>
                </div>
            </div>
            <img src={SRC_GREAT} />
        </div>
    )
}


const tabs = [
    {
        name: 'tab1',
        label: 'Sessions',
        render: () => {
            return <>

                <div className='w-full'>
                    <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="John Mark" session="Messy Pesangan" photo="https://media.istockphoto.com/id/922584892/photo/skilled-surfers-finished-morning-surfing-session-in-sydney.jpg?s=612x612&w=0&k=20&c=3UomlVf2w4uA20HLwrpB7OX13I8NclV04dETQel1_Z0=" avatar="https://mighty.tools/mockmind-api/content/human/71.jpg" />
                    <SocialPost name="Ahmed Allem" hasSlider="true" session="Barrels at Tuason" photo="https://cms.accuweather.com/wp-content/uploads/2018/06/surf-4.jpg" avatar="https://mighty.tools/mockmind-api/content/human/75.jpg" />
                    <SocialPost name="Tomas Zeman" session="Time to shine" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
                    <SocialPost name="Juan Rodi" hasSlider="true" session="Trip to north!" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar="https://mighty.tools/mockmind-api/content/human/70.jpg" />
                    <SocialPost name="Alfonso" session="Massive Stimpy's" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar="https://mighty.tools/mockmind-api/content/human/73.jpg" />
                </div>
            </>
        }
    },
    {
        name: 'tab2',
        label: 'Forecast',
        render: () => {
            return <>
                <div className='p-global flex justify-between'>
                    <div className='flex items-start'>

                        <div>
                            <div className='text-2xl font-semibold'>6-7<span className='text-sm'>ft</span></div>
                            <div className='text-sm text-slate-400 dark:text-zinc-500'>Primary swell</div>
                        </div>
                    </div>
                    <div className='flex items-start'>
                        <motion.img
                            initial={{ rotate: 0 }}
                            animate={{ rotate: 210 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className='mt-1 mr-1' src={SRC_SWELL} />
                        <div>
                            <div className='text-2xl font-semibold'>11<span className='text-sm'>s</span></div>
                            <div className='text-sm text-slate-400 dark:text-zinc-500'>ENE 61°</div>
                        </div>
                    </div>
                    <div className='flex items-start'>
                        <motion.img
                            initial={{ rotate: 0 }}
                            animate={{ rotate: -120 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className='mt-1 mr-1' src={SRC_WIND} />
                        <div>
                            <div className='text-2xl font-semibold'>24<span className='text-sm'>kph</span></div>
                            <div className='text-sm text-slate-400 dark:text-zinc-500'>ENE Wind</div>
                        </div>
                    </div>

                </div>
                <img src={SRC_FORECAST} />
                <motion.div className="w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x p-global snap-mandatory no-scrollbar mb-6">
                    <ForecastDay day="Today, 19/01" />
                    <ForecastDay day="Tomorrow, 20/01" />
                    <ForecastDay day="Saturday, 21/01" />
                    <ForecastDay day="Sunday, 22/01" />
                </motion.div>
            </>
        }
    },

    {
        name: 'tab3',
        label: 'Leaderboard',
        render: () => {
            return <>
                <div className='p-global'>
                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                        <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                        Tomas Zeman
                    </div>
                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                        <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/74.jpg' />
                        John Doe
                    </div>
                    <div className='flex items-center gap-x-2 py-3'>
                        <img className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/73.jpg' />
                        Jack Johnson
                    </div>
                </div>
            </>
        }
    },

];


function AnimatedTabs() {
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleClick = (e, tab) => {
        e.preventDefault()

        setActiveTab(tab)
    }

    const isSelected = tab => activeTab.name === tab.name

    return (
        <>
            <div className="w-full flex sticky top-0 bg-white dark:bg-zinc-800 z-50 gap-x-3 overflow-auto no-scrollbar border-b border-slate-200 dark:border-zinc-700">
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        className="text-black w-1/2 text-center"
                    >
                        <a className='px-4 py-3 pt-4 block ' href="#" onClick={e => handleClick(e, tab)}>
                            {tab.label}
                        </a>

                        {isSelected(tab) && (
                            <motion.div layoutId="indicator-2" className="h-1 bg-sky-500 rounded-full" />
                        )}
                    </div>
                ))}
            </div>

            <div className="w-full bg-white dark:bg-zinc-800 relative z-40 ">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab.name || "empty"}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        {activeTab && activeTab?.render()}
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    )
}