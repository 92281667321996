
import { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, ScrollRestoration } from "react-router-dom";


import { motion, useAnimation, stagger, AnimatePresence, isVisible, useScroll, useMotionValueEvent } from "framer-motion"


import Like from "./ui/Like";
import FollowCard from "./ui/FollowCard";

import SRC_MORE from '../assets/img/more.svg';
import SRC_STAR from '../assets/img/star.svg';
import SRC_LIKE from '../assets/img/like.svg';
import SRC_COMMENT from '../assets/img/comment.svg';
import SRC_SHARE from '../assets/img/share.svg';

import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_COCO from '../assets/img/coco.jpeg';
import SRC_JOHN from '../assets/img/john.jpeg';
import SRC_RAMZI from '../assets/img/ramzi.jpeg';


const AddPost = () => {
    const { scrollY } = useScroll();

    const [hidden, setHidden] = useState(false);

    useMotionValueEvent(scrollY, "change", (latest) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false)
        }
    });

    return (
        <motion.button
            variants={{
                visible: { y: 0 },
                hidden: { y: 100 },
            }}
            initial="hidden"
            transition={{ duration: 0.2, delay: 0.4 }}
            animate={hidden ? "hidden" : "visible"}
            className='size-14 bg-sky-500 shadow-xl ml-auto rounded-full overflow-hidden flex items-center justify-center text-white sticky bottom-2 right-2'>
            <span className='text-3xl font-light -translate-y-px'>+</span>
        </motion.button>
    )
}

export function Social() {


    return (
        <motion.div layout
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 100, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className='page-wrapper p-0 no-scrollbar'>
            <div className='w-full px-global pt-2 font-semibold bg-slate-100 dark:bg-zinc-700'>Suggested for you</div>
            <div className='w-full bg-slate-100 dark:bg-zinc-700 py-3 flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar'>
                <FollowCard name="Coco Ho" img={SRC_COCO} />
                <FollowCard name="Tomas Zeman" img={SRC_TOMAS} />
                <FollowCard name="Nathan Florence" img={SRC_NATHAN} />
                <FollowCard name="Ramzi Boukhiam" img={SRC_RAMZI} />
                <FollowCard name="John John Florence" img={SRC_JOHN} />
            </div>

            <SocialPost name="Tomas Zeman" hasSlider="true" session="Morning At Cloud 9" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
            <SocialPost name="John Mark" session="Messy Pesangan" photo="https://media.istockphoto.com/id/922584892/photo/skilled-surfers-finished-morning-surfing-session-in-sydney.jpg?s=612x612&w=0&k=20&c=3UomlVf2w4uA20HLwrpB7OX13I8NclV04dETQel1_Z0=" avatar="https://mighty.tools/mockmind-api/content/human/71.jpg" />
            <SocialPost name="Ahmed Allem" hasSlider="true" session="Barrels at Tuason" photo="https://cms.accuweather.com/wp-content/uploads/2018/06/surf-4.jpg" avatar="https://mighty.tools/mockmind-api/content/human/75.jpg" />
            <SocialPost name="Tomas Zeman" session="Time to shine" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar={SRC_TOMAS} />
            <SocialPost name="Juan Rodi" hasSlider="true" session="Trip to north!" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar="https://mighty.tools/mockmind-api/content/human/70.jpg" />
            <SocialPost name="Alfonso" session="Massive Stimpy's" photo="https://www.surfertoday.com/images/stories/teahupoo-facts.jpg" avatar="https://mighty.tools/mockmind-api/content/human/73.jpg" />
            {/*<AddPost />*/}
        </motion.div>
    );
}

function PostDrawer() {



    return (

        <div className='fixed bottom-0 left-0 w-full flex items-end justify-start h-full z-[9999]'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 100 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0 }}
                className='bg-black/30 dark:bg-black/70 fixed w-full h-full top-0 left-0 z-50' />
            <motion.div
                initial={{ y: "90%" }}
                animate={{ y: 0 }}
                transition={{ type: "spring", damping: 40, stiffness: 700 }}
                exit={{ y: "90%" }}
                className='w-full px-global pb-6 rounded-tl-xl rounded-tr-xl bg-white dark:bg-zinc-900 relative z-[9999]'>
                <div className='w-full flex items-center justify-center py-4 before:w-14 before:h-2 before:rounded-full before:bg-slate-200 dark:before:bg-zinc-800 before:block' />
                <div className='w-full py-3 border-b border-slate-200 dark:border-zinc-800'>Add to favorites</div>
                <div className='w-full py-3 border-b border-slate-200 dark:border-zinc-800'>Unfollow</div>
                <div className='w-full py-3 border-b border-slate-200 dark:border-zinc-800'>Edit post</div>
                <div className='w-full py-3'>Delete post</div>
            </motion.div>
        </div>

    )
}

export function SocialPost(props) {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    const { avatar, name, session, photo, hasSlider } = props;
    return (
        <>
            <div className='w-full border-b-8 border-slate-200 dark:border-zinc-900'>
                <div className='w-full flex items-center mb-3 text-sm p-global pb-0'>
                    <Link to="../surfer-profile">
                        <img className='size-10 rounded-full object-cover mr-3' src={avatar} />
                    </Link>
                    <div>
                        <Link to="../surfer-profile" className='font-bold'>{name}</Link>
                        <Link to="../spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                            Cloud 9
                            <div className='size-1 rounded-full bg-slate-200 dark:bg-zinc-600' />
                            4 Hours ago
                        </Link>
                    </div>
                    <motion.button onClick={handleClick}
                        whileTap={{ backgroundColor: "#F1F5F9" }} className='size-[30px] flex items-center justify-center rounded-lg ml-auto'>
                        <img className='dark:invert' src={SRC_MORE} />
                    </motion.button>
                </div>

                <Link to="../post-detail" className='w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar'>
                    <div className='w-full flex-shrink-0 snap-center relative before:pt-[66%] before:block before:w-full rounded-xl overflow-hidden'>
                        <img className='w-full h-full absolute object-cover top-0 left-0' src={photo} />
                    </div>
                    {hasSlider && (
                        <>
                            <div className='w-full flex-shrink-0 snap-center relative before:pt-[66.666%] before:block before:w-full rounded-xl overflow-hidden'>
                                <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfertoday.com/images/stories/teahupoo-meaning.jpg' />
                            </div>
                            <div className='w-full flex-shrink-0 snap-center relative before:pt-[66.666%] before:block before:w-full rounded-xl overflow-hidden'>
                                <img className='w-full h-full absolute object-cover top-0 left-0' src='https://www.surfholidays.com/assets/images/blog/2015-08-17-teahupoo-aerial.jpg' />
                            </div>
                        </>
                    )}
                </Link>
                <Link to="../post-detail" className='w-full block p-global pt-3 pb-0'>
                    <div className='w-full text-lg font-bold'>{session}</div>
                    <div className='text-xs text-slate-800 dark:text-zinc-400'>Glassy epic morning. As good as it gets.</div>

                    <div className='w-full flex items-center mt-6 gap-x-6 pb-4 border-b border-slate-200 dark:border-zinc-700'>
                        <div>
                            <div className='text-xs text-slate-500 dark:text-zinc-400'>Wave count</div>
                            <div className='text-xl'>14</div>
                        </div>
                        <div>
                            <div className='text-xs text-slate-500 dark:text-zinc-400'>Duration</div>
                            <div className='text-xl'>2:31</div>
                        </div>
                        <div className='ml-auto'>
                            <div className='text-xs text-slate-500 dark:text-zinc-400'>Conditions</div>
                            <div className='flex items-center gap-x-1 justify-end'>
                                4.9
                                <img className='size-4 -translate-y-px dark:invert' src={SRC_STAR} />
                            </div>
                        </div>
                    </div>
                </Link>
                <div className='flex gap-x-4 items-center p-global'>
                    <Like />
                    <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg'>
                        <img className='dark:invert' src={SRC_COMMENT} />
                    </motion.button>
                    <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg'>
                        <img className='dark:invert' src={SRC_SHARE} />
                    </motion.button>

                    <div className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center ml-auto'>
                        5 Likes
                        <div className='size-1 rounded-full bg-slate-200' />
                        2 Comments
                    </div>
                </div>
            </div>


            <AnimatePresence>
                {isShown &&
                    <>
                        <div className=' fixed w-full h-full top-0 left-0 z-[99999]' onClick={handleClick} />
                        <PostDrawer />
                    </>
                }
            </AnimatePresence>
        </>
    );
}