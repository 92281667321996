
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../../assets/img/calendar.svg';
import SRC_MORE from '../../assets/img/more.svg';
import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_CHAT from '../../assets/img/messages.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_SALE from '../../assets/img/sale.svg';

import SRC_ADD from '../../assets/img/add.svg';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function OrderDetail() {
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Order ID: 9284729" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full px-global pt-0'>
                        <div className="w-full flex flex-col py-8 pt-3 items-center last-of-type:border-none">
                            <img src={SRC_BOARD} className='size-32 mb-global border border-slate-200 dark:border-zinc-600 rounded-xl object-contain' />
                            <div className='text-center'>
                                <div className='font-bold text-lg'>Firewire Seaside 5'7</div>
                                <div className='text-slate-500 text-sm'>2.5 hours rental</div>
                            </div>
                        </div>
                        <div className='flex bg-slate-100 dark:bg-zinc-900 rounded-xl px-global w-full justify-between py-8 '>
                            <div className=''>
                                <div className='text-sm text-slate-400'>Rent Date</div>
                                <div className='font-bold flex items-center gap-x-1'><img className='size-4' src={SRC_CALENDAR} />Mar 19, 2024</div>
                            </div>
                            <div className=''>
                                <div className='text-sm text-slate-400'> Rent Time</div>
                                <div className='font-bold flex items-center gap-x-1'>
                                    <img className='size-4' src={SRC_TIME} />
                                    2:30pm - 4:30pm</div>
                            </div>
                        </div>
                        <div className='flex w-full py-8 border-b border-slate-200 dark:border-zinc-600'>
                            <img src={SRC_COCO} className='size-12 mr-global border border-slate-200 dark:border-zinc-600 rounded-full object-cover' />
                            <div>
                                <div className='text-sm text-slate-400'>Customer</div>
                                <div className='font-bold'>Coco Ho</div>
                            </div>
                            <Link className='bg-slate-100 text-sm px-4 text-slate-700 dark:bg-zinc-800 dark:text-white rounded-lg ml-auto flex items-center gap-2'>
                                <img className='dark:invert size-4' src={SRC_CHAT} />
                                Chat
                            </Link>
                        </div>
                        <div className='w-full py-8'>
                            <div className='font-bold'>Payment summary</div>
                            <div className='py-global text-sm border-b border-slate-200 dark:border-zinc-600 flex justify-between items-center'>
                                <div>2.5 Hours rental</div>
                                <div className='font-semibold'>$125.00 USD</div>
                            </div>
                            
                            <div className='py-global text-sm border-b border-slate-800 dark:border-zinc-400 flex justify-between items-center'>
                                <div>Payment method</div>
                                <div className='font-semibold'>VISA</div>
                            </div>
                            <div className='py-global text-lg flex justify-between items-center'>
                                <div>TOTALS</div>
                                <div className='font-semibold'>$135.00</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <button className='bg-red-500 text-sm px-6 py-3 rounded-md  text-white'>Decline</button>
                    <button className='bg-sky-500 text-sm px-6 py-3 rounded-md text-white'>Approve order</button>
                </div>
            </motion.div >
        </>

    )
}
