
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import { Link } from "react-router-dom";




export function TripSlider() {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} className='sticky shadow-inverted bottom-0 left-0 z-[99999] bg-white p-global w-full border-t border-slate-200 dark:bg-zinc-900'>
                <div className='flex w-full justify-between items-center pb-3'>
                    <div>
                        <div className='font-bold text-lg'>Siargao Island</div>
                        <div className='text-xs text-slate-500'>24-29 April, 2024</div>
                    </div>
                    <Link to="/trip/your-trip" className='px-4 ml-auto py-3 bg-sky-500 text-white  text-sm rounded-lg relative z-20'>
                        Save trip
                    </Link>
                </div>


                {isVisible ?
                    <>
                        
                    </>
                    :
                    <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={toggleVisibility} className='w-full flex items-center mt-3 border-t border-slate-200 dark:bg-zinc-900 pt-4'>
                            <motion.img layoutId='img1' className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                            <motion.img layoutId='img2' className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-20' src='https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367' />
                            <motion.img layoutId='img3' className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-lg -mr-2 relative z-10' src='https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377' />
                            <motion.img layoutId='chevron' src={SRC_CHEV} className='ml-auto dark:invert opacity-40' />
                        </motion.div>

                }


                <AnimatePresence>
                    {isVisible &&
                        <motion.div layout initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
                            animate={{ height: 'auto', overflow: 'visible', opacity: 1 }}
                            exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
                            className='overflow-hidden border-t border-slate-200 dark:bg-zinc-900'>
                            <div className='py-3 flex items-center text-sm uppercase' onClick={toggleVisibility}>Hide
                                <motion.img src={SRC_CHEV} className='ml-auto rotate-90 dark:invert opacity-40' />
                            </div>
                            <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                                <motion.img  layoutId='img1' className='size-12 rounded-full object-cover' src='https://mighty.tools/mockmind-api/content/human/72.jpg' />
                                Tomas Zeman
                                <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                            </div>
                            <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                                <motion.img  layoutId='img2' className='size-12 border border-slate-200 rounded-lg  object-cover' src='https://www.firewiresurfboards.com/cdn/shop/products/MASHUP_Deck_grande.png?v=1675207367' />
                                Firewire Seasife
                                <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                            </div>
                            <div className='flex items-center gap-x-2 py-3'>
                                <motion.img  layoutId='img3' className='size-12 border border-slate-200 rounded-lg object-cover' src='https://www.firewiresurfboards.com/cdn/shop/products/SWEET_POTATO_Deck_grande.png?v=1646168377' />
                                JS Monsta, 6'2
                                <img src={SRC_DELETE} className='ml-auto opacity-40 dark:invert mr-2' />
                            </div>
                        </motion.div>


                    }
                </AnimatePresence>
            </motion.div >


        </>

    )
}

