import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"

import SRC_FAVORITE from '../assets/img/favorite.svg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_STAR from '../assets/img/star.svg';

import SRC_NATHAN from '../assets/img/nathan.webp';
import SRC_JOHN from '../assets/img/john.jpeg';
import SRC_FATLIPS from '../assets/img/fat-lips.jpeg';
import SRC_KUDO from '../assets/img/kudo.jpeg';
import ProductCard from "./ui/ProductCard";
import Favorite from "./ui/Favorite";

import { SocialPost } from './Social'

import { Header } from './Header'

export function Photographers() {


    return (
        <>
            <motion.div layout
                initial={{ x: "100%", opacity: 100 }}
                animate={{ x: 0, opacity: 100 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ x: "100%", opacity: 1 }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999] top-0 left-0 shadow-2xl'>

                <Header hasBack="true" title="Surf Photographers" />
                <div className='w-full h-full overflow-auto no-scrollbar pb-10'>
                    
                        <div className='px-global py-12'>
                            <div className='flex flex-col items-center'>
                                <motion.h3 className='text-3xl font-semibold'>Siargao island</motion.h3>
                                <div className='text-sm text-slate-400 dark:text-zinc-500'>
                                    Philippines
                                </div>
                            </div>
                        </div>

                        <div className='w-full border-t-8 border-slate-200 dark:border-zinc-900 py-global pb-10'>
                            <div className='w-full flex items-center mb-3 text-sm px-global'>
                                <Link to="/surfer-profile">
                                    <img className='size-10 rounded-full object-cover mr-3' src={SRC_NATHAN} />
                                </Link>
                                <div>
                                    <Link to="/surfer-profile" className='font-bold'>Nathan Florence</Link>
                                    <Link to="/spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                                        Siargao Island, Philippines
                                    </Link>
                                </div>
                                <Link to="/surfer-profile" className='px-3 py-2 bg-sky-500 text-xs text-white rounded-lg ml-auto'>View profile</Link>
                            </div>
                            <motion.div
                                className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1609870025624-98b62826e80a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1616141893496-fbc65370493e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1517699418036-fb5d179fef0c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3VyZmluZ3xlbnwwfHwwfHx8MA%3D%3D' />

                            </motion.div>
                        </div>

                        <div className='w-full border-t-8 border-slate-200 dark:border-zinc-900 py-global pb-10'>
                            <div className='w-full flex items-center mb-3 text-sm px-global'>
                                <Link to="/surfer-profile">
                                    <img className='size-10 rounded-full object-cover mr-3' src={SRC_TOMAS} />
                                </Link>
                                <div>
                                    <Link to="/surfer-profile" className='font-bold'>Tomas Zeman</Link>
                                    <Link to="/spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                                        Siargao Island, Philippines
                                    </Link>
                                </div>
                                <Link to="/surfer-profile" className='px-3 py-2 bg-sky-500 text-xs text-white rounded-lg ml-auto'>View profile</Link>
                            </div>
                            <motion.div
                                className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1526342122811-2a9c8512023d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1520443240718-fce21901db79?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1541447237128-f4cac6138fbe?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />

                            </motion.div>
                        </div>

                        <div className='w-full border-t-8 border-slate-200 dark:border-zinc-900 py-global pb-10'>
                            <div className='w-full flex items-center mb-3 text-sm px-global'>
                                <Link to="/surfer-profile">
                                    <img className='size-10 rounded-full object-cover mr-3' src={SRC_JOHN} />
                                </Link>
                                <div>
                                    <Link to="/surfer-profile" className='font-bold'>John John</Link>
                                    <Link to="/spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                                        Siargao Island, Philippines
                                    </Link>
                                </div>
                                <Link to="/surfer-profile" className='px-3 py-2 bg-sky-500 text-xs text-white rounded-lg ml-auto'>View profile</Link>
                            </div>
                            <motion.div
                                className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1551524358-f34c0214781d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1580331522941-58d0812c6d81?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                                <img className='max-h-[250px] snap-center rounded-2xl' src='https://images.unsplash.com/photo-1530870110042-98b2cb110834?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />

                            </motion.div>
                        </div>


                </div>



            </motion.div>
        </>

    )
}


export function PhotoItem(props) {
    const { title, image, name } = props;
    return (
        <div className='w-full border-t-8 border-slate-200 dark:border-zinc-900 py-global pb-10'>
            <div className='w-full flex items-center mb-3 text-sm px-global'>
                <Link to="/surfer-profile">
                    <img className='size-10 rounded-full object-cover mr-3' src={image} />
                </Link>
                <div>
                    <Link to="/surfer-profile" className='font-bold'>{name}</Link>
                    <Link to="/spot-detail" className='text-slate-500 dark:text-zinc-400 text-xs flex gap-x-2 items-center'>
                        Siargao Island, Philippines
                    </Link>
                </div>
                <Link to="/surfer-profile" className='px-3 py-2 bg-sky-500 text-xs text-white rounded-lg ml-auto'>View profile</Link>
            </div>
            <motion.div
                className="w-full flex gap-x-3 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar">
                <img className='max-h-[200px] rounded-2xl' src='https://images.unsplash.com/photo-1609870025624-98b62826e80a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                <img className='max-h-[200px] rounded-2xl' src='https://images.unsplash.com/photo-1616141893496-fbc65370493e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHN1cmZpbmd8ZW58MHx8MHx8fDA%3D' />
                <img className='max-h-[200px] rounded-2xl' src='https://images.unsplash.com/photo-1517699418036-fb5d179fef0c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3VyZmluZ3xlbnwwfHwwfHx8MA%3D%3D' />

            </motion.div>
        </div>
    )
}



