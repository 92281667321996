import React from "react";
import { useState } from "react";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";

import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_CHECK from '../../assets/img/check.svg';
import { EyeOff, EyeOffOutline, EyeOutline } from "react-ionicons";

export function InputField(props) {

    const { title, label, id, value, type, hasIcon, icon, inputProps } = props;

    const [isActive, setIsActive] = useState(true);

    var curr = new Date();
    curr.setDate(curr.getDate() + 0);
    var date = curr.toISOString().substring(0, 10);

    return (
        <>
            <div className="w-full relative flex-shrink-0">
                <input id={id}
                    placeholder={label}
                    value={value}
                    min="0" max="50"
                    className="peer pl-3 pt-[17px] !bg-transparent h-[56px] w-full border rounded-lg border-slate-800 dark:border-zinc-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-black focus:ring-1 focus:ring-black"
                    {...inputProps}
                    type={(type === 'password' && !isActive) ? 'text' : type}
                />
                <label htmlFor={id} className="absolute left-3 top-2 text-gray-500 text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-slate-800 peer-placeholder-shown:top-[16px] peer-focus:top-2 peer-focus:text-slate-500 peer-focus:text-xs">
                    {label}
                </label>
                {hasIcon && (
                    <>
                        {isActive ? (
                            <EyeOutline className="absolute right-2 top-4 z-20" alt="" onClick={()=> setIsActive(false)}/>
                        ) : (
                            <EyeOffOutline className="absolute right-2 top-4 z-20" onClick={()=> setIsActive(true)}/>
                        )}
                        {/* <div className="size-8 bg-white z-10 absolute right-1 bottom-2" /> */}
                    </>
                )}
            </div>
        </>
    );
}

export function DateField(props) {
    const { title, label, id, value, type, hasIcon, icon } = props;
    const [isActive, setIsActive] = useState(false);
    var curr = new Date();
    curr.setDate(curr.getDate() + 0);
    var date = curr.toISOString().substring(0, 10);

    return (
        <>
            <div className="w-full relative flex-shrink-0">
                <input id={id} placeholder={label} defaultValue={date} value={value} type={type} min="0" max="50" className="peer pl-3 pt-[17px] h-[56px] w-full border rounded-lg border-slate-800 dark:border-zinc-300 text-gray-900 placeholder-transparent !bg-transparent focus:outline-none focus:border-black focus:ring-1 focus:ring-black" />
                <label for={id} className="absolute left-3 top-2 text-gray-500 text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-slate-800 peer-placeholder-shown:top-[16px] peer-focus:top-2 peer-focus:text-slate-500 peer-focus:text-xs">
                    {label}
                </label>
                {hasIcon && (
                    <>
                        <img className="absolute right-2 top-4 z-20" src={icon} />
                        <div className="size-8 bg-white z-10 absolute right-1 bottom-2" />
                    </>
                )}
            </div>
        </>
    );
}


export function TextField(props) {
    const { title, label, id } = props;
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <div className="w-full relative flex-shrink-0">
                <textarea id={id} placeholder={label} className="peer pl-3 pt-[26px] h-[120px] w-full border rounded-lg border-slate-800 dark:border-zinc-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-black focus:ring-1 focus:ring-black"></textarea>
                <label for={id} className="absolute left-3 top-2 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-slate-800 peer-placeholder-shown:top-[10px] peer-focus:top-2 peer-focus:text-slate-600 peer-focus:text-sm">
                    {label}
                </label>
            </div>
        </>
    );
}

export function RangeSlider(props) {
    const [volume, setVolume] = useState(1)
    const [muted, setMuted] = useState(false)
    const finalVolume = muted ? 0 : volume ** 1
    const { min, max } = props;
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <div className="w-full relative flex-shrink-0">
                <input
                    className="w-full"
                    type="range"
                    min={min}
                    max={max}
                    step={1}
                    value={volume}
                    onChange={event => {
                        setVolume(event.target.valueAsNumber)
                    }}
                />
                <div className="w-full flex justify-between">
                    <span className="font-bold">{finalVolume.toFixed(0)}</span>
                    <span>max {max} +</span>
                </div>
            </div>
        </>
    );
}

export function SelectField(props) {
    const { title, options } = props;
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className="w-full pl-3 pt-[17px] relative h-[56px] border rounded-lg border-slate-800 dark:border-zinc-300">
            <div>{title}</div>
            <img src={SRC_CHEV} className=" absolute top-[15px] rotate-90 right-2" />
            <div className="w-full h-full absolute left-0 top-0" onClick={() => setIsOpen(true)} />
            <AnimatePresence>
                {isOpen &&
                    <div className="fixed w-full h-full top-0 left-0 z-[99999] flex items-end">
                        <MotionConfig transition={{ duration: 0.05, type: "spring", damping: 40, stiffness: 600 }}>
                            <motion.div
                                initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
                                className="w-full h-full absolute left-0 top-0 bg-black/40" onClick={() => setIsOpen(false)} />
                            <motion.div
                                initial={{ y: '100%' }}
                                animate={{ y: 0 }}
                                exit={{ y: '100%' }}
                                className="p-global max-h-screen overflow-auto rounded-t-2xl bg-white dark:bg-zinc-900 w-full relative z-50">
                                {options}
                            </motion.div>
                        </MotionConfig>
                    </div>
                }
            </AnimatePresence>
        </div>
    )
}

export function SelectOption(props) {
    const { title, icon } = props;
    return (
        <div className="w-full flex items-center gap-x-2 py-5 border-b border-slate-200 dark:border-zinc-600 last-of-type:border-none">
            <img className="size-6" src={icon} />
            {title}
        </div>
    )
}


export function Drawer(props) {
    const { options } = props;
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className="w-full h-full absolute left-0 top-0">
            <div className="w-full h-full absolute top-0 left-0 " onClick={() => setIsOpen(true)} />
            <AnimatePresence>
                {isOpen &&
                    <div className="fixed w-full h-full top-0 left-0 z-[99999] flex items-end">
                        <MotionConfig transition={{ duration: 0.05, type: "spring", damping: 40, stiffness: 600 }}>
                            <motion.div
                                initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
                                className="w-full h-full absolute left-0 top-0 bg-black/40" onClick={() => setIsOpen(false)} />
                            <motion.div
                                initial={{ y: '100%' }}
                                animate={{ y: 0 }}
                                exit={{ y: '100%' }}
                                className="p-global max-h-screen overflow-auto rounded-t-2xl bg-white dark:bg-zinc-900 w-full relative z-50">
                                {options}
                            </motion.div>
                        </MotionConfig>
                    </div>
                }
            </AnimatePresence>
        </div>
    )
}

export function DrawerOption(props) {
    const { title, icon, hasIcon, hasCheckbox } = props;
    return (
        <div className="w-full flex items-center gap-x-2 py-5 border-b border-slate-200 dark:border-zinc-600 last-of-type:border-none">
            {hasIcon && (
                <img className="size-6" src={icon} />
            )}

            <div className="text-base normal-case">{title}</div>
            {hasCheckbox && (
                <Checkbox />
            )}
        </div>
    )
}

export function Checkbox(props) {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };
    return (
        <motion.div whileTap={{ scale: 1.4 }} transition={{ duration: 0.3 }} className={`size-6 ml-auto flex items-center justify-center rounded-md ${clicked ? 'bg-sky-500' : 'bg-slate-200 dark:bg-zinc-700'}`} onClick={handleClick}>
            <img className={`size-4 invert ${clicked ? 'block' : 'hidden'}`} src={SRC_CHECK} />
        </motion.div>
    )
}
