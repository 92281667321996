import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"


import SRC_EDIT from '../assets/img/edit.svg';
import SRC_CHAT from '../assets/img/messages.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_SIZE from '../assets/img/board.svg';
import SRC_VIDEO from '../assets/img/camera.svg';
import SRC_TIME from '../assets/img/time.svg';
import SRC_CAR from '../assets/img/car.svg';

import SRC_COCO from '../assets/img/coco.jpeg';

import { SocialPost } from './Social'

import { CheckoutDrawer } from './Checkout/CheckoutDrawer';

import { HeaderCheckout } from './Header'

export function Service() {


    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='product-test fixed flex flex-col items-start justify-start bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Service detail" hasIcon="true" icon={SRC_CHAT} link="/trip/location" hasCustom="true"
                custom={
                    <>
                        <Link to="/profile/add-service" className='size-[26px] rounded-lg relative ml-6 flex items-center justify-center'>
                            <img src={SRC_EDIT} />
                        </Link>
                    </>
                }
                
                />
                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full flex flex-col items-center mb-3 text-sm px-global pb-8 mt-8 relative z-20'>
                        <img className='size-32 rounded-full object-cover border-4 border-white dark:border-zinc-800' src={SRC_COCO} />
                        <div className='flex flex-col items-center'>
                            <div className='text-xl font-bold'>Coco Ho</div>
                            <div className='text-base'>Siargao Island, Philippines</div>

                        </div>
                    </div>
                    <div className='p-6'>
                        <motion.div layoutId="protuctname" className='text-3xl font-semibold'>Advanced surf training</motion.div>
                        <div className='flex items-start mt-2'>
                            <div className='text-xl font-semibold'>$50.00 USD</div>
                            <div className='text-xs text-slate-500 ml-1 mt-1'>/ per person</div>
                        </div>
                        <div className='text-base text-slate-500 dark:text-zinc-500 my-6'>
                            Two minds combined.

                            Rob Machado and Dan Mann have created the Mashup: A melding of each of their most popular designs; the Seaside and the Spitfire.

                            Both board designers have spent time mixing and matching their favorite elements of each shape, and they’ve landed at something that goes fast in weak waves as well as a Seaside, and also drives vertically in good waves as well as the Spitfire.
                        </div>

                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_TIME} />
                            <div className=' '>2 hours</div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_SIZE} />
                            <div className=' '>Board included</div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_VIDEO} />
                            <div className=' '>Video analysis</div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_CAR} />
                            <div className=' '>Transport included</div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_LOCATION} />
                            <div className=' '>Meet at the spot</div>
                        </div>
                       




                    </div>



                </div>

                <CheckoutDrawer price="$50.00 USD" per="person" />



            </motion.div>
        </>

    )
}







