
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";

import SRC_CLOSE from '../../assets/img/close.svg';

import SRC_GLOBE from '../../assets/img/globe.svg';
import SRC_BOARD from '../../assets/img/board.svg';
import SRC_CAMERA from '../../assets/img/camera.svg';
import SRC_CROWD from '../../assets/img/crowd.svg';
import SRC_SHOP from '../../assets/img/shop.svg';
import SRC_INSTRUCTOR from '../../assets/img/instructor.svg';
import SRC_SHAKA from '../../assets/img/shaka.svg';
import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, Checkbox, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function LookingFor() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <motion.div layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100 }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="" hasIcon="true" icon={SRC_CLOSE} link="/marketplace" />

                <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 100, x: 0 }} exit={{ x: -50, opacity: 0 }} transition={{ duration: 0.2 }} className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='p-global'>
                        <h2 className='text-2xl font-bold text-center mb-8'>What are you looking for?</h2>
                        
                        <Category  title="Surf instructors" icon={SRC_INSTRUCTOR} description="Level up your skills with the best" />

                        <Category title="Friends" icon={SRC_SHAKA} description="Find people to surf with" />
                        <Category title="Boards for rent" icon={SRC_SHOP} description="Rent or buy gear from in your area" />
                        <Category title="Photographers" icon={SRC_CAMERA} description="Ger the best photos of your sessions" />

                        <Category title="Events" icon={SRC_BOARD} description="Find trips and events with local guides" />

                    </div>
                </motion.div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <Link Link to="/trip/results" className='bg-sky-500 text-center text-sm px-6 py-3 rounded-md text-white w-full'>Next</Link>
                </div>
            </motion.div >


        </>

    )
}


export function Category(props) {
    const { icon, title, description } = props;
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
        <motion.div whileTap={{scale:1.05}} onClick={handleClick} className={`w-full p-4 flex items-center rounded-2xl mb-2 border  dark:border-zinc-600 ${clicked ? 'border-transparent outline outline-1 bg-slate-100 outline-zinc-800 dark:outline-zinc-500' : 'border-slate-200'}`}>
            <div className={`size-14 mr-4 flex items-center justify-center rounded-full  ${clicked ? 'bg-white dark:bg-zinc-700' : 'bg-slate-100 dark:bg-zinc-700'}`}>
                <img className='size-6 dark:invert' src={icon} />
            </div>
            <div>
                <div className='text-lg font-semibold'>{title}</div>
                <div className='text-xs text-slate-400 dark:text-zinc-400'>{description}</div>
            </div>
        </motion.div>

    )
}

