
import { useState } from 'react'
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"
import { Link } from "react-router-dom";




export function ProductCard(props) {
    const {img, name, price} = props;

    return (
    
    <>
            <motion.div 
            layout 
            className='snap-center w-2/5 col-span-1 rounded-xl flex-shrink-0 relative'>
            <Link to="/product-page" className='items-center flex flex-col justify-start w-full h-full flex-shrink-0 rounded-lg'>
                    <div className='w-full h-[220px] relative z-20 flex-shrink-0 before:w-full before:block rounded-xl overflow-hidden'>
                        <img className='absolute left-0 top-0 h-full w-full object-cover' src={img} />
                    </div>
                    <div className='w-full h-full flex flex-col justify-between mt-2'>
                        <h3 className='card-title font-semibold text-sm mb-px'>{name}</h3>
                        <div className='flex w-full justify-between text-xxs mt-auto'>
                        {price}
                        </div>
                    </div>
            </Link>
            
        </motion.div>
  </>
)
}

export default ProductCard