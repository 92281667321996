
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll } from "framer-motion"
import { Link, useNavigate } from "react-router-dom";
import SRC_STAR from '../assets/img/star.svg';
import SRC_FATLIPS from '../assets/img/fat-lips.jpeg';
import SRC_TOMAS from '../assets/img/tomas.jpg';
import SRC_NATHAN from '../assets/img/nathan.webp';

import StaticCard from "./ui/StaticCard";
import ProductCard from "./ui/ProductCard";
import SRC_GLOBE from '../assets/img/globe.svg';
import SRC_SESSIONS from '../assets/img/sessions.svg';
import SRC_CHART from '../assets/img/analytics.svg';
import SRC_INFO from '../assets/img/info.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_SHORTS from '../assets/img/shorts.svg';
import SRC_BAG from '../assets/img/bag-empty.svg';
import SRC_ANALYTICS from '../assets/img/analytics.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_WATCH from '../assets/img/watch.svg';
import SRC_FAVORITE from '../assets/img/favorite.svg';
import SRC_DISTANCE from '../assets/img/distance.svg';
import SRC_PROFILE from '../assets/img/profile.svg';

import SRC_SWITCH from '../assets/img/switch-account.svg';

import SRC_PRODUCTS from '../assets/img/products.svg';
import SRC_EVENTS from '../assets/img/calendar.svg';
import SRC_TIME from '../assets/img/time.svg';
import SRC_ADD from '../assets/img/add.svg';

import SRC_CHEV from '../assets/img/chevron-right.svg';
import { SocialPost } from './Social'
import { InputField, TextField, Drawer, DrawerOption } from './ui/Forms';
import { getAbbreviation, onFormatAddressFlow } from '../helpers/GeneralHelper';

export function Profile(props) {

    const navigate = useNavigate();

    const { USER, LANGUAGE } = props;

    const onSelectSettings = (type, payload) => {

        console.log("type ===> ", type, payload);

        if (type === 'user') {
            navigate('/' + LANGUAGE + '/settings/me')
        }
        else if (type === 'store') {
            // navigate('/' + LANGUAGE + '/settings/store')
        }
        else if (type === 'dogs') {
            navigate('/' + LANGUAGE + '/pets')
        }
        else if (type === 'training') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-training')
        }
        else if (type === 'walking') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-walking')
        }
        else if (type === 'sitting') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-sitting')
        }
        else if (type === 'grooming') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-grooming')
        }
        else if (type === 'nutrition') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-nutrition')
        }
        else if (type === 'veterinary') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-veterinary')
        }
        else if (type === 'shelter') {
            navigate('/' + LANGUAGE + '/dashboard/services/dog-shelter')
        }
        else if (type === 'view_dog') {
            navigate('/' + LANGUAGE + '/pets/' + payload.id)
        }
        else if (type === 'edit_dog') {
            navigate('/' + LANGUAGE + '/settings/pets/' + payload.id)
        }
        else if (type === 'view_store') {
            navigate(`/${LANGUAGE}/search/dog-training/provider/${USER?.store?.id}`)
        }
        else if (type === 'edit_store') {
            navigate('/' + LANGUAGE + '/settings/store')
        }
        else if (type === 'me') {
            navigate('/' + LANGUAGE + '/public/' + USER.id)
        }
        else if (type === 'share') {
            navigate('/' + LANGUAGE + '/public/' + USER.id + '?share=true')
            //navigate('/' + LANGUAGE + '/public/' + USER.id)
        }
        else {
            navigate('/' + LANGUAGE + '/settings')
        }
    }
    return (
        <>
            <motion.div layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 100, scale: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className='page-wrapper p-0 pt-1 no-scrollbar'>

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full flex items-start text-sm px-global py-4 pb-4'>
                        {USER?.img ? (
                            <img className='size-20 rounded-full object-cover mr-5 bg-zinc-100' src={USER.img} alt='' />
                        ) : (
                            <div className='size-20 flex items-center justify-center rounded-full object-cover mr-5 bg-zinc-100'>
                                <div className='text-2xl font-semibold'>
                                    {getAbbreviation(USER?.name)}
                                </div>
                            </div>
                        )}

                        <div className='flex flex-col'>
                            <div className='text-xl font-bold'>
                                {USER?.name}
                            </div>
                            <div className='text-base'>
                                {onFormatAddressFlow(USER?.location)}
                            </div>
                            <div className='flex items-start justify-start gap-x-2'>
                                <Link to="/surfer-profile"
                                    className='px-3 flex-shrink-0 flex items-center py-2 mr-auto mt-2 bg-sky-500 text-white text-sm rounded-lg'>
                                    View Profile
                                </Link>
                                <Link to="/edit-profile"
                                    className='px-3 flex-shrink-0 flex items-center py-2 mr-auto mt-2 bg-slate-100 dark:bg-zinc-700  dark:text-white text-sm rounded-lg'>
                                    Edit Profile
                                </Link>
                            </div>
                        </div>
                    </div>
                    <AnimatedTabs />

                </div>
            </motion.div>
        </>

    )
}



const tabs = [

    {
        name: 'tab1',
        label: 'Account',
        render: () => {

            return <>
                <Account />


            </>
        }
    },
    {
        name: 'tab2',
        label: 'Shop',
        render: () => {
            return <>
                <div className="w-full bg-slate-100 dark:bg-zinc-900 p-global">
                    <StravaStats />
                </div>
                <div className='p-global w-full'>
                    {/*<div className="p-6 rounded-2xl bg-white drop-shadow-md dark:bg-zinc-700">
                        <div className="flex items-center">
                            <div className="h-10 w-1 bg-sky-500 mr-2" />
                            <div className="font-medium text-4xl mr-3">$911.00</div>
                            <div className="text-xs text-slate-400 dark:text-zinc-500">Earnings <br />past 7 days</div>
                        </div>

        </div>*/}



                    <Link to="/profile/orders" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                        <img className='size-5 dark:invert' src={SRC_BAG} />
                        Orders
                        <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                    </Link>
                    <Link to="/profile/products" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                        <img className='size-5 dark:invert' src={SRC_PRODUCTS} />
                        Products
                        <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                    </Link>


                    <div className='h-2 w-full 0 my-6' />
                </div>
            </>
        }
    },
    {
        name: 'tab3',
        label: 'Services',
        render: () => {
            return <>
                <div className="w-full bg-slate-100 dark:bg-zinc-900 p-global">
                    <StravaStats />
                </div>
                <div className='p-global w-full'>

                    <Link to="/profile/bookings" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                        <img className='size-5 dark:invert' src={SRC_SESSIONS} />
                        Bookings
                        <div className='text-xs px-2 py-1 ml-auto rounded-full bg-orange-400 text-white'>2 Pending</div>
                    </Link>
                    <Link to="/profile/services" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                        <img className='size-5 dark:invert' src={SRC_WAVE} />
                        Your Services
                        <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                    </Link>
                    <Link to="/profile/events" className='w-full text-base font-medium py-4 flex items-center gap-x-4 '>
                        <img className='size-5 dark:invert' src={SRC_EVENTS} />
                        Events
                        <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                    </Link>
                </div>
            </>
        }
    },


];


function AnimatedTabs() {
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleClick = (e, tab) => {
        e.preventDefault()

        setActiveTab(tab)
    }

    const isSelected = tab => activeTab.name === tab.name

    return (
        <>
            <div className="w-full flex sticky top-0 bg-white dark:bg-zinc-800 z-50 gap-x-3 overflow-auto no-scrollbar border-b border-slate-200 dark:border-zinc-700">
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        className=" w-1/2 text-center"
                    >
                        <a className='px-4 py-3 pt-4 block ' href="#" onClick={e => handleClick(e, tab)}>
                            {tab.label}
                        </a>

                        {isSelected(tab) && (
                            <motion.div layoutId="indicator" className="h-1 bg-sky-500 rounded-full" />
                        )}
                    </div>
                ))}
            </div>

            <div className="w-full bg-white dark:bg-zinc-800 relative ">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab.name || "empty"}
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                            duration: 0.1
                        }}
                    >
                        {activeTab && activeTab?.render()}
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    )
}

function Account() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };
    return (
        <>
            <div className="w-full grid grid-cols-3 gap-x-3 mt-4">
                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_WAVE} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        14k
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Waves surfed</div>

                </div>
                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_SHORTS} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        391h
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Water time</div>

                </div>
                <div className='ml-auto flex flex-col items-center rounded-xl flex-shrink-0 w-full py-6 snap-center  '>
                    <img className='dark:invert size-[24px]' src={SRC_SESSIONS} />
                    <div className='flex items-center mt-1 gap-x-1 text-xl justify-end font-semibold'>
                        291
                    </div>
                    <div className='text-xxs text-slate-400 dark:text-zinc-500'>Total sessions</div>

                </div>
            </div>
            <div className='h-2 w-full bg-slate-100 dark:bg-zinc-900 mt-6' />
            <div className='px-global pt-6 w-full'>

                <Link to="/profile/sessions" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_SESSIONS} />
                    Sessions
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_ANALYTICS} />
                    Stats
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link to="/profile/saved" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_FAVORITE} />
                    Favorites
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link to="/profile/boards" className='w-full text-base font-medium py-4 flex items-center gap-x-4 border-b border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_BOARD} />
                    Your Boards
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link to="/profile/events" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_EVENTS} />
                    Events
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link to="/profile/trips" className='w-full text-base font-medium py-4 border-b flex items-center gap-x-4 border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_GLOBE} />
                    Your trips
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>
                <Link to="/profile/history" className='w-full text-base font-medium py-4 flex items-center gap-x-4 border-b border-slate-200 dark:border-zinc-600'>
                    <img className='size-5 dark:invert' src={SRC_TIME} />
                    History
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </Link>


                <div onClick={handleClick} className='w-full text-base font-medium py-4 flex items-center gap-x-4 '>
                    <img className='size-5 dark:invert' src={SRC_SWITCH} />
                    Switch account
                    <img className='ml-auto opacity-40 dark:invert' src={SRC_CHEV} />
                </div>

            </div>

            <AnimatePresence>
                {isShown &&
                    <>
                        <div className=' fixed w-full h-full top-0 left-0 z-[99999]' onClick={handleClick} />
                        <SwitchAccount />
                    </>
                }
            </AnimatePresence>
        </>
    )
}

function StravaStats() {
    return (
        <div className="w-full px-global bg-white dark:bg-zinc-800 shadow-sm rounded-xl">
            <div className="w-full relative py-global mb-6 uppercase flex items-center justify-between border-b border-slate-200 dark:border-zinc-600 text-xs">
                Last month
                <Drawer
                    options={
                        <>
                            <DrawerOption title="Past month" hasCheckbox="true" />
                            <DrawerOption title="Past 7 Days" hasCheckbox="true" />
                            <DrawerOption title="All time" hasCheckbox="true" />
                        </>
                    }

                />
                {/*<Link to="/insights" className="text-sky-500 font-semibold">View all</Link>*/}
                <img src={SRC_CHEV} className="dark:invert rotate-90" />
            </div>
            <div className="flex text-xs uppercase">
                <div className="h-[85px] w-1/2 text-4xl flex flex-col items-start justify-center border-r border-slate-200 dark:border-zinc-600">
                    <div className="ml-auto text-xxs mb-auto mr-4">Earnings</div>
                    <div className="my-auto -translate-y-2 flex items-start"><span className="text-base">$</span>912<span className="text-base mt-auto">.99</span></div>
                </div>
                <div className="w-1/2 flex flex-col pl-global">
                    <div className="flex w-full items-start justify-start border-b border-slate-200 dark:border-zinc-600 pb-4">
                        <img className="size-5 mr-2 translate-y-[3px] dark:invert" src={SRC_BAG} />
                        <span className="text-lg">21</span>
                        <div className="ml-auto text-xxs">Orders</div>
                    </div>
                    <div className="flex w-full items-start justify-start pt-2 pb-4">
                        <img className="size-5 mr-2 translate-y-[3px] dark:invert" src={SRC_PROFILE} />
                        <span className="text-lg">291</span>
                        <div className="ml-auto text-xxs">Store Visitors</div>
                    </div>
                    {/*<div className="flex w-full items-start justify-start pt-2 pb-4">
                        <img className="size-5 mr-2 translate-y-[3px] dark:invert" src={SRC_DISTANCE} />
                        <span className="text-lg">24</span>
                        <div className="ml-auto text-xxs">KM</div>
    </div>*/}
                </div>
            </div>
        </div>
    )
}


function SwitchAccount({ onClose }) {

    return (
        <>

            <div className='fixed bottom-0 left-0 w-full flex items-end justify-start h-full z-[9999]'>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 100 }}
                    transition={{ duration: 0.1 }}
                    exit={{ opacity: 0 }}
                    className='bg-black/30 dark:bg-black/70 fixed w-full h-full top-0 left-0 z-50' onClick={onClose} />
                <motion.div
                    key="modal"
                    initial={{ y: "90%" }}
                    animate={{ y: 0 }}
                    transition={{ type: "spring", damping: 40, stiffness: 700 }}
                    exit={{ y: "90%" }}
                    className='w-full px-global pt-global pb-6 dark:text-white rounded-tl-xl rounded-tr-xl bg-white dark:bg-zinc-900 relative z-[9999]'>


                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                        <img className='size-12 rounded-full object-cover' src={SRC_TOMAS} />
                        Tomas Zeman
                        <input className="size-5 ml-auto accent-black" checked type="radio" />
                    </div>
                    <div className='flex items-center gap-x-2 border-b border-slate-200 dark:border-zinc-700 py-3'>
                        <img className='size-12 rounded-full object-cover' src={SRC_FATLIPS} />
                        Fat Lips
                        <input className="size-5 ml-auto accent-black" type="radio" />
                    </div>
                    <div className='flex items-center gap-x-2 py-3'>
                        <div className="size-12 flex items-center justify-center">
                            <img className='rounded-full object-contain dark:invert' src={SRC_ADD} />
                        </div>
                        Add Account
                    </div>
                </motion.div>
            </div>

        </>
    );
}



