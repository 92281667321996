
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../../assets/img/calendar.svg';
import SRC_EDIT from '../../assets/img/edit.svg';
import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_LOCATION from '../../assets/img/location.svg';
import SRC_CHAT from '../../assets/img/messages.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import SRC_NATHAN from '../../assets/img/nathan.webp';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function EditBooking() {
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Edit Booking" hasIcon="true" icon={SRC_DELETE} link="/profile/bookings" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full px-global pt-0'>
                        <div className="w-full flex flex-col py-8 pt-3 items-center last-of-type:border-none">
                            <img src={SRC_NATHAN} className='size-24 mb-global border border-slate-200 dark:border-zinc-600 rounded-full object-cover' />
                            <div className='text-center'>
                                <div className='font-bold text-lg'>Advanced surf training</div>
                                <div className='text-slate-500 text-sm'>2 hours</div>
                            </div>
                        </div>
                        <form className='w-full flex flex-col gap-4 '>

                            <DateField label="Date" id="date" hasIcon="true" type="date" value="Time" icon={SRC_CALENDAR} />
                            <div className='grid grid-cols-2 gap-x-3'>
                                <InputField label="Start" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                                <InputField label="End" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                            </div>


                        </form>
                        <div className='flex w-full py-8 border-b border-slate-200 dark:border-zinc-600'>

                            <div>
                                <div className='text-sm text-slate-400'>Instructor</div>
                                <div className='font-bold'>Nathan Florence</div>
                            </div>
                            <Link className='bg-slate-100 text-sm px-4 text-slate-700 dark:bg-zinc-800 dark:text-white rounded-lg ml-auto flex items-center gap-2'>
                                <img className='dark:invert size-4' src={SRC_CHAT} />
                                Chat
                            </Link>
                        </div>
                        <div className='w-full py-8'>
                            <div className='font-bold'>Updated summary</div>
                            <div className='py-global text-sm border-b border-slate-200 dark:border-zinc-600 flex justify-between items-center'>
                                <div>2 Hours</div>
                                <div className='font-semibold'>$125.00 USD</div>
                            </div>
                            
                            <div className='py-global text-sm border-b border-slate-800 dark:border-zinc-400 flex justify-between items-center'>
                                <div>Payment method</div>
                                <div className='font-semibold'>VISA</div>
                            </div>
                            <div className='py-global text-lg flex justify-between items-center'>
                                <div>TOTALS</div>
                                <div className='font-semibold'>$135.00</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <button className='bg-slate-200 text-sm px-6 py-3 rounded-md  dark:text-white dark:bg-zinc-600'>Cancel</button>
                    <button className='bg-sky-500 text-sm px-6 py-3 rounded-md text-white'>Save changes</button>
                </div>
            </motion.div >
        </>

    )
}
