
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_CALENDAR from '../../assets/img/calendar.svg';
import SRC_EDIT from '../../assets/img/edit.svg';
import SRC_COCO from '../../assets/img/coco.jpeg';
import SRC_LOCATION from '../../assets/img/location.svg';
import SRC_CHAT from '../../assets/img/messages.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_DELETE from '../../assets/img/delete.svg';
import SRC_PRICE from '../../assets/img/sale.svg';

import SRC_NATHAN from '../../assets/img/nathan.webp';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderCheckout } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function EditEvent() {
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="Add/Edit Event" hasIcon="true" icon={SRC_DELETE} link="/profile/bookings" />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full px-global pt-0'>
                        <div className='w-full mb-4 relative before:w-full before:block before:pt-[66%]'>
                            <img src="https://tinyurl.com/2bmdckce" className='size-full absolute top-0 left-0 rounded-2xl object-cover' />

                        </div>
                        <form className='w-full flex flex-col gap-4 pb-24'>

                            <InputField label="Event title" id="time" type="text" value="Trip to north"  />
                            <TextField label="Description" id="Description" type="text"   />
                            <div className='grid grid-cols-2 gap-x-3 pt-6 border-t border-slate-200 dark:border-zinc-600'>
                                <DateField label="Start date" id="date" hasIcon="true" type="date" value="Time" icon={SRC_CALENDAR} />
                                <DateField label="End date" id="date" hasIcon="true" type="date" value="Time" icon={SRC_CALENDAR} />
                            </div>
                            <div className='grid grid-cols-2 gap-x-3 pb-6 border-b border-slate-200 dark:border-zinc-600'>
                                <InputField label="Start" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                                <InputField label="End" id="time" hasIcon="true" type="time" value="Time" icon={SRC_TIME} />
                            </div>
                            <InputField label="Price per person" id="time" hasIcon="true" type="text" value="20" icon={SRC_PRICE} />


                        </form>


                    </div>
                </div>
                <div className='flex px-global py-4 border-t border-slate-200 dark:border-zinc-700 justify-between'>
                    <button className='bg-slate-200 text-sm px-6 py-3 rounded-md  dark:text-white dark:bg-zinc-600'>Cancel</button>
                    <button className='bg-sky-500 text-sm px-6 py-3 rounded-md text-white'>Save changes</button>
                </div>
            </motion.div >
        </>

    )
}
