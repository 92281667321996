import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export default function Switch() {
  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => setIsOn(!isOn);

  return (
    <motion.div className="switch w-11 h-6 rounded-full flex items-center px-1 bg-slate-200 dark:bg-zinc-700" data-isOn={isOn} onClick={toggleSwitch}>
      <motion.div className="handle size-4 rounded-full bg-white" layout transition={spring} />
    </motion.div>
  );
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
};
