import React from "react";
import { useState, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";


import SRC_SHAKA from '../../assets/img/shaka.svg';
import SRC_LIKE from '../../assets/img/like.svg';
import SRC_LIKED from '../../assets/img/like-full.svg';

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export default function Chip(props) {
    const { title } = props;
    const [isActive, setIsActive] = useState(false);

    const ref = useRef(null);

  const { scrollXProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"]
  });
  const scale = useTransform(scrollXProgress, [0, 0.5, 1], [0.5, 1, 0.5]);

    return (
        <>
            {isActive
                ?
                <motion.button
                    onClick={() => setIsActive(false)}
                      className='flex text-sm flex-shrink-0 px-4 py-2 rounded-full border border-sky-500  items-center justify-center bg-sky-500 text-white'>
                        {title}
                </motion.button>
                :
                <motion.button
                    onClick={() => setIsActive(true)}
                     className='flex text-sm flex-shrink-0 px-4 py-2 border border-slate-700 dark:border-zinc-500 items-center justify-center rounded-full'>
                        {title}
                </motion.button>

            }
        </>
    );
}

