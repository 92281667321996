import axios from 'axios';

//const API_SIMPLE = 'https://aviationinfinity.herokuapp.com';
//const API_SIMPLE = 'http://localhost:3002';
const API_SIMPLE = '';

const API_ROOT = API_SIMPLE + '/api/v1';

const getApiPublic = async (API_URL) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.get(API_REQUEST);
        var DATA_RESPONSE = DATA.data;

        console.log("API GET PUBLIC SUCCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API GET PUBLIC ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const getApiPrivate = async (API_URL, JWT) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.get(API_REQUEST, { headers: { authorization: JWT } });
        var DATA_RESPONSE = DATA.data;

        console.log("API GET PRIVATE SUCCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API GET PRIVATE ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const postApiPublic = async (API_URL, FORM) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.post(API_REQUEST, FORM);
        var DATA_RESPONSE = DATA.data;

        console.log("API POST PUBLIC SUCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API POST PUBLIC ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const postApiPrivate = async (API_URL, FORM, JWT) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.post(API_REQUEST, FORM, { headers: { authorization: JWT } });
        var DATA_RESPONSE = DATA.data;

        console.log("API POST PRIVATE DATA ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API POST PRIVATE ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

function shufflePosts(posts) {
    for (let i = posts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [posts[i], posts[j]] = [posts[j], posts[i]];
    }
    return posts;
  }

export { getApiPublic, getApiPrivate, postApiPublic, postApiPrivate, shufflePosts, API_ROOT, API_SIMPLE };