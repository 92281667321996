
import { useState, useEffect, useMemo } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useNavigate } from 'react-router-dom';

import ThemeSwitcher from './ThemeSwitcher';
import FilterSocial from './Filters';
import SearchSpots from './SearchSpots';

import Like from "./ui/Like";
import Favorite from "./ui/Favorite";
import Follow from "./ui/Follow";





import SRC_ADD from '../assets/img/add.svg';
import SRC_NOTI from '../assets/img/notifications.svg';
import SRC_CHAT from '../assets/img/messages.svg';

import SRC_LIKE from '../assets/img/like.svg';
import SRC_COMMENT from '../assets/img/comment.svg';
import SRC_SHARE from '../assets/img/share.svg';
import SRC_EDIT from '../assets/img/edit.svg';
import SRC_BACK from '../assets/img/back.svg';
import SRC_FILTER from '../assets/img/filter.svg';
import SRC_CLOSE from '../assets/img/close.svg';
import SRC_SEARCH from '../assets/img/search.svg';
import SRC_SETTINGS from '../assets/img/settings.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SearchGeneral from './SearchGeneral';
import LocationDrawer from './LocationDrawer';





export function Header(props) {
    const { title, hasBack, hasLocation } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isLocation, setIsLocation] = useState(false);
    const navigate = useNavigate();

    const handleOpenSearch = () => {
        setIsOpen(true);
    };
    const handleCloseSearch = () => {
        setIsOpen(false);
    };
    const handleOpenLocation = () => {
        setIsLocation(true);
    };
    const handleCloseLocation = () => {
        setIsLocation(false);
    };
    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0">
                    {hasBack && (
                        <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                            <img className='dark:invert' src={SRC_BACK} />
                        </button>
                    )}
                    <div className="font-semibold text-lg">{title}</div>
                    {hasLocation && (
                        <div className="flex items-center text-xs text-slate-400 dark:text-zinc-500 ml-3 translate-y-px" onClick={handleOpenLocation}>
                            <div className='size-2 rounded-full mr-2 relative'>
                                <div className='size-2 absolute left-0 top-0 rounded-full bg-green-500 z-20' />
                                <div className='size-2 absolute left-0 top-0 rounded-full bg-green-400 animate-ping' />
                            </div>
                            General Luna
                        </div>
                    )}
                    {/*<ThemeSwitcher />*/}
                    <div className='flex ml-auto gap-x-5'>
                        <button className='flex size-[26px] items-center justify-center' onClick={handleOpenLocation}>
                            <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_LOCATION} />
                        </button>
                        <button className='flex size-[26px] items-center justify-center' onClick={handleOpenSearch}>
                            <motion.img className='dark:invert' layoutId='search-icon' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_SEARCH} />
                        </button>
                    </div>
                </div>
            </AnimatePresence>
            <AnimatePresence>
                {isOpen && <SearchGeneral onClose={handleCloseSearch} />}
                {isLocation && <LocationDrawer onClose={handleCloseLocation} />}
            </AnimatePresence>


        </>
    );
}

export function HeaderUserProfile() {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenSearch = () => {
        setIsOpen(true);
    };
    const handleCloseSearch = () => {
        setIsOpen(false);
    };
    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0">
                    <div className="font-semibold text-lg">You</div>
                    {/*<ThemeSwitcher />*/}
                    <div className='flex ml-auto gap-x-5'>
                        <Link to="/notifications" className='flex size-[26px] items-center justify-center'>
                            <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_NOTI} />
                        </Link>
                        <Link to="/settings" className='flex size-[26px] items-center justify-center'>
                            <motion.img className='dark:invert' layoutId='search-icon' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_SETTINGS} />
                        </Link>
                    </div>
                </div>
            </AnimatePresence>
            <AnimatePresence>
                {isOpen && <SearchGeneral onClose={handleCloseSearch} />}
            </AnimatePresence>


        </>
    );
}

export function HeaderSpot() {
    const navigate = useNavigate();
    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0">
                    <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                        <img className='dark:invert' src={SRC_BACK} />
                    </button>
                    <div className="font-semibold text-lg">Spot name</div>
                    <div className='flex ml-auto gap-x-3 items-center'>
                        <Link className='flex size-[26px] flex-shrink-0 items-center justify-center' to="/new-session">
                            <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_ADD} />
                        </Link>
                        <Favorite />
                    </div>
                </div>
            </AnimatePresence>


        </>
    );
}

export function HeaderProduct() {
    const navigate = useNavigate();
    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] fixed justify-between top-0 z-50 flex text-black  dark:bg-zinc-800 dark:text-white items-center px-global flex-shrink-0">
                    <button className='flex size-[40px] border border-slate-200 dark:bg-zinc-800 rounded-xl bg-white mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                        <img className='dark:invert' src={SRC_BACK} />
                    </button>
                    <motion.button
                        whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='flex size-[40px] border border-slate-200 dark:bg-zinc-800 rounded-xl bg-white items-center justify-center'>
                        <motion.img whileTap={{ rotate: 45 }} className='dark:invert' src={SRC_SHARE} />
                    </motion.button>

                </div>
            </AnimatePresence>


        </>
    );
}

export function HeaderSearch(props) {
    const { title } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenSearch = () => {
        setIsOpen(true);
    };
    const handleCloseSearch = () => {
        setIsOpen(false);
    };

    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0">

                    <div className="font-semibold text-lg">{title}</div>
                    <div className='flex ml-auto gap-x-5'>

                        <button className='flex size-[26px] items-center justify-center' onClick={handleOpenSearch}>
                            <motion.img className='dark:invert' layoutId='search-icon' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_SEARCH} />
                        </button>
                    </div>
                </div>
            </AnimatePresence>

            <AnimatePresence>
                {isOpen && <SearchSpots onClose={handleCloseSearch} />}
            </AnimatePresence>


        </>
    );
}



export function HeaderSocial() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>

            <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between items-center px-global flex-shrink-0">
                <AnimatePresence mode='wait'>
                    {isOpen
                        ? <motion.h3 initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -20 }} className='text-xl font-semibold'>Filters</motion.h3>
                        : <ThemeSwitcher />
                    }
                </AnimatePresence>
                <div className='flex items-center ml-auto gap-x-3'>

                    <AnimatePresence mode='wait'>
                        {isOpen
                            ? <></>
                            :
                            <>

                                <Link className='flex size-[40px] rounded-lg items-center justify-center relative' to="/notifications">
                                    <div className='size-3 rounded-full bg-sky-500 absolute top-2 right-2 border-2 border-white dark:border-zinc-800 z-20' />
                                    <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_NOTI} />
                                </Link>
                            </>
                        }
                        {isOpen
                            ? <motion.button initial={{ scale: 0, rotate: "90deg" }} animate={{ scale: 1, rotate: "0deg" }} exit={{ scale: 0 }}
                                onClick={() => setIsOpen(false)}
                                className='flex size-[26px] items-center justify-center' to="/about">
                                <img transition={{ duration: 0.1 }} src={SRC_CLOSE} />
                            </motion.button>
                            :
                            <>
                                <button
                                    onClick={() => setIsOpen(true)}
                                    className='flex size-[26px] items-center justify-center' to="/about">
                                    <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_FILTER} />
                                </button>
                                <Link className='flex size-[40px] rounded-lg items-center justify-center ' to="/new-session">
                                    <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={SRC_ADD} />
                                </Link>
                            </>
                        }
                    </AnimatePresence>

                </div>
            </div>
            <AnimatePresence>
                {isOpen && (

                    <FilterSocial />
                )}
            </AnimatePresence>




        </>
    );
}

export function HeaderPost() {

    const navigate = useNavigate();

    return (
        <>
            <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between  items-center px-global flex-shrink-0">
                <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                    <img className='dark:invert' src={SRC_BACK} />
                </button>
                <div className="font-semibold text-lg">Post</div>

                <div className='flex gap-x-2 items-center ml-auto'>

                    <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg'>
                        <img className='dark:invert' src={SRC_EDIT} />
                    </motion.button>
                    <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg'>
                        <img className='dark:invert' src={SRC_COMMENT} />
                    </motion.button>
                    <motion.button whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] flex items-center justify-center rounded-lg'>
                        <img className='dark:invert' src={SRC_SHARE} />
                    </motion.button>
                    <Like />

                </div>
            </div>


        </>
    );
}

export function HeaderProfile() {

    const navigate = useNavigate();

    return (
        <>
            <div className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-between  items-center px-global flex-shrink-0">
                <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                    <img className='dark:invert' src={SRC_BACK} />
                </button>
                <div className="font-semibold text-lg">Profile</div>

                <div className='flex ml-auto gap-x-5 items-center'>
                    <Link className='flex size-[26px] items-center justify-center' to="/edit-profile">
                        <img className='dark:invert' src={SRC_EDIT} />
                    </Link>
                    <motion.Link className='flex size-[26px] items-center justify-center' to="/about">
                        <img className='dark:invert' src={SRC_CHAT} />
                    </motion.Link>
                    <Follow />
                </div>
            </div>


        </>
    );
}

export function HeaderCheckout(props) {
    const { title, hasIcon, icon, link, hasCustom, custom } = props;
    const navigate = useNavigate();

    return (
        <>
            <motion.div

                className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-start  items-center px-global flex-shrink-0">
                <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                    <img src={SRC_BACK} />
                </button>
                <div className="font-semibold text-lg">{title}</div>
                
                {hasIcon && (
                    <Link className='flex size-[26px] flex-shrink-0 items-center justify-center ml-auto' to={link}>
                        <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={icon} />
                    </Link>
                )
                }
                {hasCustom && (
                    <>{custom}</>
                )}

            </motion.div>


        </>
    );
}

export function HeaderEdit(props) {
    const { title, hasIcon, icon } = props;
    const navigate = useNavigate();

    return (
        <>
            <motion.div

                className="w-full h-[68px] flex text-black bg-white dark:bg-zinc-800 dark:text-white justify-start  items-center px-global flex-shrink-0">
                <button className='flex size-[26px] mr-2 items-center justify-center' onClick={() => navigate(-1)}>
                    <img src={SRC_BACK} />
                </button>
                <div className="font-semibold text-lg">{title}</div>
                <div className='flex gap-x-3 items-center ml-auto'>
                    {hasIcon && (
                        <Link className='flex size-[26px] flex-shrink-0 items-center justify-center ml-auto'>
                            <motion.img className='dark:invert' initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ duration: 0.1 }} src={icon} />
                        </Link>
                    )}
                    <motion.button
                        whileTap={{ scale: 0.9 }} className='px-4 flex-shrink-0 ml-auto py-2 bg-sky-500 text-white text-sm rounded-lg'>
                        Publish
                    </motion.button>
                </div>


            </motion.div>


        </>
    );
}