import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";


import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EVERYWHERE from '../assets/img/everywhere.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_FOLLOWING from '../assets/img/following.svg';
import SRC_CROWD from '../assets/img/crowd.svg';

import SRC_BACK from '../assets/img/back.svg';
import SRC_CHEV from '../assets/img/chevron-right.svg';

import SRC_GLOBE from '../assets/img/globe.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_CAMERA from '../assets/img/camera.svg';
import SRC_REPAIR from '../assets/img/repair.svg';
import SRC_SHOP from '../assets/img/shop.svg';
import SRC_INSTRUCTOR from '../assets/img/instructor.svg';
import SRC_SHAKA from '../assets/img/shaka.svg';

import Switch from "./ui/Switch";
import Favorite from './ui/Favorite';
import Chip from './ui/Chip';



function SearchGeneral({ onClose }) {

    return (
        <>

            <motion.div exit={{ height: 0 }} transition={{ duration: 0.1 }} className='flex flex-col h-full w-full absolute overflow-hidden top-0 bg-white dark:bg-zinc-800  z-[9999]'>
                <div className='p-global w-full'>
                    <div className='w-full relative'>
                        <motion.img className='absolute left-4 top-[15px] dark:invert z-20' src={SRC_BACK} onClick={onClose} />
                        <motion.input initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1 }} transition={{ delay: 0 }} className='bg-slate-100 pl-12 rounded-full w-full focus:outline-sky-500 dark:bg-zinc-700 p-4' placeholder='Name, Product, Event' />
                        {/*<motion.img initial={{ scale: 0 }} animate={{ scale: 1 }} className='absolute right-4 top-[15px] dark:invert z-20' src={SRC_CURRENT} />*/}
                    </div>
                </div>
                {/*<div className='w-full flex gap-x-2 overflow-x-scroll overflow-y-visible snap-x snap-mandatory pl-global pr-global no-scrollbar'>
                    <Chip title="All" />
                    <Chip title="Instructors" />
                    <Chip title="Surfers" />
                    <Chip title="Events" />
                    <Chip title="Surf Shops" />
                    <Chip title="Gear" />
    </div>*/}
                <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: "100%" }}
                    className="w-full h-full overflow-hidden origin-top bg-white text-black flex-col flex dark:bg-zinc-800 dark:text-white">

                    <motion.div
                        exit={{ opacity: 0, y: 20 }}
                        className='h-full w-full overflow-auto'>
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0 }}
                            className='w-full px-global'>
                            <Category link="/trip-planner" title="Plan your trip" icon={SRC_GLOBE} description="Everything you need for you next trip." />
                            <Category link="/instructors" title="Surf instructors" icon={SRC_INSTRUCTOR} description="Level up your skills with the best" />
                            <Category title="Surfers" icon={SRC_CROWD} description="Follow surfers around the world" />
                            <Category title="Friends" icon={SRC_SHAKA} description="Find people to surf with" />
                            <Category link="/surf-shops" title="Surf shops" icon={SRC_SHOP} description="Rent or buy gear from in your area" />
                            <Category link="/photographers" title="Photographers" icon={SRC_CAMERA} description="Ger the best photos of your sessions" />
                            {/*<Category title="Shape & Repair" icon={SRC_REPAIR} description="Find local shapers or get your board fixed" />*/}
                            <Category link="/profile/events" title="Events" icon={SRC_BOARD} description="Find trips and events with local guides" />
                            




                        </motion.div>



                    </motion.div>

                    {/*<motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1 }}
                        className='px-global py-global mt-auto'
                    >
                        <motion.button exit={{ y: 100, opacity: 0 }} className='w-full shadow-xl py-3 text-center bg-sky-500 text-white rounded-xl'>Show Results</motion.button>

</motion.div>*/}

                </motion.div>



            </motion.div>

        </>
    );
}

const Category = (props) => {
    const { icon, title, description, link } = props;

    return (
        <Link to={link} className='w-full py-4 flex items-center border-b border-slate-200 dark:border-zinc-600 last-of-type:border-none'>
            <div className='size-14 mr-4 flex items-center justify-center rounded-full bg-slate-100 dark:bg-zinc-700'>
                <img className='size-6 dark:invert' src={icon} />
            </div>
            <div>
                <div className='text-lg font-semibold'>{title}</div>
                <div className='text-xs text-slate-400 dark:text-zinc-400'>{description}</div>
            </div>
            <img className='ml-auto dark:invert opacity-40' src={SRC_CHEV} />
        </Link>

    )
}

export default SearchGeneral