import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";


import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EVERYWHERE from '../assets/img/everywhere.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_ADD from '../assets/img/add.svg';

import SRC_CLOSE from '../assets/img/close.svg';

import Switch from "./ui/Switch";
import Favorite from './ui/Favorite';
import Chip from './ui/Chip';



function LocationDrawer({ onClose }) {

    return (
        <>

            <motion.div className='flex dark:text-white flex-col justify-end h-full w-full absolute overflow-hidden top-0  z-[9999]'>
                <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 100 }} onClick={onClose} className='w-full h-full absolute top-0 left-0 bg-black/40'>
                    <button  onClick={onClose} className='size-10 rounded-xl bg-white dark:bg-zinc-800 flex items-center justify-center absolute top-4 right-4'>
                        <motion.img initial={{ scale: 0 }} animate={{ scale: 1 }} className='dark:invert z-20' src={SRC_CLOSE} />
                    </button>
                </motion.div>
                <motion.div initial={{ y: "100%" }} animate={{ y: 0 }} exit={{ y: "100%" }} transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }} className="w-full rounded-tr-3xl rounded-tl-3xl min-h-[50%] z-50 relative p-global shadow-inverted overflow-auto origin-top bg-white flex-col flex dark:bg-zinc-800 ">

                    {/*<div className='flex items-center justify-between w-full'>
                        <h3 className='text-xl font-semibold'>Location</h3>
                        <img className='dark:invert z-20' src={SRC_CLOSE} onClick={onClose} />
    </div>*/}
                    <div className='w-full relative mt-2'>

                        <motion.input initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1 }} transition={{ delay: 0 }} className='bg-slate-100 pl-6 rounded-full w-full focus:outline-sky-500 dark:bg-zinc-700 p-4' placeholder='Add location' />
                        <motion.img className='absolute right-4 top-[15px] dark:invert z-20 opacity-30' src={SRC_ADD} />
                    </div>

                    <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                        <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_CURRENT} />
                        <div className='mr-auto font-medium'>Current Location</div>
                        <Switch />
                    </div>
                    <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                        <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_EVERYWHERE} />
                        <div className='mr-auto font-medium'>Everywhere</div>
                        <Switch />
                    </div>
                    <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                        <img className='mr-3 flex-shrink-0 dark:invert' src={SRC_LOCATION} />
                        <div className='mr-auto font-medium'>Pipeline, Oahu, Hawaii</div>

                        <motion.button
                            whileTap={{ backgroundColor: "#F1F5F9" }} className='size-6 flex items-center justify-center rounded-lg ml-auto mr-3'>
                            <img className='dark:invert size-5 opacity-55' src={SRC_DELETE} />
                        </motion.button>
                        <Switch />
                    </div>

                </motion.div>
            </motion.div>

        </>
    );
}



export default LocationDrawer