import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import SRC_CURRENT from '../assets/img/current-location.svg';
import SRC_EVERYWHERE from '../assets/img/everywhere.svg';
import SRC_LOCATION from '../assets/img/location.svg';
import SRC_DELETE from '../assets/img/delete.svg';
import SRC_FOLLOWING from '../assets/img/following.svg';
import SRC_CROWD from '../assets/img/crowd.svg';

import Switch from "./ui/Switch";



const FilterSocial = () => {
    return (
        <>

            <motion.div
                initial={{ height: 0 }}
                animate={{ height:"calc(100% - 68px)" }}
                exit={{ height:0 }}
                className="w-full h-[calc(100%-68px)] overflow-hidden origin-top top-[68px] z-[9999] bg-white text-black absolute flex-col flex dark:bg-zinc-800 dark:text-white flex-shrink-0">
                <motion.div
                exit={{opacity:0, y:20}}
                className='h-full w-full overflow-auto'>
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0 }}
                        className='w-full py-4 px-global border-b-8 border-slate-100 dark:border-zinc-900'>
                        <h3 className='text-xl font-semibold'>Location</h3>
                        <div className='text-sm text-slate-400 dark:text-zinc-500 mb-6'>Choose location you want to see all public sessions from</div>
                        <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0' src={SRC_CURRENT} />
                            <div className='mr-auto font-medium'>Current Location</div>
                            <Switch />
                        </div>
                        <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0' src={SRC_EVERYWHERE} />
                            <div className='mr-auto font-medium'>Everywhere</div>
                            <Switch />
                        </div>
                        <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0' src={SRC_LOCATION} />
                            <div className='mr-auto font-medium'>Pipeline, Oahu, Hawaii</div>

                            <motion.button
                                whileTap={{ backgroundColor: "#F1F5F9" }} className='size-6 flex items-center justify-center rounded-lg ml-auto mr-3'>
                                <img className='dark:invert size-5 opacity-55' src={SRC_DELETE} />
                            </motion.button>
                            <Switch />
                        </div>
                        <div className='flex w-full items-center py-6 dark:border-zinc-700'>
                            <div className='size-[24px] mr-3 font-light text-xl flex items-center justify-center text-slate-400 dark:text-zinc-400'>+</div>
                            <div className='mr-auto font-medium text-slate-400 dark:text-zinc-400'>Add Location</div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0 }}
                        className='w-full py-8 px-global dark:border-zinc-900'>
                        <h3 className='text-xl font-semibold'>People</h3>
                        <div className='text-sm text-slate-400 dark:text-zinc-500 mb-6'>Based on people you follow</div>
                        <div className='flex w-full items-center py-6 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0' src={SRC_FOLLOWING} />
                            <div className='mr-auto font-medium'>People you follow</div>
                            <Switch />
                        </div>

                        <div className='flex w-full items-center py-6 dark:border-zinc-700'>
                            <img className='mr-3 flex-shrink-0' src={SRC_CROWD} />
                            <div className='mr-auto font-medium'>All public posts</div>
                            <Switch />
                        </div>
                    </motion.div>

                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1}}
                    className='px-global pb-global mt-auto shadow-white'
                >
                    <motion.button exit={{y:100, opacity:0}} className='w-full shadow-xl py-3 text-center bg-sky-500 text-white rounded-xl'>Show Results</motion.button>

                </motion.div>

            </motion.div>



        </>
    );
}

export default FilterSocial