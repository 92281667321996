import { Fragment, useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";


import { motion, useAnimation, AnimatePresence } from "framer-motion"


import SRC_CHEV from '../assets/img/chevron-right.svg';
import SRC_CHAT from '../assets/img/messages.svg';
import SRC_SIZE from '../assets/img/board.svg';
import SRC_VOLUME from '../assets/img/volume.svg';
import SRC_FIN from '../assets/img/fin.svg';

import SRC_TOMAS from '../assets/img/tomas.jpg';

import { SocialPost } from './Social'

import { HeaderProduct } from './Header'

export function Product() {


    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderProduct />
                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full flex overflow-auto snap-x snap-mandatory no-scrollbar'>
                        <div className='w-full snap-center bg-slate-50 border border-slate-100 dark:bg-zinc-600 h-1/2 relative z-20 flex-shrink-0 before:w-full before:block before:pt-[100%] overflow-hidden'>
                            <img className='w-full h-full absolute left-0 top-0 snap-center flex-shrink-0 bg-slate-100 dark:bg-zinc-700' src='https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_deck_grande.png?v=1699917947' />
                        </div>
                        <div className='w-full snap-center bg-slate-50 border border-slate-100 dark:bg-zinc-600 h-1/2 relative z-20 flex-shrink-0 before:w-full before:block before:pt-[100%] overflow-hidden'>
                            <img className='w-full h-full absolute left-0 top-0 snap-center flex-shrink-0 bg-slate-100 dark:bg-zinc-700' src='https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_rockerprofile_1080x.png?v=1699917947' />
                        </div>
                        <div className='w-full snap-center bg-slate-50 border border-slate-100 dark:bg-zinc-600 h-1/2 relative z-20 flex-shrink-0 before:w-full before:block before:pt-[100%] overflow-hidden'>
                            <img className='w-full h-full absolute left-0 top-0 snap-center flex-shrink-0 bg-slate-100 dark:bg-zinc-700' src='https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_3upv2_1080x.png?v=1699917947' />
                        </div>
                        {/* <motion.img layoutId='image' className='w-full snap-center relative flex-shrink-0 bg-slate-100 dark:bg-zinc-700' src='https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_rockerprofile_1080x.png?v=1699917947' />
                        <motion.img layoutId='image' className='w-full snap-center relative flex-shrink-0 bg-slate-100 dark:bg-zinc-700' src='https://www.firewiresurfboards.com/cdn/shop/files/pdp_mashup_3upv2_1080x.png?v=1699917947' />*/}
                    </div>
                    <div className='p-6'>
                        <motion.div layoutId="protuctname" className='text-3xl font-semibold'>Firewire Mashup 5'7</motion.div>
                        <div className='w-full flex py-3 pb-5 items-center mb-5 border-b border-slate-200 dark:border-zinc-500'>
                            <Link to="/surfer-profile" className='flex items-center'>
                                <img className='size-8 border-2 border-white dark:border-zinc-800 object-cover rounded-full -mr-2 relative z-30' src={SRC_TOMAS} />
                                <span className=' text-sm ml-4 font-semibold'>Tomas Zeman</span>
                            </Link>
                            <motion.button
                                whileTap={{ backgroundColor: "#F1F5F9", scale: 1.3 }} className='size-[40px] ml-auto flex items-center justify-center rounded-lg'>
                                <motion.img whileTap={{ rotate: 45 }} className='dark:invert' src={SRC_CHAT} />
                            </motion.button>
                        </div>
                        <div className='text-sm text-slate-500 dark:text-zinc-500 mb-4'>
                            Two minds combined.

                            Rob Machado and Dan Mann have created the Mashup: A melding of each of their most popular designs; the Seaside and the Spitfire.

                            Both board designers have spent time mixing and matching their favorite elements of each shape, and they’ve landed at something that goes fast in weak waves as well as a Seaside, and also drives vertically in good waves as well as the Spitfire.
                        </div>

                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_SIZE} />
                            <div className=' '>Size</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                5'7ft
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_VOLUME} />
                            <div className=' '>Volume</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                34.2L
                            </div>
                        </div>
                        <div className='ml-auto flex w-full py-4'>
                            <img className='mr-2 dark:invert' src={SRC_FIN} />
                            <div className=' '>Fins</div>
                            <div className='flex items-center gap-x-1 justify-end ml-auto font-semibold'>
                                Future Quad
                            </div>
                        </div>


                    </div>



                </div>

                <motion.div initial={{ y: "100%" }} animate={{ y: 0 }} transition={{ delay: 0.3 }} className='w-full shadow-inverted gap-x-global flex items-center p-global py-2 border-t border-zinc-200 dark:border-zinc-600 bg-white dark:bg-zinc-800  '>
                    <div className=' rounded-xl p-2 w-2/3 relative'>
                        <div className='text-lg font-semibold'>$50.00 USD <span className='text-xs font-normal text-slate-400 dark:text-zinc-500'>/day</span></div>
                        {/*<img className='absolute bottom-3 rotate-90 right-2' src={SRC_CHEV} />*/}
                    </div>
                    <Link className=' w-1/3 block' to="/checkout/step-one">
                        <motion.button
                            whileTap={{ scale: 1.1 }} className='w-full px-4 ml-auto whitespace-nowrap flex-shrink-0 py-4  bg-sky-500 text-white text-sm rounded-lg'>
                            Request
                        </motion.button>
                    </Link>
                </motion.div>



            </motion.div>
        </>

    )
}







