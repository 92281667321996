
import { useState, useEffect, useMemo } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, ScrollRestoration, useLocation } from 'react-router-dom';

import SRC_HOME from '../assets/img/home.svg';
import SRC_BOARD from '../assets/img/board.svg';
import SRC_MARKETPLACE from '../assets/img/marketplace.svg';
import SRC_WAVE from '../assets/img/wave.svg';
import SRC_USER from '../assets/img/tomas.jpg';
import SRC_CHAT from '../assets/img/messages.svg';
import { getAbbreviation } from '../helpers/GeneralHelper';

export function Navbar(props) {

    const { USER } = props;

    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <>
            <AnimatePresence mode='wait'>
                <div className="w-full h-[68px] grid grid-flow-col flex-shrink-0 justify-between px-global col-span-5 text-black bg-white dark:bg-zinc-800 border-t border-slate-100 dark:border-zinc-600 relative z-50">
                    <Link className='flex size-[60px] items-center justify-center relative' to="/">
                        <motion.img whileTap={{ scale: 1.2 }} className={" " + (url === "/" ? " invert-blue" : "dark:invert")} src={SRC_BOARD} />
                        {(url === "/" ?
                            <motion.div layoutId='nav-indicator' className='w-full h-1 absolute -top-px left-0 rounded-xl z-10 bg-sky-500' />
                            :
                            <></>
                        )}
                    </Link>
                    <Link className='flex size-[60px] items-center justify-center relative' to="/spots">
                        <motion.img whileTap={{ scale: 1.2 }} className={" " + (url === "/spots" ? " invert-blue" : "dark:invert")} src={SRC_WAVE} />
                        {(url === "/spots" ?
                            <motion.div layoutId='nav-indicator' className='w-full h-1 absolute -top-px left-0 rounded-xl z-10 bg-sky-500' />
                            :
                            <></>
                        )}
                    </Link>
                    <Link className='flex size-[60px] items-center justify-center relative' to="/marketplace">
                        <motion.img whileTap={{ scale: 1.2 }} className={" " + (url === "/marketplace" ? " invert-blue" : "dark:invert")} src={SRC_MARKETPLACE} />
                        {(url === "/marketplace" ?
                            <motion.div layoutId='nav-indicator' className='w-full h-1 absolute -top-px left-0 rounded-xl z-10 bg-sky-500' />
                            :
                            <></>
                        )}
                    </Link>
                    <Link className='flex size-[60px] items-center justify-center relative' to="/chat">
                        <div className='absolute bg-sky-500 text-xxs text-white px-[5px] py-[2px] top-3 border-2 border-white dark:border-zinc-800  right-1 z-20 rounded-md'>10</div>
                        <motion.img whileTap={{ scale: 1.2 }} className={" " + (url === "/chat" ? " invert-blue" : "dark:invert")} src={SRC_CHAT} />
                        {(url === "/chat" ?
                            <motion.div layoutId='nav-indicator' className='w-full h-1 absolute -top-px left-0 rounded-xl z-10 bg-sky-500' />
                            :
                            <></>
                        )}
                    </Link>
                    <Link className='flex size-[60px] items-center justify-center relative' to="/profile">

                        {USER?.img ? (
                            <motion.img whileTap={{ scale: 1.2 }}
                                className={"size-[26px] rounded-full object-cover" + (url === "/profile" ? " outline outline-2 outline-sky-500" : " ")}
                                src={USER?.img}
                            />
                        ) : (
                            <motion.div whileTap={{ scale: 1.2 }}
                                className={"size-[26px] flex items-center justify-center rounded-full object-cover" + (url === "/profile" ? " outline outline-2 outline-sky-500" : " ")}
                            >
                                <div className='text-xs text-black font-semibold'>
                                    {getAbbreviation(USER?.name)}
                                </div>
                            </motion.div>
                        )}

                        {(url === "/profile" ?
                            <motion.div layoutId='nav-indicator' className='w-full h-1 absolute -top-px left-0 rounded-xl z-10 bg-sky-500' />
                            :
                            <></>
                        )}
                    </Link>
                </div>
            </AnimatePresence>


        </>
    );
}