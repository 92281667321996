
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";


import SRC_CHEV from '../../assets/img/chevron-right.svg';
import SRC_FILTER from '../../assets/img/filter.svg';
import SRC_NATHAN from '../../assets/img/nathan.webp';
import SRC_TOMAS from '../../assets/img/tomas.jpg';

import SRC_BOARD from '../../assets/img/board-1.png';
import SRC_BOARDTWO from '../../assets/img/board-2.png';
import SRC_BOARDTHREE from '../../assets/img/board-3.png';

import SRC_COCO from '../../assets/img/coco.jpeg';


import { Drawer, DrawerOption } from '../ui/Forms';
import { HeaderCheckout } from '../Header';
import { SocialPost } from '../Social'
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';




export function History() {

    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "-100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "-100%" }}
                className='fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[9999999] top-0 left-0 shadow-2xl'>

                <HeaderCheckout title="History" hasCustom="true"
                    custom={
                        <>
                            <div className='size-[26px] rounded-lg relative ml-auto flex items-center justify-center'>
                                <img src={SRC_FILTER} />
                                <Drawer
                                    options={
                                        <>
                                            <DrawerOption title="Products" hasCheckbox="true" />
                                            <DrawerOption title="Services" hasCheckbox="true" />
                                        </>
                                    }

                                />
                            </div>
                        </>
                    }
                />

                <div className='w-full h-full overflow-auto no-scrollbar'>
                    <div className='w-full p-global pt-0'>
                        <Booking title="Advanced surf training" img={SRC_COCO} price="Mar 19, 2024" />
                        <Booking title="Advanced surf training" img={SRC_NATHAN} price="Mar 19, 2024"  />
                        <Booking title="Local guide" img={SRC_TOMAS} price="Mar 18, 2024" />
                        <Product title="Seaside 5'1" img={SRC_BOARD} price="$50/day" />
                        <Product title="JS Monsta 6'1" img={SRC_BOARDTWO} price="$80/day" />
                        <Product title="Slater Outerknown" img={SRC_BOARDTHREE} price="$800" />
                    </div>
                </div>
            </motion.div>

        </>

    )
}

export function Booking(props) {
    const { img, title, price, hasPending } = props;
    return (
        <Link to="/profile/booking-detail" className="w-full py-global relative border-b border-slate-200 dark:border-zinc-600 flex items-center last-of-type:border-none">

            <div className='size-14 relative mr-global'>
                <img src={img} className='size-full border border-slate-200 dark:border-zinc-600 rounded-full object-cover' />
            </div>
            <div>
                <div className='font-semibold'>{title}</div>
                <div className='text-slate-500 text-sm flex items-center gap-x-4'>
                    {price}
                    {hasPending && (
                        <div className='text-xxs bg-orange-400 text-white top-global px-2 py-1 rounded-xl'>Pending</div>
                    )}
                </div>
            </div>
        </Link>
    )
}


export function Product(props) {
    const { img, title, price } = props;
    return (
        <Link to="/profile/add-product" className="w-full py-global border-b border-slate-200 dark:border-zinc-600 flex items-center last-of-type:border-none">
            <img src={img} className='size-14 mr-global border border-slate-200 dark:border-zinc-600 rounded-xl object-contain' />
            <div>
                <div className='font-semibold'>{title}</div>
                <div className='text-slate-500 text-sm'>{price}</div>
            </div>
        </Link>
    )
}








