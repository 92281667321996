import React, { useState } from 'react';

import SRC_GOOGLE from '../assets/img/google.svg';
import SRC_APPLE from '../assets/img/apple.svg';
import SRC_FACEBOOK from '../assets/img/facebook.svg';


import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login'

import swal from 'sweetalert';

const SocialLogin = (props) => {

    const { onLoginGoogle, onLoginApple } = props;

    return (
        <div className='flex gap-x-3'>

            <GoogleLogin {...props} onLoginGoogle={onLoginGoogle} />

            <AppleLogin
                clientId="com.dogu.app.auth"
                redirectURI="https://www.dogu.app/en/signup"
                usePopup={false}
                scope="email name"
                responseType='code'
                responseMode="query"
                render={renderProps => (  //Custom Apple Sign in Button
                    <div className='w-1/3 py-5 bg-slate-100 rounded-xl  flex items-center justify-center' onClick={() => onLoginApple({ fun: renderProps.onClick })}>
                        <img src={SRC_APPLE} alt='' />
                    </div>
                )}
            />
            <div className='w-1/3 py-5 bg-slate-100 rounded-xl  flex items-center justify-center' onClick={() => swal('Coming Soon', '🚧 Facebook Login is in progress 🚧')}>
                <img src={SRC_FACEBOOK} alt='' />
            </div>
        </div>
    )
}

const GoogleLogin = (props) => {

    const { onLoginGoogle } = props;

    const [DATA, setData] = useState();

    const isLocalhost = window.location.href.includes('localhost:3000');

    const onLoginClick = () => {
        const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
        if (isNativeApp) {

            // const clientId = '534041740722-el9c12qp89n0tihead1iiqcb7ptiraom.apps.googleusercontent.com';
            // const redirectUri = isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.dogu.app/en/login';
            // const scope = 'openid profile email'; // Adjust scope based on your requirements

            // const authUrl = `https://accounts.google.com/o/oauth2/auth?` +
            //     `response_type=code&` +
            //     `client_id=${clientId}&` +
            //     `redirect_uri=${encodeURIComponent(redirectUri)}&` +
            //     `scope=${encodeURIComponent(scope)}`;

            const webData = {
                type: 'LOGIN_GOOGLE'
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(webData));
        }
        else {
            login()
        }
    }

    const login = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        prompt: 'select_account',
        redirect_uri: isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.dogu.app/en/login',
        onSuccess: credentialResponse => {
            console.log(JSON.stringify(credentialResponse));
            setData(credentialResponse);
            onLoginGoogle(credentialResponse);
            return
        },
        onError: error => {
            console.log('Login Failed');
            setData(error)
        },
    });

    return (
        <div className='w-1/3 py-5 bg-slate-100 rounded-xl  flex items-center justify-center' onClick={() => onLoginClick()}>
            <img src={SRC_GOOGLE} alt='' />
        </div>
    )

}

export default SocialLogin