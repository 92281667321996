import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom";



import SRC_CLOSE from '../../assets/img/close.svg';
import SRC_SEARCH from '../../assets/img/search.svg';
import SRC_CHECK from '../../assets/img/check.svg';

import Switch from "./Switch";
import { InputField, TextField } from './Forms';
import Chip from './Chip';



function CurrencyDrawer({ onClose }) {

    return (
        <>

            <motion.div className='flex dark:text-white flex-col justify-end h-full w-full fixed overflow-hidden top-0  z-[99999999]'>
                <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 100 }} onClick={onClose} className='w-full h-full absolute top-0 left-0 bg-black/40' />

                <motion.div initial={{ y: "100%" }} animate={{ y: 0 }} exit={{ y: "100%" }} transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }} className="w-full rounded-tr-3xl rounded-tl-3xl  z-50 relative p-global shadow-inverted overflow-auto origin-top bg-white flex-col flex dark:bg-zinc-800 ">

                    <div className='w-full flex text-lg items-center font-semibold justify-between mb-4'>
                        Change currency
                        <img onClick={onClose} src={SRC_CLOSE} />

                    </div>
                    <div className='mb-3'>
                        <InputField label="Search" id="search" hasIcon="true" icon={SRC_SEARCH} />
                    </div>

                    <div className='w-full border-b border-slate-200 py-3 flex items-center'>
                        <div>
                            <div className="font-medium">PHP</div>
                            <div className="text-sm text-slate-400">Philippine Peso</div>
                        </div>
                        <img src={SRC_CHECK} className='ml-auto' />
                    </div>
                    <div className='w-full border-b border-slate-200 py-3 flex items-center'>
                        <div>
                            <div className="font-medium">USD</div>
                            <div className="text-sm text-slate-400">American Dollar</div>
                        </div>
                    </div>
                    <div className='w-full border-b border-slate-200 py-3 flex items-center'>
                        <div>
                            <div className="font-medium">AUD</div>
                            <div className="text-sm text-slate-400">Australian Dollar</div>
                        </div>
                    </div>
                    <div className='w-full border-b border-slate-200 py-3 flex items-center'>
                        <div>
                            <div className="font-medium">EUR</div>
                            <div className="text-sm text-slate-400">Euro</div>
                        </div>
                    </div>
                    <div className='w-full border-b border-slate-200 py-3 flex items-center'>
                        <div>
                            <div className="font-medium">YEN</div>
                            <div className="text-sm text-slate-400">Japanese Yen</div>
                        </div>
                    </div>
                    


                </motion.div>
            </motion.div>

        </>
    );
}



export default CurrencyDrawer