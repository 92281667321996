
import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence, useScroll, Reorder, useDragControls } from "framer-motion"

import Rating from '@mui/material/Rating';

import { Link } from "react-router-dom";
import SRC_WETSUIT from '../../assets/img/wetsuit.svg';
import SRC_MONEY from '../../assets/img/sale.svg';
import SRC_MEDIA from '../../assets/img/media.svg';
import SRC_BOARD from '../../assets/img/board.svg';
import SRC_DELETE from '../../assets/img/delete.svg';

import SRC_SIZE from '../../assets/img/size.svg';
import SRC_VIDEO from '../../assets/img/camera.svg';
import SRC_CAR from '../../assets/img/car.svg';
import SRC_LOCATION from '../../assets/img/location.svg';

import SRC_TIME from '../../assets/img/time.svg';
import SRC_SALE from '../../assets/img/sale.svg';

import SRC_ADD from '../../assets/img/add.svg';

import SRC_LEASH from '../../assets/img/leash.svg';

import SRC_CHEV from '../../assets/img/chevron-right.svg';

import { HeaderEdit } from '../Header';
import { InputField, RangeSlider, TextField, DateField, SelectField, SelectOption, Checkbox } from '../ui/Forms';
import Switch from '../ui/Switch';
import { Expandable } from '../ui/Expandable';
import Chip from '../ui/Chip';

import SearchPeople from '../SearchPeople';



export function AddService() {


    const value = 3.5;
    return (
        <>
            <motion.div layout
                initial={{ opacity: 100, x: "100%" }}
                animate={{ opacity: 100, x: 0 }}
                transition={{ duration: 0.2, type: "spring", damping: 60, stiffness: 700 }}
                exit={{ opacity: 100, x: "100%" }}
                className='product-test fixed flex flex-col bg-white dark:bg-zinc-800 dark:text-white h-full w-full z-[99999] top-0 left-0 shadow-2xl'>

                <HeaderEdit title="Add service" hasIcon="true" icon={SRC_DELETE} />

                <div className='w-full h-full overflow-auto no-scrollbar'>

                    <div className='w-full px-global pt-0'>

                        <div className='w-full mt-2 mb-4 text-sm px-global min-h-32 text-sky-500 flex items-center justify-center flex-col b border border-dashed border-sky-500 rounded-xl'>
                            <img src={SRC_MEDIA} className='invert-blue' />
                            Service photo
                        </div>
                        <form className='w-full flex flex-col gap-4 mb-6'>
                            <SelectField
                                title="Service name"
                                options={
                                    <>
                                        <div className='w-full relative'>
                                            <InputField label="Add yours" id="price" />
                                            <motion.button
                                                whileTap={{ scale: 0.9 }} className='px-4 absolute top-[10px] right-2 flex-shrink-0 ml-auto py-2 bg-sky-500 text-white text-sm rounded-lg'>
                                                Save
                                            </motion.button>
                                        </div>
                                        <SelectOption title="Beginner surf training" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Local surf guidance" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Intermediate surf training" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Advanced surf training" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Maneuvering the board" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Wave selection" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Advanced Maneuvering" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Competition training" hasIcon="true" icon={SRC_ADD} />
                                        <SelectOption title="Fine-tuning technique" hasIcon="true" icon={SRC_ADD} />
                                    </>
                                }

                            />

                            <InputField label="Price" id="price" hasIcon="true" icon={SRC_MONEY} />
                            <InputField label="Duration" id="duration" hasIcon="true" icon={SRC_TIME} />
                            <TextField label="About this service" id="about" />
                        </form>

                        
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_BOARD} />
                            <div className=' '>Board included</div>
                            <Checkbox />
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_VIDEO} />
                            <div className=' '>Video analysis</div>
                            <Checkbox />
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_CAR} />
                            <div className=' '>Transport included</div>
                            <Checkbox />
                        </div>
                        <div className='ml-auto flex w-full py-4 border-b border-slate-200 dark:border-zinc-700'>
                            <img className='mr-2 dark:invert' src={SRC_LOCATION} />
                            <div className=' '>Meet at the spot</div>
                            <Checkbox />
                        </div>



                    </div>

                </div>
            </motion.div>

        </>

    )
}

